import React from "react"

export default () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256.15 216.39">
            <defs>
                <style></style>
            </defs>
            <title>All purpose cleaner text</title>
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                    <path d="M21.46,200.59c-2.92-5.66-4-11.74-4-18.9a46.49,46.49,0,0,1,6.83-24.15,25.69,25.69,0,0,1,7.24-8,15.51,15.51,0,0,1,8.91-3c7.16,0,12.74,7.08,10.83,14.49A7.33,7.33,0,0,1,46.94,166a3.81,3.81,0,0,1-4.58-.58c-1.34-1.17-1.92-1.08-2.75.42a39.86,39.86,0,0,0-3.08,6c-2,4.91-2.17,10.08-1.58,15.24a19.77,19.77,0,0,0,1.16,4.41c.59,1.59,1.92,1.84,3.17.59a10.43,10.43,0,0,0,2.16-2.83,43.58,43.58,0,0,0,2-5.5c.42-1.17.5-2.42.92-3.58a6,6,0,0,1,5.82-4.25,5.48,5.48,0,0,1,5.17,5.41c-.17,7.25-.67,14.49-5,20.82a20.62,20.62,0,0,1-5.74,5.66,14.3,14.3,0,0,1-15.08,1,17.62,17.62,0,0,1-8.07-8.24Z" />
                    <path d="M82.08,205.34c-3.83,2.33-8,3.83-12.16,3.58a14.23,14.23,0,0,1-9.74-5.5,18.55,18.55,0,0,1-4-9.07,62.11,62.11,0,0,1-.91-9.58c.08-8.24.41-16.49.66-24.73.08-1.5,0-3,.17-4.5a16,16,0,0,1,.91-4,6,6,0,0,1,6.08-4,6.13,6.13,0,0,1,5.75,4.5,14,14,0,0,1,.58,4.08c.17,6.91.25,13.82.42,20.73.08,3.83.33,7.66.58,11.49a22.26,22.26,0,0,0,.67,4.08c.58,2.08,1.83,2.92,4.08,2.92.91,0,1.83-.25,2.74-.34a5.85,5.85,0,0,1,6.42,4A5.75,5.75,0,0,1,82,205.84Z" />
                    <path d="M86.08,204.09a37.81,37.81,0,0,1-1.5-12.24,77.55,77.55,0,0,1,5.16-26.4,33.72,33.72,0,0,1,6.33-10.82A14.8,14.8,0,0,1,104,149.8a9.07,9.07,0,0,1,9.41,3.16,12.51,12.51,0,0,1,2.83,10.16c-.25,2-1.25,3.25-2.67,3.5-1.74.33-3.08-.42-4.08-2.33-.41-.75-.66-1.58-1.08-2.33a1,1,0,0,0-1.75-.09,16.28,16.28,0,0,0-1,1.5,55.41,55.41,0,0,0-4.58,11.74c-.33,1.33,0,1.75,1.42,1.83.83.09,1.67,0,2.5.09a4.6,4.6,0,0,1,.58,9.07,25.38,25.38,0,0,1-5.08.17c-1.25,0-1.75.33-1.91,1.58A33.4,33.4,0,0,0,99,199.34a5.06,5.06,0,0,0,.42,1c.42,1,1.33,1.25,1.91.42,1-1.5,1.92-3.08,2.84-4.66.41-.75.66-1.59,1.08-2.25a5.65,5.65,0,0,1,6.24-2.5,5.81,5.81,0,0,1,4.25,4.91,8.26,8.26,0,0,1-.58,4.67c-1.25,2.74-2.42,5.49-3.75,8.16a15,15,0,0,1-2.58,3.41c-5.33,5.25-12.33,5.16-17.65-.08a20.09,20.09,0,0,1-4.92-8.33Z" />
                    <path d="M146,170c-.17,2-.25,4-.33,5.5-.09,5.74-.5,11,.41,16.15.25,1.42.59,2.83.92,4.25a19.19,19.19,0,0,1,.5,2.16,6.21,6.21,0,0,1-2.58,6.08,6.65,6.65,0,0,1-7,.67,8,8,0,0,1-4.41-5.16c-.58-1.75-.92-3.58-1.42-5.42a1.46,1.46,0,0,0-1.83-1.33,6,6,0,0,1-1.25.25c-1.74,0-2.08.75-2.41,2.42-.17,1-.17,2-.33,3a23.25,23.25,0,0,1-.67,3.42,4.38,4.38,0,0,1-8.41-.17,17.91,17.91,0,0,1-.5-5.58c.33-4.66.67-9.32,1.42-13.9.83-5,2.08-9.91,3.33-14.82a35.7,35.7,0,0,1,3.83-9.66,19.21,19.21,0,0,1,5.08-5.92,9,9,0,0,1,13.49,2.75A19.54,19.54,0,0,1,145.7,160a36.11,36.11,0,0,1,.41,9.91Zm-14.82,13.74a15,15,0,0,0,.91-3.33,17,17,0,0,0,0-4.16c-.25-2.5-2,1.16-2.16,1.91a20,20,0,0,0-.33,5,1.73,1.73,0,0,0,.16.92.8.8,0,0,0,.84.33.88.88,0,0,0,.5-.58Z" />
                    <path d="M156.77,170.28c-.16,2.17-.25,4.33-.33,6.25.42,7.08.67,13.9,2.25,20.57a12.13,12.13,0,0,1,.33,3,3.56,3.56,0,0,1-2.83,3.42,3.48,3.48,0,0,1-4-1.83,15.11,15.11,0,0,1-1.33-3.67c-.58-2.83-1.16-5.66-1.58-8.57-1.33-9.66-1.83-19.41-.42-29.15a20.87,20.87,0,0,1,1.25-5.08c2.83-6.66,10.66-8.08,15.74-2.83a22.39,22.39,0,0,1,4.41,7.58c.42,1.08.83,2.16,1.33,3.25,0,.08.59.08.59,0a16,16,0,0,0,.91-2.84,99.93,99.93,0,0,1,4.67-15.48,26.64,26.64,0,0,1,1.58-3.58,7.55,7.55,0,0,1,3.33-3.33,5.91,5.91,0,0,1,6.58.33A6.31,6.31,0,0,1,192,144.8a7.35,7.35,0,0,1-.42,1.25c-3.42,10.58-4.75,21.57-5.5,32.64-.33,4.67-.58,9.33-1.08,13.91a57.82,57.82,0,0,1-1.75,7.58,12.41,12.41,0,0,1-1.08,2.41c-3.91,7-12.32,7.41-16.74.67a37.34,37.34,0,0,1-5.49-16.41c-.67-5.91-1.25-11.82-1.92-17.73,0-.25-.41-.5-.66-.75-.17.25-.42.41-.5.66a3.32,3.32,0,0,0-.25,1.25Z" />
                    <path d="M188,204.09a38.09,38.09,0,0,1-1.5-12.24,77.32,77.32,0,0,1,5.17-26.4A33.51,33.51,0,0,1,198,154.63a14.87,14.87,0,0,1,7.91-4.83,9.06,9.06,0,0,1,9.41,3.16,12.51,12.51,0,0,1,2.83,10.16c-.25,2-1.24,3.25-2.66,3.5-1.75.33-3.08-.42-4.08-2.33-.42-.75-.67-1.58-1.08-2.33a1,1,0,0,0-1.75-.09,16.28,16.28,0,0,0-1,1.5A54.36,54.36,0,0,0,203,175.11c-.33,1.33,0,1.75,1.42,1.83.83.09,1.66,0,2.49.09a4.6,4.6,0,0,1,.59,9.07,25.43,25.43,0,0,1-5.08.17c-1.25,0-1.75.33-1.92,1.58a33.4,33.4,0,0,0,.42,11.49,4.53,4.53,0,0,0,.42,1c.41,1,1.33,1.25,1.91.42,1-1.5,1.92-3.08,2.83-4.66.42-.75.67-1.59,1.08-2.25a5.67,5.67,0,0,1,6.25-2.5,5.84,5.84,0,0,1,4.25,4.91,8.26,8.26,0,0,1-.59,4.67c-1.25,2.74-2.41,5.49-3.74,8.16a15.06,15.06,0,0,1-2.59,3.41c-5.32,5.25-12.32,5.16-17.65-.08a20.18,20.18,0,0,1-4.91-8.33Z" />
                    <path d="M219.15,200.76c-.17-4.16-.25-8.33-.42-14.16a132.56,132.56,0,0,1,1.5-22,24,24,0,0,1,3-8.91c4-6.41,9.74-9.66,17.31-9.32a15,15,0,0,1,12.25,7c4.91,7.24,5,19.07-3.5,26a4.44,4.44,0,0,0-1.17,1,2.79,2.79,0,0,0-.66,1.66c1.24,5.75,2.58,11.5,3.91,17.24a22.42,22.42,0,0,1,.75,3.25,6.23,6.23,0,0,1-3.08,6.41,6.57,6.57,0,0,1-7.16-.17,9.49,9.49,0,0,1-4.17-5.66c-.91-3.41-1.66-6.83-2.49-10.24-.34-1.5-.5-3-.92-4.41a5.55,5.55,0,0,0-1.33-2.25,2.27,2.27,0,0,0-1.67-.59c-.16,0-.41.84-.5,1.34-.16,1.5-.25,3-.33,4.49-.17,3.67-.33,7.33-.58,11a11.39,11.39,0,0,1-.59,2.92,5,5,0,0,1-9.74-.17,19.57,19.57,0,0,1-.66-4.41Zm20.56-32.31c1.58-.83,2.17-2.33,2.17-4.33a11.33,11.33,0,0,0-.5-1.91,2.35,2.35,0,0,0-3.33-1.5,5.09,5.09,0,0,0-2.83,2.91c-.67,1.75-1.17,3.58-1.75,5.41-.5,1.42-.17,2.34,1.58,1.67a30.06,30.06,0,0,0,4.75-2.17Z" />
                    <path d="M43.41,82.2A21.71,21.71,0,0,1,45,90.77a22.68,22.68,0,0,1-4.53,12.69,32.45,32.45,0,0,1-15,11.75c-2.35.93-4.79,1.52-7.14,2.27a2.14,2.14,0,0,0-1.85,2.61c.26,3.19.59,6.38.84,9.57a31.92,31.92,0,0,1,.26,4,6.86,6.86,0,0,1-5.21,6.3,6.75,6.75,0,0,1-7.14-3.19,17,17,0,0,1-1.93-4.37C-.1,119.83-1.11,107.23,1.41,94.47,3.09,86,7.46,79.18,14.85,74.64c4.79-2.94,9.91-4.28,15.54-3.1A16.89,16.89,0,0,1,43.32,82.29ZM27,93.71a18.31,18.31,0,0,0,1.26-3c0-2-1.09-3-2.35-2.69a8.82,8.82,0,0,0-3.19,1.34,12.75,12.75,0,0,0-5.21,9.33,1.17,1.17,0,0,0,1.76,1.17,16.31,16.31,0,0,0,7.81-6.21Z" />
                    <path d="M46.68,123.87a100,100,0,0,1-1.42-15c.25-6.88.5-13.77.84-20.66a37.45,37.45,0,0,1,.67-6.13,9.83,9.83,0,0,1,1.59-4A5.85,5.85,0,0,1,58.7,79.18a12,12,0,0,1,.84,4.2c.25,4.28.33,8.57.58,12.85q.63,9.83,1.52,19.66a24.82,24.82,0,0,0,2.18,8.9c.17.34.67.5,1,.59a1.11,1.11,0,0,0,.67-.59,16.06,16.06,0,0,0,1-3.78c.33-3,.5-6.13.59-9.24.25-7.64.33-15.29.75-22.93a57,57,0,0,1,1.51-9.07,8.52,8.52,0,0,1,1.85-3.53,5.73,5.73,0,0,1,10.17,3.61v.84c-.93,9.24-.42,18.48-.76,27.72-.17,5.29-.5,10.58-1.09,15.79a27.06,27.06,0,0,1-4,11.51A15.91,15.91,0,0,1,70,141.25a12.65,12.65,0,0,1-14.45-.75,21.72,21.72,0,0,1-5.2-5.88,29.72,29.72,0,0,1-3.95-11.17Z" />
                    <path d="M82.05,132.77c-.17-4.2-.25-8.4-.42-14.28a133.93,133.93,0,0,1,1.51-22.18,24.13,24.13,0,0,1,3-9c4-6.47,9.83-9.75,17.48-9.41A15.09,15.09,0,0,1,116,85c5,7.3,5,19.23-3.52,26.2a4.72,4.72,0,0,0-1.18,1,2.79,2.79,0,0,0-.67,1.68c1.26,5.8,2.6,11.59,4,17.39a22.67,22.67,0,0,1,.75,3.27A6.27,6.27,0,0,1,112.2,141a6.61,6.61,0,0,1-7.22-.17,9.57,9.57,0,0,1-4.2-5.71c-.92-3.44-1.68-6.89-2.52-10.33-.34-1.51-.5-3-.92-4.45A5.73,5.73,0,0,0,96,118.07a2.28,2.28,0,0,0-1.68-.59c-.17,0-.42.84-.5,1.35-.17,1.51-.25,3-.34,4.53-.17,3.7-.33,7.39-.59,11.09a11.44,11.44,0,0,1-.58,2.94,5,5,0,0,1-5.13,3.7,4.89,4.89,0,0,1-4.7-3.87,19.46,19.46,0,0,1-.67-4.45Zm20.75-32.59c1.59-.84,2.18-2.35,2.18-4.37a10.82,10.82,0,0,0-.5-1.93,2.38,2.38,0,0,0-3.36-1.51,5.14,5.14,0,0,0-2.86,2.94c-.67,1.76-1.18,3.61-1.76,5.46-.51,1.42-.17,2.35,1.59,1.68a31.08,31.08,0,0,0,4.79-2.19Z" />
                    <path d="M162.77,82.2a21.71,21.71,0,0,1,1.6,8.57,22.7,22.7,0,0,1-4.54,12.69,32.4,32.4,0,0,1-15,11.75c-2.35.93-4.78,1.52-7.14,2.27a2.13,2.13,0,0,0-1.84,2.61c.25,3.19.58,6.38.84,9.57a33.92,33.92,0,0,1,.25,4,6.83,6.83,0,0,1-5.21,6.3,6.75,6.75,0,0,1-7.14-3.19,17,17,0,0,1-1.93-4.37c-3.36-12.52-4.37-25.12-1.85-37.88,1.68-8.49,6-15.29,13.44-19.83,4.79-2.94,9.91-4.28,15.54-3.1a16.9,16.9,0,0,1,12.94,10.75ZM146.39,93.71a18.31,18.31,0,0,0,1.26-3c0-2-1.09-3-2.35-2.69a8.89,8.89,0,0,0-3.19,1.34,12.75,12.75,0,0,0-5.21,9.33,1.17,1.17,0,0,0,1.76,1.17,16.31,16.31,0,0,0,7.81-6.21Z" />
                    <path d="M191,91.19a34.71,34.71,0,0,1,2.52,9.07,67.89,67.89,0,0,1-.08,16.55c-.5,5-1.26,10.16-3.44,14.7s-6.05,8.57-10.92,9.91c-7.14,1.94-14.87-2.35-18.9-8.48s-5.13-13.78-5.38-21.17c-.25-6.89.17-13.86,2.6-20.24s8-14.37,15.38-14.28c8.06.08,15.2,7,18.31,14Zm-13.77,30.49a50.07,50.07,0,0,0,3.27-25.28,2.49,2.49,0,0,0-.08-.76c-.25-1.51-1-6.3-2.86-6.8-2.85-.76-3.86,4.37-4.36,6.55-2.1,9-3.28,18.73.16,27.3a2.27,2.27,0,0,0,.84,1.26c1.26.76,2.61-.84,3.11-2.18Z" />
                    <path d="M223.08,115.63a31.4,31.4,0,0,1,1.26,12.18,21.3,21.3,0,0,1-1.68,7.48c-2,5-5.88,7.81-11.42,8.4a13.62,13.62,0,0,1-12.94-5.29,15.26,15.26,0,0,1-3.11-13,10.21,10.21,0,0,1,.93-2.69,3.31,3.31,0,0,1,3.69-1.34,3,3,0,0,1,2.44,2.52c.08.75-.09,1.59,0,2.43a10,10,0,0,0,.59,3.19c.75,1.85,2.35,3.28,3.11,2.94,1.34-.58,1.51-2,1.76-3.1A17.21,17.21,0,0,0,204.27,114a25.51,25.51,0,0,0-2.69-2.6c-.93-.85-1.93-1.6-2.77-2.53a14.94,14.94,0,0,1-4.29-14.28A21,21,0,0,1,200,84.3a12.43,12.43,0,0,1,11.34-4,9.54,9.54,0,0,1,8.4,8,6.34,6.34,0,0,1-.5,4c-1.1,2.35-2.69,3.19-5.38,2.77l-.76-.25c-.75-.25-1.59-.76-2.26.08a3.15,3.15,0,0,0,0,3.78,38,38,0,0,0,2.77,2.61A30.67,30.67,0,0,1,222.91,116Z" />
                    <path d="M238.62,98.67v4.79c.08,1.67.34,1.93,2.1,2.26a21.28,21.28,0,0,1,3.11.59,5.52,5.52,0,0,1,4.11,5.21,5.37,5.37,0,0,1-3.78,5.38,23.57,23.57,0,0,1-3.44.75,1.59,1.59,0,0,0-1.51,1.85c.17,2.52.25,5,.59,7.56.33,2.1,1.26,2.85,3.27,2.94a18.55,18.55,0,0,0,3-.42c1-.17,2-.51,3-.59a5.48,5.48,0,0,1,4.87,8.57,6.71,6.71,0,0,1-1.51,1.59c-3.78,2.36-7.73,4.46-12.35,4.54a13.78,13.78,0,0,1-12.68-6.8,20.77,20.77,0,0,1-2.86-8.4,76.39,76.39,0,0,1,0-13.86c.34-7.73.93-15.38,1.43-23a25.09,25.09,0,0,1,.59-4.45,6.13,6.13,0,0,1,4.62-5c6.13-1.43,12.26-2.1,18.39,0a22.29,22.29,0,0,1,3.28,1.43,5.87,5.87,0,0,1,2.6,6.13,5.5,5.5,0,0,1-5.29,4.54,25.54,25.54,0,0,1-6.13-.59c-5.8-1.34-5.63-1.34-5.38,4.54Z" />
                    <path d="M111.9,26.33c-.19,2.34-.29,4.68-.38,6.43-.1,6.73-.59,12.87.48,18.91.29,1.65.68,3.31,1.07,5a23.12,23.12,0,0,1,.59,2.53,7.29,7.29,0,0,1-3,7.12,7.81,7.81,0,0,1-8.19.78,9.4,9.4,0,0,1-5.16-6c-.69-2-1.08-4.19-1.66-6.33a1.71,1.71,0,0,0-2.14-1.56,7.65,7.65,0,0,1-1.47.29c-2,0-2.43.88-2.82,2.83-.2,1.17-.2,2.34-.39,3.51a26.82,26.82,0,0,1-.78,4,5.13,5.13,0,0,1-9.85-.19A21.3,21.3,0,0,1,77.6,57c.39-5.46.78-10.92,1.66-16.28,1-5.85,2.43-11.6,3.89-17.35a42.28,42.28,0,0,1,4.49-11.3,22.52,22.52,0,0,1,5.94-6.92C99,1.18,106,2.55,109.37,8.4a22.64,22.64,0,0,1,2.15,6.23,42.46,42.46,0,0,1,.48,11.6ZM94.56,42.41a18.44,18.44,0,0,0,1.07-3.9,20.87,20.87,0,0,0,0-4.87c-.29-2.93-2.34,1.36-2.53,2.24a23.53,23.53,0,0,0-.39,5.85,2,2,0,0,0,.19,1.07.93.93,0,0,0,1,.39,1,1,0,0,0,.59-.68Z" />
                    <path d="M146.11,67.65c-4.48,2.73-9.36,4.48-14.23,4.19a16.67,16.67,0,0,1-11.4-6.43,21.82,21.82,0,0,1-4.68-10.62,73.44,73.44,0,0,1-1.07-11.21c.1-9.65.49-19.3.78-28.95.1-1.75,0-3.5.19-5.26a19.18,19.18,0,0,1,1.08-4.68A7,7,0,0,1,123.89,0a7.16,7.16,0,0,1,6.72,5.27,16.06,16.06,0,0,1,.69,4.77c.19,8.09.29,16.18.48,24.27.1,4.48.39,9,.69,13.45a26.21,26.21,0,0,0,.78,4.77C133.93,55,135.39,56,138,56c1.07,0,2.14-.3,3.22-.39,3.6-.3,6.43,1.46,7.5,4.67a6.73,6.73,0,0,1-2.73,8Z" />
                    <path d="M180.51,67.65c-4.48,2.73-9.35,4.48-14.23,4.19a16.67,16.67,0,0,1-11.4-6.43,21.92,21.92,0,0,1-4.68-10.62,73.44,73.44,0,0,1-1.07-11.21c.1-9.65.49-19.3.78-28.95.1-1.75,0-3.5.19-5.26a19.18,19.18,0,0,1,1.08-4.68A7,7,0,0,1,158.29,0,7.18,7.18,0,0,1,165,5.28a16.37,16.37,0,0,1,.68,4.77c.19,8.09.29,16.18.49,24.27.09,4.48.39,9,.68,13.45a26.21,26.21,0,0,0,.78,4.77c.68,2.44,2.14,3.42,4.77,3.42,1.08,0,2.15-.3,3.22-.39,3.61-.3,6.43,1.46,7.5,4.67a6.73,6.73,0,0,1-2.73,8Z" />
                </g>
            </g>
        </svg>
    )
}
