import React from "react"

export default () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 278.6 213.5">
            <defs>
                <style></style>
            </defs>
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                    <path d="M54.68,202.4c-.16-4.17-.24-8.33-.41-14.16a132.56,132.56,0,0,1,1.5-22,24.1,24.1,0,0,1,3-8.91c4-6.41,9.75-9.66,17.32-9.33a15,15,0,0,1,12.24,7c4.92,7.24,5,19.07-3.49,26a4.44,4.44,0,0,0-1.17,1,2.79,2.79,0,0,0-.66,1.66c1.24,5.75,2.58,11.49,3.91,17.24a22.29,22.29,0,0,1,.75,3.24,6.24,6.24,0,0,1-3.08,6.42,6.57,6.57,0,0,1-7.16-.17,9.49,9.49,0,0,1-4.17-5.66c-.91-3.42-1.66-6.83-2.49-10.24-.34-1.5-.5-3-.92-4.42a5.68,5.68,0,0,0-1.33-2.25,2.32,2.32,0,0,0-1.67-.58c-.16,0-.41.83-.5,1.33-.16,1.5-.25,3-.33,4.5-.17,3.66-.33,7.33-.58,11a11.39,11.39,0,0,1-.59,2.92,5,5,0,0,1-9.74-.17,19.11,19.11,0,0,1-.66-4.41Zm20.57-32.31c1.58-.83,2.17-2.33,2.17-4.33a11.67,11.67,0,0,0-.5-1.92,2.36,2.36,0,0,0-3.33-1.5,5.11,5.11,0,0,0-2.83,2.92c-.67,1.75-1.17,3.58-1.75,5.41-.5,1.42-.17,2.33,1.58,1.67a32,32,0,0,0,4.75-2.17Z" />
                    <path d="M111.14,199c-.16-.66-.33-1.41-.41-1.75-.09-4.24-1.42-3.66-4.17-3.16a2.39,2.39,0,0,0-2.08,1.83c-1,3.08-2,6.08-3.08,9.08a26.93,26.93,0,0,1-1.42,3.66,4.7,4.7,0,0,1-4.74,2.42,4.47,4.47,0,0,1-3.5-4,8.59,8.59,0,0,1-.08-2.58,37.49,37.49,0,0,1,.83-4.25c1.67-8.08,3.41-16.15,5.08-24.31a41.09,41.09,0,0,1,5.66-14.49,15.5,15.5,0,0,1,5.5-5.41c4.33-2.33,8.41-1.92,12.32.83a13.23,13.23,0,0,1,4.66,6.16,24.21,24.21,0,0,1,1.5,11.91c-.41,3.16-1,6.33-1.66,9.41a46,46,0,0,0-1.42,11.24c0,.83,0,1.75-.08,2.58a6.58,6.58,0,0,1-6,6c-3.08.25-5.74-1.74-6.74-5.07Zm-.75-15.49c.42-2.41.75-4.82,1.09-7.07-.34-.34-.5-.5-.59-.5-.25.08-.58.16-.66.33-.67,2.33-1.34,4.66-1.92,7-.08.24.42.66.75.83.17.08.58-.09.83-.25s.59-.42.59-.67Z" />
                    <path d="M143.29,192.32H143c-.25-2.75-.5-5.49-.84-8.24a11.26,11.26,0,0,0-.66-2.25,13.73,13.73,0,0,0-.75,2.5c-.17,3.16-.34,6.33-.34,9.49.09,3.91.34,7.83.5,11.74a15.34,15.34,0,0,1-.16,3.41,5.4,5.4,0,0,1-4.33,4.42,5,5,0,0,1-5.33-2.17,8.74,8.74,0,0,1-1.5-3.08,84.77,84.77,0,0,1-1.42-9,100.85,100.85,0,0,1,.67-26.48,68.59,68.59,0,0,1,1.75-7.16,12.47,12.47,0,0,1,3.66-5.75,11,11,0,0,1,16,.75,19.57,19.57,0,0,1,3.91,7.25,30,30,0,0,0,.84,2.91c.33.83.08,2.08,1.41,2.42a12.93,12.93,0,0,0,.42-2.5c.17-4.08.25-8.16.42-12.24a25.9,25.9,0,0,1,.58-5.16,5.1,5.1,0,0,1,4.83-3.75,5,5,0,0,1,4.66,4.41,21.15,21.15,0,0,1,.08,2.25c.09,1.42.09,2.83.17,4.25a111.31,111.31,0,0,1-4.25,36.72,55,55,0,0,1-2.66,7.32,17.06,17.06,0,0,1-3.41,5,6.79,6.79,0,0,1-11.08-1.33A19.54,19.54,0,0,1,144,202c-.59-3.16-.75-6.41-1.09-9.58Z" />
                    <path d="M181.84,152.69a35.23,35.23,0,0,1,5.41-1.75c4.67.33,7.75,1.91,9.83,5.33a7.66,7.66,0,0,1,.92,5.41,4.18,4.18,0,0,1-5.08,3.58,2.74,2.74,0,0,1-1.17-.42c-1.91-1.16-3.33-.5-4.58,1a2.28,2.28,0,0,0-.67,1.5c.09,1,1.09.75,1.75.83,2,.25,4,.42,6,.75,3.83.67,5.74,2.75,6.58,6.5a26.9,26.9,0,0,1-.42,11.24,37.67,37.67,0,0,1-4,11.49,24.22,24.22,0,0,1-4,5.58c-4.92,4.83-13.33,5.91-19-1.42a27.41,27.41,0,0,1-5.24-12.65,42.45,42.45,0,0,1,4.16-27.48,20.89,20.89,0,0,1,9.74-9.58Zm8.24,31.14c1.5-3.42,2-4.33-2.83-3.83h-.33c-4.25,0-4.41-.09-3.83,4.49a47,47,0,0,0,1.33,5.92.93.93,0,0,0,1.67.25c1.33-2.25,2.75-4.42,3.83-6.75Z" />
                    <path d="M221.06,152.6a23.72,23.72,0,0,1,3.42.25,16.26,16.26,0,0,1,4.41,1.42,6.94,6.94,0,0,1,3.5,7.66,7.08,7.08,0,0,1-6.25,5.66,35.28,35.28,0,0,1-4.24,0c-1.25,0-2.5-.08-3.75-.08a2,2,0,0,0-2.25,1.75,34.81,34.81,0,0,0-.58,5.16c-.09.91.5,1.41,1.41,1.5a43.59,43.59,0,0,1,4.92.5,3.6,3.6,0,0,1,2.66,4.24,4.06,4.06,0,0,1-3.5,3.33c-1.16.17-2.33.09-3.49.17-.75.08-1.34.5-1.17,1.33a58.4,58.4,0,0,0,1.25,6.66,2.7,2.7,0,0,0,2.83,2.17c1.5-.08,3-.33,4.5-.33a11.36,11.36,0,0,1,3.41.25,5.57,5.57,0,0,1,2.16,9.24,11.14,11.14,0,0,1-2.74,2.08,14.51,14.51,0,0,1-18.32-2.91,20.13,20.13,0,0,1-4.5-8.66,109.13,109.13,0,0,1-2.83-17.24,33.1,33.1,0,0,1,.67-11.82,21.24,21.24,0,0,1,.66-2.25c2.08-6.41,6.41-9.74,12.91-10.08a43.82,43.82,0,0,1,4.58.17Z" />
                    <path d="M21.88,80c5.33,2.13,7.58,6.33,8,11.75a23,23,0,0,1-2.5,11.76c-1,2-2.25,3.78-3.42,5.67-.83,1.4-.83,1.48.34,2.8.5.57,1,1.07,1.5,1.64A18.73,18.73,0,0,1,24,138.35a13.07,13.07,0,0,1-20.09-2.06A22.18,22.18,0,0,1,.29,125.93a61.5,61.5,0,0,1,0-11.75A113.14,113.14,0,0,1,6.79,84.5a19.59,19.59,0,0,1,1.92-4.28c1.83-3,4.67-3.94,8-2.63,1.75.66,3.42,1.48,5.17,2.22Zm-7.42,42.5c0,.25.08.5.08.74h-.25c.42,1.4.84,2.8,1.25,4.2a.84.84,0,0,0,1.25.16,5,5,0,0,0,1-5.67c-.58-1.07-1.25-2.63-2.41-2.39-1.5.25-.75,2-.92,3ZM21,98.8a15.25,15.25,0,0,0,1.59-6.41,6.89,6.89,0,0,0-1.75-4.6c-.59-.66-1.5-.58-1.75.16a9.13,9.13,0,0,0-.42,1.48C17.88,93.62,17,97.82,16.29,102a12.73,12.73,0,0,0-.08,2.47c1.25.16,1.58-1,2.08-1.65a32.07,32.07,0,0,0,2.5-4Z" />
                    <path d="M59.47,102.26c-.17,2-.25,3.94-.33,5.42-.09,5.68-.5,10.86.41,15.95.25,1.4.59,2.8.92,4.2A18.23,18.23,0,0,1,61,130a6.08,6.08,0,0,1-2.58,6,6.73,6.73,0,0,1-7,.66A7.94,7.94,0,0,1,47,131.53c-.59-1.73-.92-3.54-1.42-5.35a1.45,1.45,0,0,0-1.83-1.31,7.06,7.06,0,0,1-1.25.24c-1.76,0-2.09.74-2.42,2.39-.17,1-.17,2-.34,3a21.61,21.61,0,0,1-.66,3.37,4.4,4.4,0,0,1-8.42-.17,17.44,17.44,0,0,1-.5-5.5c.33-4.61.67-9.21,1.42-13.74.83-4.93,2.08-9.78,3.33-14.63a35.19,35.19,0,0,1,3.83-9.54,19,19,0,0,1,5.09-5.83,9,9,0,0,1,13.5,2.71,18.77,18.77,0,0,1,1.84,5.26,35.19,35.19,0,0,1,.41,9.78ZM44.63,115.82a14.73,14.73,0,0,0,.92-3.29,16.62,16.62,0,0,0,0-4.11c-.25-2.46-2,1.15-2.17,1.89a20,20,0,0,0-.33,4.94,1.57,1.57,0,0,0,.17.9.79.79,0,0,0,.83.33.86.86,0,0,0,.5-.58Z" />
                    <path d="M65.14,132.43c-2.92-5.59-4-11.59-4-18.66A45.41,45.41,0,0,1,68,89.92,25.59,25.59,0,0,1,75.22,82a15.65,15.65,0,0,1,8.92-3A11.39,11.39,0,0,1,95,93.38a7.24,7.24,0,0,1-4.34,4.85,3.84,3.84,0,0,1-4.58-.58c-1.33-1.15-1.92-1.07-2.75.41A41.14,41.14,0,0,0,80.22,104c-2,4.85-2.16,10-1.58,15a20.07,20.07,0,0,0,1.17,4.36c.58,1.56,1.92,1.81,3.17.57a10.1,10.1,0,0,0,2.16-2.79,41.14,41.14,0,0,0,2-5.43c.42-1.15.5-2.38.92-3.53A6.06,6.06,0,0,1,93.9,108a5.43,5.43,0,0,1,5.16,5.35c-.16,7.15-.66,14.3-5,20.55a20.55,20.55,0,0,1-5.75,5.59,14.49,14.49,0,0,1-15.09,1,17.52,17.52,0,0,1-8.08-8.14Z" />
                    <path d="M129.4,92.8h-1c-1.58-.08-3.25-.16-4,1a3.36,3.36,0,0,0-.42,2l1.26,32.56a19,19,0,0,1-.67,7.15,7.33,7.33,0,0,1-5.42,4.85,7.11,7.11,0,0,1-6.67-3.2,16.3,16.3,0,0,1-2.25-7.07c-1.75-11.92-1.5-24.09-1-36.18a3.13,3.13,0,0,0-.58-2.3c-.75-.82-2.09-.74-3.25-.82-3.17-.25-6.09-2.71-6.34-5.67-.75-10.61,23.59-6.5,29.59-5.43a13.79,13.79,0,0,1,5.09,1.73,6.16,6.16,0,0,1,2.5,7.07,7.31,7.31,0,0,1-6.59,4.52Z" />
                    <path d="M131.82,135.88a37.09,37.09,0,0,1-1.5-12.08,75.43,75.43,0,0,1,5.17-26.06,33.12,33.12,0,0,1,6.33-10.69,14.89,14.89,0,0,1,7.92-4.77,9.16,9.16,0,0,1,9.42,3.12,12.23,12.23,0,0,1,2.84,10c-.25,2-1.26,3.21-2.67,3.46-1.75.33-3.09-.41-4.09-2.31-.41-.74-.66-1.56-1.08-2.3a1,1,0,0,0-1.75-.08,16.05,16.05,0,0,0-1,1.48,53.72,53.72,0,0,0-4.59,11.59c-.33,1.32,0,1.73,1.42,1.81.83.08,1.67,0,2.5.08a4.54,4.54,0,0,1,.59,9,27.09,27.09,0,0,1-5.09.16c-1.25,0-1.75.33-1.92,1.56a32.6,32.6,0,0,0,.42,11.35,4.59,4.59,0,0,0,.42,1c.41,1,1.33,1.24,1.91.41,1-1.48,1.92-3,2.84-4.6.42-.74.67-1.56,1.08-2.22a5.71,5.71,0,0,1,6.25-2.47,5.8,5.8,0,0,1,4.26,4.86,7.94,7.94,0,0,1-.59,4.6c-1.25,2.71-2.42,5.42-3.75,8.06a14.8,14.8,0,0,1-2.58,3.37c-5.34,5.18-12.34,5.09-17.68-.09a19.79,19.79,0,0,1-4.91-8.22Z" />
                    <path d="M163,132.59c-.16-4.11-.25-8.22-.41-14a128.23,128.23,0,0,1,1.5-21.71,23.43,23.43,0,0,1,3-8.79c4-6.33,9.75-9.54,17.34-9.21a15,15,0,0,1,12.25,6.9c4.92,7.16,5,18.83-3.5,25.65a4.61,4.61,0,0,0-1.17,1,2.74,2.74,0,0,0-.66,1.65c1.25,5.67,2.58,11.34,3.91,17a21.91,21.91,0,0,1,.75,3.21,6.11,6.11,0,0,1-3.08,6.33,6.67,6.67,0,0,1-7.17-.16,9.4,9.4,0,0,1-4.17-5.59c-.91-3.37-1.66-6.74-2.5-10.12-.33-1.48-.5-3-.92-4.35a5.43,5.43,0,0,0-1.33-2.22,2.29,2.29,0,0,0-1.67-.58c-.16,0-.41.82-.5,1.32-.16,1.48-.25,3-.33,4.44-.17,3.61-.33,7.23-.58,10.85a11.09,11.09,0,0,1-.59,2.88,5.06,5.06,0,0,1-9.75-.17,19.23,19.23,0,0,1-.67-4.36Zm20.59-31.9c1.59-.82,2.17-2.3,2.17-4.27a10.5,10.5,0,0,0-.5-1.89,2.36,2.36,0,0,0-3.33-1.48,5.06,5.06,0,0,0-2.84,2.88c-.67,1.72-1.17,3.53-1.75,5.34-.5,1.4-.17,2.3,1.59,1.64a30.82,30.82,0,0,0,4.75-2.13Z" />
                    <path d="M216.42,119.85c.09,4.61-.08,9.21-.16,13.81a11.91,11.91,0,0,1-.34,3,6.3,6.3,0,0,1-5.5,4.85,6.75,6.75,0,0,1-6.75-3.86c-.33-.66-.5-1.32-.83-2.14-.09-1.4-.34-3-.34-4.52.09-8.63.17-17.26.34-25.9a19.59,19.59,0,0,1,.33-3.86,8.85,8.85,0,0,1,1.33-3.13,5.66,5.66,0,0,1,9.51.09,8.26,8.26,0,0,1,1.41,4.44c.25,5.75.67,11.51.84,17.26Z" />
                    <path d="M246.77,102.26c-.17,2-.25,3.94-.34,5.42-.08,5.68-.5,10.86.42,15.95.25,1.4.58,2.8.92,4.2.16.74.41,1.39.5,2.13a6.07,6.07,0,0,1-2.59,6,6.71,6.71,0,0,1-7,.66,8,8,0,0,1-4.42-5.09c-.58-1.73-.92-3.54-1.42-5.35a1.45,1.45,0,0,0-1.83-1.31,6.89,6.89,0,0,1-1.25.24c-1.75,0-2.08.74-2.42,2.39-.16,1-.16,2-.33,3a21.61,21.61,0,0,1-.67,3.37,4.4,4.4,0,0,1-8.42-.17,18,18,0,0,1-.5-5.5c.34-4.61.67-9.21,1.42-13.74.83-4.93,2.08-9.78,3.33-14.63A35.54,35.54,0,0,1,226,90.25a19,19,0,0,1,5.08-5.83,9.05,9.05,0,0,1,13.51,2.71,18.44,18.44,0,0,1,1.83,5.26,34.81,34.81,0,0,1,.42,9.78Zm-14.84,13.56a14.71,14.71,0,0,0,.91-3.29,16.62,16.62,0,0,0,0-4.11c-.25-2.46-2,1.15-2.16,1.89a19.58,19.58,0,0,0-.34,4.94,1.57,1.57,0,0,0,.17.9.8.8,0,0,0,.83.33.86.86,0,0,0,.5-.58Z" />
                    <path d="M276,137.12c-3.83,2.3-8,3.78-12.17,3.53a14.34,14.34,0,0,1-9.75-5.42,18.3,18.3,0,0,1-4-9,60.48,60.48,0,0,1-.92-9.45c.08-8.14.42-16.28.67-24.42.08-1.48,0-3,.16-4.44a16.13,16.13,0,0,1,.92-3.94,6,6,0,0,1,6.09-4,6.11,6.11,0,0,1,5.75,4.44,13.32,13.32,0,0,1,.58,4c.17,6.82.25,13.64.42,20.47.08,3.78.33,7.56.58,11.34a23.38,23.38,0,0,0,.67,4c.58,2.06,1.83,2.88,4.08,2.88.92,0,1.84-.25,2.75-.33a5.85,5.85,0,0,1,6.42,4,5.62,5.62,0,0,1-2.33,6.74Z" />
                    <path d="M90.78,34.5c-.18,2.09-.27,4.19-.35,5.76-.09,6-.53,11.53.43,16.94.26,1.49.61,3,1,4.46a20.16,20.16,0,0,1,.53,2.27,6.51,6.51,0,0,1-2.71,6.37,7,7,0,0,1-7.34.7,8.44,8.44,0,0,1-4.62-5.41c-.61-1.84-1-3.76-1.49-5.68-.26-1.14-.78-1.48-1.92-1.4a6.75,6.75,0,0,1-1.31.26c-1.83,0-2.18.79-2.53,2.54-.18,1-.18,2.09-.35,3.14a24.12,24.12,0,0,1-.7,3.58,4.59,4.59,0,0,1-8.82-.17A18.73,18.73,0,0,1,60,62c.35-4.89.69-9.78,1.48-14.59C62.39,42.18,63.7,37,65,31.88a38.07,38.07,0,0,1,4-10.13,20.17,20.17,0,0,1,5.33-6.2A9.39,9.39,0,0,1,88.5,18.43,20,20,0,0,1,90.43,24a38,38,0,0,1,.43,10.39ZM75.23,48.91a15.5,15.5,0,0,0,1-3.5,17.66,17.66,0,0,0,0-4.36c-.26-2.62-2.09,1.22-2.27,2a21,21,0,0,0-.35,5.24,1.69,1.69,0,0,0,.18,1,.84.84,0,0,0,.87.35.91.91,0,0,0,.52-.62Z" />
                    <path d="M102,34.76c-.17,2.27-.26,4.54-.35,6.55.44,7.42.7,14.58,2.36,21.57A12.92,12.92,0,0,1,104.4,66a3.72,3.72,0,0,1-3,3.58,3.63,3.63,0,0,1-4.19-1.92,15.55,15.55,0,0,1-1.4-3.84c-.61-3-1.22-5.94-1.66-9-1.4-10.12-1.92-20.34-.44-30.56A22.47,22.47,0,0,1,95.05,19c3-7,11.18-8.47,16.51-3a23.72,23.72,0,0,1,4.63,8c.43,1.14.87,2.27,1.39,3.41,0,.08.62.08.62,0a17.18,17.18,0,0,0,1-3,104.34,104.34,0,0,1,4.89-16.24,26.83,26.83,0,0,1,1.66-3.76A7.9,7.9,0,0,1,129.2.88a6.18,6.18,0,0,1,6.9.35A6.6,6.6,0,0,1,139,8a7.76,7.76,0,0,1-.44,1.31c-3.58,11.09-5,22.62-5.76,34.23-.35,4.89-.61,9.78-1.14,14.58a61.47,61.47,0,0,1-1.83,8,13.37,13.37,0,0,1-1.13,2.53c-4.11,7.34-12.93,7.77-17.56.7a39.2,39.2,0,0,1-5.76-17.2c-.7-6.2-1.31-12.4-2-18.6,0-.26-.44-.53-.7-.79-.17.26-.43.44-.52.7a3.4,3.4,0,0,0-.26,1.31Z" />
                    <path d="M167,24.46h-1c-1.66-.09-3.4-.18-4.19,1a3.72,3.72,0,0,0-.43,2.19Q162,45,162.65,62.27a20.55,20.55,0,0,1-.7,7.6A7.74,7.74,0,0,1,156.27,75a7.46,7.46,0,0,1-7-3.41,17.29,17.29,0,0,1-2.35-7.51c-1.84-12.66-1.57-25.58-1.05-38.42a3.35,3.35,0,0,0-.61-2.45c-.79-.87-2.19-.78-3.41-.87-3.32-.26-6.37-2.88-6.63-6-.79-11.26,24.71-6.89,31-5.76a14.45,14.45,0,0,1,5.32,1.83,6.59,6.59,0,0,1,2.62,7.51,7.66,7.66,0,0,1-6.9,4.81Z" />
                    <path d="M181.86,53.19c.08,4.89-.09,9.78-.18,14.67a12.5,12.5,0,0,1-.35,3.14,6.65,6.65,0,0,1-5.76,5.15,7.06,7.06,0,0,1-7.07-4.1c-.35-.7-.53-1.4-.88-2.27-.08-1.49-.35-3.15-.35-4.81.09-9.16.18-18.33.35-27.5a21.31,21.31,0,0,1,.35-4.11,9.27,9.27,0,0,1,1.4-3.32,5.88,5.88,0,0,1,9.95.09,8.82,8.82,0,0,1,1.49,4.72c.26,6.11.7,12.22.87,18.34Z" />
                    <path d="M204.56,58c-3.53.09-7.05-.08-10.57-.17a6.83,6.83,0,0,1-2.26-.35c-2.08-.7-3.53-3.06-3.71-5.76-.19-3,.94-5.77,2.95-7.08.5-.35,1-.52,1.64-.87,1.07-.09,2.26-.35,3.46-.35,6.6.09,13.2.18,19.81.35a11.14,11.14,0,0,1,3,.35,5.82,5.82,0,0,1,2.39,1.4,7.23,7.23,0,0,1-.06,10A5.28,5.28,0,0,1,217.77,57c-4.41.26-8.81.7-13.21.87Z" />
                </g>
            </g>
        </svg>
    )
}
