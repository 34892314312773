import React from "react"

export default () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.4 43.41">
            <defs>
                <style></style>
            </defs>
            <title>Recyclable Bottle logo</title>
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                    <path d="M4.83,38a.8.8,0,0,1-.91.89c-.68,0-1.13-.28-1.16-1a.77.77,0,0,0-.08-.45.5.5,0,0,0-.39-.13H2.13v.61H2.6v.9H.38v-.9H.94V36.07H.38v-.89H3.11c.65,0,1.23.31,1.23,1s-.52.88-1.07.89a1.32,1.32,0,0,1,.63.27c.3.26.24.68.31.73a.11.11,0,0,0,.08,0c.15,0,.15-.29.15-.39h.38A1.93,1.93,0,0,1,4.83,38ZM3.08,36.4a.33.33,0,0,0-.35-.33h-.6v.66H2.7C2.92,36.73,3.08,36.63,3.08,36.4Z" />
                    <path d="M8,37.76c-.2.81-.68,1.08-1.54,1.08a1.33,1.33,0,0,1-1.48-1.41A1.48,1.48,0,0,1,6.52,36c1,0,1.41.69,1.41,1.59H6.08a.23.23,0,0,0,0,0c0,.31.12.56.46.56A.42.42,0,0,0,7,37.76ZM6.84,37.1c0-.23-.08-.48-.36-.48a.37.37,0,0,0-.4.42v.06Z" />
                    <path d="M10.94,37.57c0,.89-.59,1.27-1.44,1.27a1.35,1.35,0,0,1-1.45-1.41A1.43,1.43,0,0,1,9.59,36a1.21,1.21,0,0,1,1.32,1.22H9.86c0-.18,0-.5-.23-.5s-.38.45-.38.69,0,.73.43.73.4-.36.4-.63h.86Z" />
                    <path d="M14.3,36.81h-.4l-.74,1.68a3,3,0,0,1-.57.93.94.94,0,0,1-.65.26.77.77,0,0,1-.84-.77H12c0,.11,0,.29.14.29s.12-.12.12-.19a.82.82,0,0,0-.06-.26c-.26-.66-.58-1.29-.86-1.94H11v-.74h1.75v.74h-.44c.14.35.3.69.44,1,.16-.35.3-.7.45-1h-.28v-.74H14.3Z" />
                    <path d="M17.23,37.57c0,.89-.6,1.27-1.45,1.27a1.34,1.34,0,0,1-1.44-1.41A1.42,1.42,0,0,1,15.87,36a1.2,1.2,0,0,1,1.32,1.22h-1c0-.18,0-.5-.24-.5s-.38.45-.38.69,0,.73.44.73.4-.36.4-.63h.86Z" />
                    <path d="M19.07,38.78H17.33V38h.37V35.91h-.37v-.73H18.7V38h.37Z" />
                    <path d="M22.76,38c0,.62-.4.83-1,.83a.78.78,0,0,1-.82-.5,1.08,1.08,0,0,1-1,.5c-.47,0-.79-.23-.79-.71,0-.79,1.24-.93,1.85-.93,0-.25,0-.53-.32-.53s-.34.17-.34.39h-1l0-.13c.08-.7.67-.93,1.32-.93s1.35.15,1.35.89v1.18c0,.09,0,.17.14.17s.12-.33.12-.43h.47A1.23,1.23,0,0,1,22.76,38ZM21,37.49h0c-.26,0-.62.12-.62.42a.23.23,0,0,0,.23.26C20.92,38.17,21,37.76,21,37.49Z" />
                    <path d="M26,37.36c0,.82-.33,1.46-1.25,1.46a1,1,0,0,1-1-.59c0,.18-.09.37-.13.55h-.54V35.91h-.37v-.73H24v1.25a1,1,0,0,1,.89-.4C25.71,36,26,36.69,26,37.36Zm-1.11.06c0-.26-.05-.65-.39-.65s-.46.32-.46.63.05.7.43.7S24.89,37.72,24.89,37.42Z" />
                    <path d="M27.9,38.78H26.16V38h.37V35.91h-.37v-.73h1.37V38h.37Z" />
                    <path d="M31,37.76c-.2.81-.69,1.08-1.54,1.08A1.33,1.33,0,0,1,28,37.43,1.48,1.48,0,0,1,29.61,36c1,0,1.4.69,1.4,1.59H29.16v0c0,.31.12.56.46.56a.42.42,0,0,0,.46-.43Zm-1.12-.66c0-.23-.07-.48-.35-.48a.38.38,0,0,0-.41.42v.06Z" />
                    <path d="M11.27,42.37c0,.78-.79,1-1.44,1H7v-.9h.56V40.63H7v-.89H9.87c.59,0,1.26.27,1.26.94,0,.47-.37.72-.8.81l-.1,0h.1C10.82,41.59,11.27,41.82,11.27,42.37ZM9.79,41a.33.33,0,0,0-.37-.34H8.77v.67h.65A.33.33,0,0,0,9.79,41Zm0,1.14c0-.27-.22-.33-.45-.33H8.77v.66h.6C9.6,42.44,9.81,42.37,9.81,42.11Z" />
                    <path d="M14.43,42a1.42,1.42,0,0,1-1.5,1.4,1.43,1.43,0,0,1-1.5-1.4,1.45,1.45,0,0,1,1.48-1.44A1.38,1.38,0,0,1,14.43,42Zm-1.11,0c0-.27,0-.75-.39-.75s-.4.48-.4.75,0,.74.4.74S13.32,42.25,13.32,42Z" />
                    <path d="M16.9,42.62c0,.55-.39.76-.91.76a.87.87,0,0,1-1-1v-1h-.49v-.74c.67,0,.82,0,.82-.72l.67,0v.75h.82v.74H16v1.17c0,.12,0,.3.16.3s.15-.32.15-.45h.55A.93.93,0,0,1,16.9,42.62Z" />
                    <path d="M19.38,42.62c0,.55-.39.76-.91.76a.87.87,0,0,1-1-1v-1H17v-.74c.67,0,.82,0,.82-.72l.67,0v.75h.82v.74h-.82v1.17c0,.12,0,.3.16.3s.16-.32.15-.45h.56A1.81,1.81,0,0,1,19.38,42.62Z" />
                    <path d="M21.25,43.34H19.52v-.73h.37V40.48h-.37v-.74h1.36v2.87h.37Z" />
                    <path d="M24.4,42.32c-.21.82-.69,1.09-1.55,1.09A1.34,1.34,0,0,1,21.37,42,1.49,1.49,0,0,1,23,40.57c1,0,1.41.68,1.41,1.58H22.52v.05c0,.3.12.56.45.56a.43.43,0,0,0,.47-.44Zm-1.12-.66c0-.23-.08-.47-.35-.47a.38.38,0,0,0-.41.41v.06Z" />
                    <path d="M16.31,27.82a.32.32,0,0,0-.35.36v.05h.67C16.64,28,16.56,27.82,16.31,27.82Zm0,0a.32.32,0,0,0-.35.36v.05h.67C16.64,28,16.56,27.82,16.31,27.82Zm0,0a.32.32,0,0,0-.35.36v.05h.67C16.64,28,16.56,27.82,16.31,27.82Z" />
                    <path d="M23.87,4.55h0L22.31,8V8a11.53,11.53,0,0,1,1.23,18.49l-.36.31-.36-.32A10,10,0,0,0,16.62,24V20.85h1a3.15,3.15,0,0,0,3.18-3.11v-1H19.18a3.14,3.14,0,0,0-2.26.94l-.3.29V14.62h1a3.15,3.15,0,0,0,3.18-3.11v-1H19.18A3.13,3.13,0,0,0,16.47,12l-.46.75L15.55,12a3.15,3.15,0,0,0-2.72-1.54H11.24v1a3.15,3.15,0,0,0,3.17,3.11h1V18l-.3-.3a3.19,3.19,0,0,0-2.26-.94H11.24v1a3.15,3.15,0,0,0,3.17,3.11h1V24a9.86,9.86,0,0,0-6.48,2.7l-.34.34-.38-.3a11.58,11.58,0,0,1-4.41-9.07A11.77,11.77,0,0,1,15.12,6l.56,0V7.92a.38.38,0,0,0,.25.36.37.37,0,0,0,.15,0,.39.39,0,0,0,.27-.11l3.94-3.77a.38.38,0,0,0,.12-.28.36.36,0,0,0-.12-.27L16.35.11A.39.39,0,0,0,16.08,0a.45.45,0,0,0-.16,0,.38.38,0,0,0-.24.36v1.9l-.52,0A15.47,15.47,0,0,0,0,17.68,15.58,15.58,0,0,0,15.71,33.07,15.56,15.56,0,0,0,31.4,17.68,15.24,15.24,0,0,0,23.87,4.55ZM14.35,28.93v.8H12.44v-.8h.43v-.28c-.31-.44-.63-.88-.93-1.33h-.42v-.8h1.92v.8H13c.17.27.35.53.52.79.18-.26.37-.52.55-.79h-.47v-.8h1.68v.8h-.43c-.31.44-.63.88-.92,1.33v.28Zm1.9.86a1.19,1.19,0,0,1-1.32-1.26,1.33,1.33,0,0,1,1.42-1.27c.89,0,1.25.61,1.25,1.41H16a.23.23,0,0,0,0,0c0,.27.11.5.41.5a.38.38,0,0,0,.41-.39h.85C17.44,29.55,17,29.79,16.25,29.79Zm3,0a1,1,0,0,1-.83-.33v.3h-.59v-.84h.71a.71.71,0,0,0,.62.42c.1,0,.27,0,.27-.13,0-.37-1.54-.14-1.54-1.13a.79.79,0,0,1,.87-.77.71.71,0,0,1,.68.39v-.35h.6v.76h-.64a.66.66,0,0,0-.52-.33c-.09,0-.23,0-.23.15,0,.35,1.57.07,1.57,1.08C20.26,29.56,19.82,29.76,19.29,29.76Z" />
                </g>
            </g>
        </svg>
    )
}
