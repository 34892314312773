import React from "react"

export default () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.86 44.24">
            <defs>
                <style></style>
            </defs>
            <title>Grey Water Safe logo</title>
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                    <path d="M5.85,37.32H5.28v1.46h-1V38.4a1.4,1.4,0,0,1-1.15.44A1.69,1.69,0,0,1,1.39,37a1.83,1.83,0,0,1,1.77-1.9,1.15,1.15,0,0,1,1.08.53v-.46h1v1.36h-1A.71.71,0,0,0,3.5,36c-.57,0-.76.55-.76,1s.2,1,.78,1,.67-.29.71-.71H3.66v-.54H5.85Z" />
                    <path d="M8.89,36.88c0,.1,0,.22,0,.33h-1a.6.6,0,0,0,0-.12c0-.16,0-.31-.24-.31s-.36.21-.36.44V38h.37v.74H6V38h.37V36.81H6v-.74H7.32v.48a.81.81,0,0,1,1.57.33Z" />
                    <path d="M12,37.76c-.21.81-.69,1.08-1.55,1.08A1.33,1.33,0,0,1,9,37.43,1.49,1.49,0,0,1,10.56,36c1,0,1.41.69,1.41,1.59H10.12a.23.23,0,0,0,0,0c0,.31.12.56.46.56a.43.43,0,0,0,.47-.43Zm-1.12-.66c0-.23-.08-.48-.36-.48a.38.38,0,0,0-.4.42v.06Z" />
                    <path d="M15.36,36.81H15c-.26.56-.5,1.12-.75,1.68a2.92,2.92,0,0,1-.56.93,1,1,0,0,1-.66.26.77.77,0,0,1-.84-.77H13c0,.11,0,.29.14.29s.12-.12.12-.19a.8.8,0,0,0,0-.26c-.27-.66-.59-1.29-.87-1.94h-.3v-.74h1.75v.74h-.44c.14.35.3.69.44,1,.17-.35.31-.7.45-1H14v-.74h1.38Z" />
                    <path d="M21.44,36.81H21.1c-.3.65-.59,1.31-.87,2h-.87c-.13-.39-.3-.78-.45-1.17-.19.38-.32.79-.5,1.17h-.87c-.24-.67-.52-1.31-.77-2h-.34v-.74H18v.74h-.24l.32.87.33-.87h-.24v-.74h1.7v.74h-.27l.32.88.36-.88H20v-.74h1.41Z" />
                    <path d="M25.08,38c0,.62-.4.83-1,.83a.8.8,0,0,1-.83-.5,1.06,1.06,0,0,1-1,.5c-.47,0-.79-.23-.79-.71,0-.79,1.24-.93,1.85-.93,0-.25,0-.53-.32-.53s-.34.17-.34.39h-1l0-.13c.08-.7.67-.93,1.32-.93s1.35.15,1.35.89v1.18c0,.09,0,.17.14.17s.12-.33.11-.43h.48A1.23,1.23,0,0,1,25.08,38Zm-1.77-.52h0c-.26,0-.62.12-.62.42a.23.23,0,0,0,.23.26C23.24,38.17,23.31,37.76,23.31,37.49Z" />
                    <path d="M27.3,38.05c0,.56-.38.76-.9.76a.86.86,0,0,1-1-1v-1h-.5v-.74c.68,0,.83,0,.83-.73l.67,0v.75h.82v.74h-.82V38c0,.12,0,.3.16.3s.15-.32.15-.45h.55A.83.83,0,0,1,27.3,38.05Z" />
                    <path d="M30.46,37.76c-.2.81-.68,1.08-1.54,1.08a1.33,1.33,0,0,1-1.48-1.41A1.48,1.48,0,0,1,29,36c1,0,1.41.69,1.41,1.59H28.59a.23.23,0,0,0,0,0c0,.31.12.56.46.56a.42.42,0,0,0,.46-.43Zm-1.11-.66c0-.23-.08-.48-.36-.48a.37.37,0,0,0-.4.42v.06Z" />
                    <path d="M33.51,36.88c0,.1,0,.22,0,.33h-1a.6.6,0,0,0,0-.12c0-.16,0-.31-.24-.31s-.36.21-.36.44V38h.37v.74H30.58V38h.36V36.81h-.36v-.74h1.36v.48a.81.81,0,0,1,1.57.33Z" />
                    <path d="M4.55,42.16a1.22,1.22,0,0,1-1.3,1.25,1.33,1.33,0,0,1-.81-.26,2.5,2.5,0,0,1-1.06.26A1.29,1.29,0,0,1,0,42.2a.74.74,0,0,1,.28-.59,1.58,1.58,0,0,1,1-.23,1.94,1.94,0,0,1-.12-.58,1,1,0,0,1,1.1-1c.46,0,1,.29,1,.78a.7.7,0,0,1-.28.55,1.46,1.46,0,0,1-.48.25l.12.17a3.48,3.48,0,0,0,.52.64.78.78,0,0,0,.26-.45H3v-.51H4.14v.51H3.87l-.05.12a2,2,0,0,1-.38.57.7.7,0,0,0,.25,0,.42.42,0,0,0,.38-.35Zm-2.68.39a1.37,1.37,0,0,1-.2-.29,5.12,5.12,0,0,1-.28-.53.41.41,0,0,0-.26.35C1.13,42.49,1.53,42.54,1.87,42.55Zm.58-1.7c0-.07,0-.17-.12-.17s-.13.11-.13.21a.59.59,0,0,0,.08.25A.39.39,0,0,0,2.45,40.85Z" />
                    <path d="M8.35,42.5c0,.65-.5.88-1.09.88A1.08,1.08,0,0,1,6.33,43v.33H5.67V42.4h.8a.76.76,0,0,0,.7.47c.1,0,.3,0,.3-.15,0-.4-1.74-.15-1.74-1.26a.89.89,0,0,1,1-.87.8.8,0,0,1,.76.43v-.39h.66v.86H7.42a.69.69,0,0,0-.57-.37c-.1,0-.26,0-.26.17C6.59,41.69,8.35,41.37,8.35,42.5Z" />
                    <path d="M11.53,42.32c-.21.82-.69,1.09-1.55,1.09A1.33,1.33,0,0,1,8.5,42a1.49,1.49,0,0,1,1.59-1.43c1,0,1.41.68,1.41,1.58H9.65v.05c0,.3.12.56.45.56a.43.43,0,0,0,.47-.44Zm-1.12-.66c0-.23-.08-.47-.36-.47a.38.38,0,0,0-.4.41v.06Z" />
                    <path d="M14.87,42.06a1.19,1.19,0,0,1-1.2,1.31A.81.81,0,0,1,13,43v.47h.45v.73h-1.9v-.73H12V41.37h-.37v-.73H13V41a.86.86,0,0,1,.78-.4C14.59,40.6,14.87,41.34,14.87,42.06ZM13.76,42c0-.31-.08-.69-.41-.69s-.4.44-.4.69,0,.69.4.69S13.76,42.31,13.76,42Z" />
                    <path d="M17.34,42.62c0,.55-.39.76-.91.76a.87.87,0,0,1-1-1v-1H15v-.74c.67,0,.82,0,.82-.72l.68,0v.75h.81v.74h-.81v1.17c0,.12,0,.3.15.3s.16-.32.15-.45h.56A.91.91,0,0,1,17.34,42.62Z" />
                    <path d="M19.22,43.34H17.48v-.73h.37V41.37h-.37v-.74h1.36v2h.38Zm-.38-2.86h-.92v-.74h.92Z" />
                    <path d="M22.22,42.13c0,.89-.59,1.28-1.44,1.28A1.35,1.35,0,0,1,19.33,42a1.43,1.43,0,0,1,1.54-1.43,1.21,1.21,0,0,1,1.32,1.21h-1c0-.17,0-.49-.24-.49s-.38.44-.38.68,0,.74.43.74.4-.37.4-.63h.86Z" />
                    <path d="M26.11,42.5c0,.65-.5.88-1.09.88a1.08,1.08,0,0,1-.93-.37v.33h-.66V42.4h.8a.76.76,0,0,0,.7.47c.1,0,.3,0,.3-.15,0-.4-1.74-.15-1.74-1.26a.89.89,0,0,1,1-.87.8.8,0,0,1,.76.43v-.39h.66v.86h-.72a.69.69,0,0,0-.57-.37c-.1,0-.26,0-.26.17C24.35,41.69,26.11,41.37,26.11,42.5Z" />
                    <path d="M29.84,42.57c0,.62-.41.84-1,.84a.78.78,0,0,1-.82-.5,1.08,1.08,0,0,1-1,.5c-.47,0-.78-.23-.78-.72,0-.79,1.24-.93,1.84-.93,0-.24,0-.52-.32-.52s-.33.16-.33.38h-1l0-.12c.09-.71.68-.93,1.33-.93s1.34.15,1.34.88v1.18c0,.09,0,.17.14.17s.12-.32.12-.43h.47A1.38,1.38,0,0,1,29.84,42.57Zm-1.78-.52h0c-.25,0-.61.12-.61.42a.23.23,0,0,0,.23.26C28,42.73,28.06,42.32,28.06,42.05Z" />
                    <path d="M32.07,40.17a.82.82,0,0,1-.07.31h-.59a.55.55,0,0,0,.05-.17c0-.11-.05-.18-.17-.18s-.17.1-.17.21a.7.7,0,0,0,.08.29h.52v.74h-.43v1.24h.41v.73H29.93v-.73h.36V41.37h-.36v-.74h.37a1.34,1.34,0,0,1-.05-.3c0-.42.38-.66.95-.66C31.54,39.67,32.07,39.73,32.07,40.17Z" />
                    <path d="M34.86,42.32c-.2.82-.68,1.09-1.54,1.09A1.33,1.33,0,0,1,31.84,42a1.48,1.48,0,0,1,1.59-1.43c1,0,1.41.68,1.41,1.58H33a.2.2,0,0,0,0,.05c0,.3.12.56.46.56a.42.42,0,0,0,.46-.44Zm-1.11-.66c0-.23-.08-.47-.36-.47a.37.37,0,0,0-.4.41v.06Z" />
                    <path d="M17.42,27.82a.33.33,0,0,0-.36.36v.05h.68C17.74,28,17.67,27.82,17.42,27.82Zm0,0a.33.33,0,0,0-.36.36v.05h.68C17.74,28,17.67,27.82,17.42,27.82ZM25.08,4.55h0L23.52,8l0,0a11.52,11.52,0,0,1,1.22,18.49l-.36.31-.35-.32A10,10,0,0,0,17.73,24l-.5,0-.51,0a10,10,0,0,0-6.6,2.71l-.34.34-.37-.3A11.55,11.55,0,0,1,5,17.68,11.76,11.76,0,0,1,16.33,6l.56,0V7.92a.41.41,0,0,0,.24.36l.16,0a.43.43,0,0,0,.28-.11l3.94-3.77a.38.38,0,0,0,.12-.28.36.36,0,0,0-.12-.27L17.57.11A.43.43,0,0,0,17.29,0l-.16,0a.4.4,0,0,0-.24.36v1.9l-.51,0A15.47,15.47,0,0,0,1.22,17.68a15.57,15.57,0,0,0,15.7,15.39A15.56,15.56,0,0,0,32.61,17.68,15.22,15.22,0,0,0,25.08,4.55ZM15.36,28.93v.8H13.44v-.8h.44v-.28c-.32-.44-.64-.88-.93-1.33h-.42v-.8h1.92v.8H14c.17.27.35.53.53.79.18-.26.37-.52.54-.79H14.6v-.8h1.67v.8h-.43c-.3.44-.62.88-.91,1.33v.28Zm2,.86A1.19,1.19,0,0,1,16,28.53a1.32,1.32,0,0,1,1.41-1.27c.9,0,1.26.61,1.26,1.41H17.06a.23.23,0,0,0,0,0c0,.27.12.5.41.5a.39.39,0,0,0,.42-.39h.85C18.55,29.55,18.12,29.79,17.35,29.79Zm2.95,0a1,1,0,0,1-.83-.33v.3h-.59v-.84h.71a.69.69,0,0,0,.62.42c.09,0,.27,0,.27-.13,0-.37-1.55-.14-1.55-1.13a.79.79,0,0,1,.88-.77.71.71,0,0,1,.68.39v-.35h.59v.76h-.64a.65.65,0,0,0-.51-.33c-.09,0-.24,0-.24.15,0,.35,1.57.07,1.57,1.08C21.26,29.56,20.82,29.76,20.3,29.76Zm-2.88-1.94a.33.33,0,0,0-.36.36v.05h.68C17.74,28,17.67,27.82,17.42,27.82Z" />
                    <path d="M12,16.09a4.71,4.71,0,0,0,.37,4.64A5.43,5.43,0,0,0,17,23.17a5.3,5.3,0,0,0,5.23-5.11,5.52,5.52,0,0,0-1-2.91,29.29,29.29,0,0,0-3.43-4.47c-.3-.36-.61-.71-.92-1.07L16,10.66A23.75,23.75,0,0,0,12,16.09Zm4.85,6.32,0-.74a3.85,3.85,0,0,0,3.64-3.55l.75.07A4.6,4.6,0,0,1,16.89,22.41Z" />
                </g>
            </g>
        </svg>
    )
}
