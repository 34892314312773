import React from "react"

export default () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.41 38.7">
            <defs>
                <style></style>
            </defs>
            <title>Soft and Hygenically Clean logo</title>
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                    <path d="M42,15.71c0,.83-.48,1.17-1.28,1.17-.43,0-1-.09-1.06-.6l0-.16a1.67,1.67,0,0,1-1.5.76c-.64,0-1.06-.28-1.06-.95,0-.91,1.12-1.31,1.86-1.42.23,0,.48,0,.7-.09v-.19c0-.39-.22-.56-.63-.56s-.63.31-.63.77H37.26c0-1.13.66-1.62,1.75-1.62s1.66.43,1.66,1.47v1.42c0,.23.07.38.33.38s.27-.45.25-.67h.68A2.85,2.85,0,0,1,42,15.71Zm-2.37-.81h-.05c-.4,0-1.27.22-1.27.73,0,.29.19.37.47.37a.89.89,0,0,0,.5-.13C39.62,15.61,39.59,15.32,39.59,14.9Z" />
                    <path d="M46.77,16.82H44.69v-.91h.54V14.5c0-.47-.2-.71-.66-.71a.83.83,0,0,0-.9.77v1.35h.51v.91H42.1v-.91h.57V13.84H42.1v-.91h1.56v.62A1.23,1.23,0,0,1,45,12.82a1.16,1.16,0,0,1,1.25,1.27v1.82h.54Z" />
                    <path d="M51.46,16.82H49.93v-.53a1.31,1.31,0,0,1-1.14.57c-1.19,0-1.67-.9-1.67-2a1.68,1.68,0,0,1,1.76-1.91,1.27,1.27,0,0,1,1,.48v-.86h-.72v-.91h1.73v4.28h.52Zm-1.53-1.65v-.59a.81.81,0,0,0-.8-.83.92.92,0,0,0-.94,1c0,.62.22,1.17.91,1.17A.8.8,0,0,0,49.93,15.17Z" />
                    <path d="M9.62,26.38H5.38V24.5H6.45v-3c0-.92-.42-1.33-1.33-1.33a1.76,1.76,0,0,0-1.88,1.59V24.5h1v1.88H0V24.5H1.16V17.55H.09V15.67H3.21v3.82a3.05,3.05,0,0,1,2.84-1.36,2.33,2.33,0,0,1,2.47,2.49V24.5h1.1Z" />
                    <path d="M19.1,20.24H17.92c-.73,1.66-1.48,3.62-2.18,5.3A9.06,9.06,0,0,1,14.1,28.3a2.69,2.69,0,0,1-1.92.75A2.07,2.07,0,0,1,9.84,27h2.28c0,.33.12.62.51.62s.57-.55.57-.87c0-.64-2.43-6.5-2.43-6.5H9.69v-1.9h4.69v1.9H13.11l1.5,4,1.57-4h-.92v-1.9H19.1Z" />
                    <path d="M27.68,19.26c0,.15,0,.3,0,.46h-1.5c0-.22.06-.87-.26-.87s-.2.15-.18.29.14.49.17.71a3.9,3.9,0,0,1,.12.88c0,1.83-1.8,2.27-3.3,2.27a5.38,5.38,0,0,1-1.47-.18c-.38.07-.78.24-.78.59s.6.48,1,.48c1,0,2.09-.22,3.15-.22,1.49,0,2.7.55,2.7,2.23,0,2.39-2.49,2.89-4.4,2.89-1.34,0-4.11.05-4.11-1.88a1.63,1.63,0,0,1,1.09-1.34,1.53,1.53,0,0,1-1.33-1.44c0-1,1-1.48,1.83-1.71a1.93,1.93,0,0,1-.93-1.72c0-2,1.65-2.57,3.38-2.57a3.61,3.61,0,0,1,2.52.78,1.17,1.17,0,0,1,1.14-1.16C27.45,17.75,27.68,18.39,27.68,19.26Zm-2.93,7c0-.55-.89-.64-1.4-.64l-1.52,0c-.42.12-.79.27-.79.75,0,.65.88,1,1.86,1C24,27.32,24.75,27,24.75,26.22Zm-.84-5.48v-.27c0-.68-.52-1-1.11-1s-1.18.29-1.18,1v.27c0,.67.58.9,1.14.9S23.91,21.46,23.91,20.74Z" />
                    <path d="M32.75,26.38H28.44V24.5h1.15V20.24H28.41v-1.9h3.25V24.5h1.09Zm-1.09-8.83H29.22V15.67h2.44Z" />
                    <path d="M41.52,23.35c-.16,2.26-2.05,3.23-4.11,3.23a4,4,0,0,1-4.19-4.2,4.08,4.08,0,0,1,4.25-4.25c2.61,0,4,1.74,4,4.18,0,.19,0,.36,0,.52H35.36a1.84,1.84,0,0,0,2.08,1.91c1,0,1.79-.32,1.86-1.39Zm-2.17-1.78a1.63,1.63,0,0,0-1.86-1.65,2,2,0,0,0-2.07,1.65Z" />
                    <path d="M51.66,26.38H47.39V24.5h1.1V21.6c0-1-.42-1.47-1.35-1.47a1.72,1.72,0,0,0-1.86,1.59V24.5h1v1.88H42V24.5H43.2V20.24H42v-1.9h3.24v1.29A2.52,2.52,0,0,1,48,18.13a2.39,2.39,0,0,1,2.58,2.63V24.5h1.1Z" />
                    <path d="M56.54,26.38H52.23V24.5h1.15V20.24H52.2v-1.9h3.25V24.5h1.09Zm-1.09-8.83H53V15.67h2.44Z" />
                    <path d="M65,23.08c-.25,2.55-1.68,3.5-4.1,3.5-2.92,0-4-1.85-4-4.25a4,4,0,0,1,4.18-4.2c2,0,3.67,1,3.79,3.13l0,.52H62.59c-.09-.93-.37-1.67-1.51-1.67s-1.84,1.12-1.84,2.28.39,2.32,1.7,2.32,1.68-.49,1.79-1.63Z" />
                    <path d="M75.28,24.09c0,1.71-1,2.41-2.62,2.41-.89,0-2-.18-2.19-1.24l0-.32a3.46,3.46,0,0,1-3.1,1.56c-1.32,0-2.19-.58-2.19-2,0-1.87,2.31-2.7,3.84-2.93.47-.07,1-.09,1.45-.18V21c0-.79-.46-1.16-1.3-1.16-1.12,0-1.3.66-1.3,1.59H65.58c0-2.32,1.38-3.33,3.62-3.33,2,0,3.44.88,3.44,3v2.94c0,.47.14.78.67.78.72,0,.55-.92.52-1.38h1.41C75.25,23.69,75.28,23.89,75.28,24.09Zm-4.87-1.67H70.3c-.82,0-2.62.44-2.62,1.5,0,.61.39.76,1,.76a1.72,1.72,0,0,0,1-.26C70.46,23.89,70.41,23.29,70.41,22.42Z" />
                    <path d="M79.75,26.38H75.38V24.5h1.14V17.55H75.33V15.67h3.33V24.5h1.09Z" />
                    <path d="M84.79,26.38H80.42V24.5h1.15V17.55H80.38V15.67h3.33V24.5h1.08Z" />
                    <path d="M94.41,20.24H93.24c-.74,1.66-1.48,3.62-2.19,5.3a8.83,8.83,0,0,1-1.63,2.76,2.71,2.71,0,0,1-1.93.75A2.07,2.07,0,0,1,85.16,27h2.27c0,.33.13.62.52.62s.57-.55.57-.87c0-.64-2.43-6.5-2.43-6.5H85v-1.9h4.69v1.9H88.43l1.49,4,1.58-4h-.92v-1.9h3.83Z" />
                    <path d="M45.29,35.21c-.24,2.55-1.68,3.49-4.09,3.49-2.92,0-4-1.85-4-4.24a4,4,0,0,1,4.19-4.2c2,0,3.66,1,3.78,3.13l0,.52H42.92c-.09-.93-.36-1.67-1.51-1.67s-1.83,1.12-1.83,2.28.38,2.32,1.69,2.32,1.68-.49,1.79-1.63Z" />
                    <path d="M50,38.5H45.66V36.63h1.15v-7h-1.2V27.8h3.33v8.83H50Z" />
                    <path d="M58.92,35.48c-.15,2.26-2.05,3.22-4.11,3.22a4,4,0,0,1-4.18-4.2,4.06,4.06,0,0,1,4.24-4.24c2.61,0,4,1.74,4,4.18,0,.18,0,.35,0,.52H52.77a1.83,1.83,0,0,0,2.07,1.91c1,0,1.79-.32,1.87-1.39Zm-2.17-1.79A1.63,1.63,0,0,0,54.89,32a2,2,0,0,0-2.06,1.65Z" />
                    <path d="M69.42,36.21c0,1.71-1,2.42-2.63,2.42-.89,0-2-.19-2.19-1.24l0-.32a3.48,3.48,0,0,1-3.1,1.56c-1.31,0-2.19-.58-2.19-2,0-1.88,2.31-2.7,3.84-2.93.47-.08,1-.09,1.45-.18v-.4c0-.8-.46-1.16-1.3-1.16-1.11,0-1.3.65-1.3,1.59H59.72c0-2.33,1.37-3.33,3.62-3.33,2,0,3.43.88,3.43,3v2.93c0,.48.14.78.68.78.71,0,.55-.91.52-1.37h1.4C69.39,35.82,69.42,36,69.42,36.21Zm-4.88-1.66h-.1c-.83,0-2.63.44-2.63,1.5,0,.61.4.76,1,.76a1.78,1.78,0,0,0,1-.26C64.59,36,64.54,35.42,64.54,34.55Z" />
                    <path d="M79.12,38.5H74.84V36.63h1.1V33.72c0-1-.41-1.46-1.34-1.46a1.73,1.73,0,0,0-1.87,1.59v2.78h1V38.5H69.48V36.63h1.18V32.36H69.48V30.47h3.24v1.28a2.51,2.51,0,0,1,2.72-1.49A2.39,2.39,0,0,1,78,32.88v3.75h1.1Z" />
                    <path d="M23.06,8.42c0,1.92-1.49,2.62-3.22,2.62A2.92,2.92,0,0,1,17.26,10c0,.32,0,.64,0,1H15.32V8h1.92a2.67,2.67,0,0,0,2.4,1.53c.61,0,1.28-.17,1.28-.9,0-1.79-5.46.47-5.46-3.51,0-1.58,1.37-2.34,2.79-2.34a2.26,2.26,0,0,1,2.2,1.19v-1h1.93V5.44H20.44a1.86,1.86,0,0,0-1.71-1.06c-.46,0-1.2.1-1.2.71C17.53,6.75,23.06,4.69,23.06,8.42Z" />
                    <path d="M32.08,7a4,4,0,0,1-4.22,4.16A4,4,0,0,1,23.63,7,4.08,4.08,0,0,1,27.8,2.67,4,4,0,0,1,32.08,7Zm-2.2-.06a2.06,2.06,0,0,0-2-2.32A2.11,2.11,0,0,0,25.78,6.9a2.12,2.12,0,0,0,2.08,2.31C29.24,9.21,29.88,8.19,29.88,6.9Z" />
                    <path d="M39.32,1.56a3.4,3.4,0,0,1-.11.7h-1.8a2,2,0,0,0,.08-.41A.48.48,0,0,0,37,1.31c-.84,0-.81.81-.81,1.44v.14h1.53V4.78H36.16V9h1.19v1.88H32.83V9h1.22V4.78H32.87V2.89h1.18C34.05.9,35,0,37,0,38,0,39.32.32,39.32,1.56Z" />
                    <path d="M44.85,8.74c0,1.66-.92,2.33-2.5,2.33-2,0-2.72-1.07-2.72-3V4.78H38V3l1.59-.07V1L41.78,1V2.89h2.69V4.78H41.78V8.25c0,.53.06,1.14.73,1.14s.68-.82.63-1.3h1.62A2.91,2.91,0,0,1,44.85,8.74Z" />
                </g>
            </g>
        </svg>
    )
}
