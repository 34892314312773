import React from "react"

export default () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 81.31 32.19">
            <defs>
                <style></style>
            </defs>
            <title>Powers Through Grease logo</title>
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                    <path d="M47.36,16.13c0,1-.55,1.4-1.5,1.4-1.2,0-1.62-.64-1.62-1.79v-2h-1V12.68l1,0V11.52l1.28,0v1.16h1.61v1.13H45.52v2.07c0,.32,0,.69.44.69s.4-.5.37-.78h1A1.67,1.67,0,0,1,47.36,16.13Z" />
                    <path d="M53.5,17.44H51V16.32h.64V14.5c0-.55-.25-.79-.8-.79a1,1,0,0,0-1.12.94v1.67h.61v1.12H47.75V16.32h.69V12.16H47.8V11h1.86v2.28a1.84,1.84,0,0,1,1.7-.81A1.39,1.39,0,0,1,52.84,14v2.32h.66Z" />
                    <path d="M58.86,13.82a2.59,2.59,0,0,1-.16.82l-1.4-.07a1.45,1.45,0,0,0,.09-.47c0-.33-.3-.49-.61-.49a.89.89,0,0,0-.91,1v1.73h.7v1.12H53.92V16.32h.71V13.77h-.71V12.64h1.94v.69a1.69,1.69,0,0,1,1.67-.82A1.26,1.26,0,0,1,58.86,13.82Z" />
                    <path d="M64,15.07a2.52,2.52,0,0,1-5,0,2.43,2.43,0,0,1,2.49-2.56A2.38,2.38,0,0,1,64,15.07Zm-1.31,0a1.23,1.23,0,0,0-1.21-1.39A1.26,1.26,0,0,0,60.26,15a1.27,1.27,0,0,0,1.24,1.38C62.33,16.42,62.71,15.8,62.71,15Z" />
                    <path d="M70,17.44H68.1v-1l-.08.19a1.62,1.62,0,0,1-1.55.83,1.51,1.51,0,0,1-1.54-1.69V13.77H64.2V12.64h2v2.82c0,.73.3,1,.85,1a1.08,1.08,0,0,0,1.07-1.1V13.77h-.8V12.64h2v3.68H70Z" />
                    <path d="M75.64,13.19a2.44,2.44,0,0,1,0,.27h-.89c0-.13,0-.52-.15-.52s-.12.09-.11.17a1.93,1.93,0,0,0,.1.43,2.72,2.72,0,0,1,.07.52c0,1.1-1.08,1.36-2,1.36a3.37,3.37,0,0,1-.88-.11c-.23,0-.46.15-.46.36s.35.28.56.28c.63,0,1.25-.13,1.88-.13.9,0,1.62.33,1.62,1.33,0,1.43-1.49,1.73-2.63,1.73-.8,0-2.45,0-2.45-1.12A1,1,0,0,1,71,17a.9.9,0,0,1-.79-.85c0-.6.57-.89,1.09-1a1.16,1.16,0,0,1-.55-1c0-1.21,1-1.53,2-1.53a2.16,2.16,0,0,1,1.51.47.7.7,0,0,1,.68-.7C75.51,12.28,75.64,12.67,75.64,13.19Zm-1.75,4.16c0-.33-.53-.39-.84-.39h-.9c-.26.07-.48.17-.48.45s.53.58,1.12.58S73.89,17.79,73.89,17.35Zm-.5-3.28v-.16c0-.41-.31-.58-.67-.58s-.7.17-.7.61v.17c0,.4.35.54.69.54S73.39,14.5,73.39,14.07Z" />
                    <path d="M81.31,17.44H78.77V16.32h.64V14.5c0-.55-.24-.79-.79-.79a1,1,0,0,0-1.12.94v1.67h.61v1.12H75.56V16.32h.69V12.16h-.63V11h1.86v2.28a1.82,1.82,0,0,1,1.69-.81A1.39,1.39,0,0,1,80.65,14v2.32h.66Z" />
                    <path d="M16.27,20.44a5.43,5.43,0,0,1,0,.57H14.41c0-.27.07-1.08-.33-1.08-.2,0-.24.19-.22.36a4.28,4.28,0,0,0,.21.89,4.84,4.84,0,0,1,.15,1.07c0,2.26-2.22,2.8-4.07,2.8a6.69,6.69,0,0,1-1.81-.22c-.47.09-1,.3-1,.73s.74.59,1.17.59c1.3,0,2.58-.27,3.88-.27,1.84,0,3.33.68,3.33,2.75,0,2.94-3.07,3.56-5.42,3.56-1.66,0-5.07.06-5.07-2.31a2,2,0,0,1,1.34-1.66A1.87,1.87,0,0,1,5,26.45c0-1.23,1.19-1.83,2.26-2.11a2.38,2.38,0,0,1-1.15-2.13c0-2.5,2-3.16,4.16-3.16a4.43,4.43,0,0,1,3.11,1,1.45,1.45,0,0,1,1.41-1.43C16,18.58,16.27,19.37,16.27,20.44ZM12.65,29c0-.68-1.09-.79-1.73-.79l-1.86,0c-.53.15-1,.33-1,.92,0,.81,1.09,1.2,2.3,1.2C11.77,30.38,12.65,30,12.65,29Zm-1-6.76v-.34c0-.83-.64-1.19-1.38-1.19S8.79,21.1,8.79,22v.34c0,.83.72,1.11,1.42,1.11S11.62,23.15,11.62,22.27Z" />
                    <path d="M26.06,21.76a5.9,5.9,0,0,1-.32,1.68l-2.9-.14a2.67,2.67,0,0,0,.19-1c0-.67-.62-1-1.26-1a1.85,1.85,0,0,0-1.88,2V26.9h1.45v2.32H15.88V26.9h1.45V21.65H15.88V19.31h4v1.43a3.51,3.51,0,0,1,3.44-1.69A2.6,2.6,0,0,1,26.06,21.76Z" />
                    <path d="M36.42,25.49c-.19,2.78-2.52,4-5.06,4a4.88,4.88,0,0,1-5.16-5.18,5,5,0,0,1,5.23-5.23c3.22,0,4.9,2.14,4.9,5.16,0,.22,0,.43,0,.64H28.83a2.26,2.26,0,0,0,2.56,2.35c1.19,0,2.21-.39,2.3-1.71Zm-2.67-2.21a2,2,0,0,0-2.3-2,2.42,2.42,0,0,0-2.54,2Z" />
                    <path d="M49.36,26.39c0,2.11-1.21,3-3.24,3-1.09,0-2.5-.23-2.69-1.53l-.06-.39a4.27,4.27,0,0,1-3.82,1.92c-1.62,0-2.69-.72-2.69-2.41,0-2.32,2.84-3.34,4.72-3.62.59-.09,1.23-.11,1.79-.22v-.49c0-1-.56-1.44-1.6-1.44-1.37,0-1.6.81-1.6,2H37.4c0-2.86,1.7-4.1,4.47-4.1,2.48,0,4.23,1.09,4.23,3.73v3.61c0,.59.17,1,.83,1,.89,0,.68-1.13.64-1.69H49.3C49.32,25.9,49.36,26.15,49.36,26.39Zm-6-2.05h-.13c-1,0-3.24.54-3.24,1.84,0,.76.49.95,1.19.95a2.21,2.21,0,0,0,1.28-.32C43.41,26.15,43.35,25.41,43.35,24.34Z" />
                    <path d="M58.91,26.13c0,2.37-1.85,3.24-4,3.24A3.63,3.63,0,0,1,51.75,28a11.76,11.76,0,0,0,0,1.19H49.36V25.58h2.37c.79,1.15,1.45,1.88,3,1.88.75,0,1.58-.2,1.58-1.11,0-2.2-6.74.59-6.74-4.33,0-1.94,1.7-2.88,3.45-2.88a2.8,2.8,0,0,1,2.71,1.47v-1.3h2.37v3.15H55.67a2.29,2.29,0,0,0-2.11-1.3c-.56,0-1.47.11-1.47.86C52.09,24.08,58.91,21.53,58.91,26.13Z" />
                    <path d="M69.53,25.49c-.19,2.78-2.52,4-5.07,4a4.88,4.88,0,0,1-5.16-5.18,5,5,0,0,1,5.24-5.23c3.22,0,4.89,2.14,4.89,5.16,0,.22,0,.43,0,.64H61.94A2.26,2.26,0,0,0,64.5,27.2c1.19,0,2.21-.39,2.3-1.71Zm-2.67-2.21a2,2,0,0,0-2.3-2,2.41,2.41,0,0,0-2.54,2Z" />
                    <path d="M11.5,5.25c0,2.6-1.48,5.07-4.33,5.07a3.09,3.09,0,0,1-2.9-1.26v2.09H6.06v2.31H0V11.15H1.71V2.6H.21V.26H4.27V1.77a3.23,3.23,0,0,1,3-1.6C10,.17,11.5,2.28,11.5,5.25Zm-2.71,0A2.42,2.42,0,0,0,6.53,2.52c-1.77,0-2.35,1.21-2.35,2.79S4.76,8.1,6.42,8.1,8.79,6.76,8.79,5.2Z" />
                    <path d="M22.32,5.29a5,5,0,0,1-5.2,5.12A5,5,0,0,1,11.9,5.29,5,5,0,0,1,17,0,4.92,4.92,0,0,1,22.32,5.29ZM19.6,5.22c0-1.55-.82-2.87-2.5-2.87a2.59,2.59,0,0,0-2.54,2.87,2.61,2.61,0,0,0,2.56,2.84C18.81,8.06,19.6,6.8,19.6,5.22Z" />
                    <path d="M40.26,2.6H39.09l-3.23,7.57H32.77l-1.6-4.46L29.3,10.17h-3L23.45,2.6H22.24V.26h5.07V2.6h-1l1.77,4.88,2-4.88H29V.26H34.1V2.6H33l1.68,4.84L36.59,2.6H35.52V.26h4.74Z" />
                    <path d="M50.58,6.44c-.18,2.79-2.52,4-5.06,4a4.88,4.88,0,0,1-5.16-5.18A5,5,0,0,1,45.59,0c3.22,0,4.9,2.15,4.9,5.16,0,.23,0,.43,0,.64H43a2.26,2.26,0,0,0,2.56,2.35c1.18,0,2.2-.39,2.29-1.71Zm-2.67-2.2a2,2,0,0,0-2.3-2,2.42,2.42,0,0,0-2.54,2Z" />
                    <path d="M61.39,2.71a5.57,5.57,0,0,1-.32,1.68l-2.9-.13a2.71,2.71,0,0,0,.19-1c0-.68-.62-1-1.26-1a1.86,1.86,0,0,0-1.88,2V7.85h1.45v2.32H51.21V7.85h1.45V2.6H51.21V.26h4V1.69A3.52,3.52,0,0,1,58.65,0,2.6,2.6,0,0,1,61.39,2.71Z" />
                    <path d="M71.06,7.08c0,2.37-1.85,3.24-4,3.24A3.63,3.63,0,0,1,63.9,9a11.81,11.81,0,0,0,0,1.19H61.51V6.53h2.37c.79,1.15,1.45,1.89,3,1.89.75,0,1.58-.21,1.58-1.11,0-2.21-6.74.58-6.74-4.33C61.68,1,63.37.09,65.13.09a2.81,2.81,0,0,1,2.71,1.47V.26h2.37V3.41H67.82a2.29,2.29,0,0,0-2.11-1.3c-.57,0-1.47.11-1.47.87C64.24,5,71.06,2.49,71.06,7.08Z" />
                </g>
            </g>
        </svg>
    )
}
