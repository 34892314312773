import React from "react"

export default () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 332.8 95.55">
            <defs>
                <style></style>
            </defs>
            <title>About text</title>
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                    <path d="M28.32,17.06c-2.93.83-6.72,2.21-9.28,3.07a6.07,6.07,0,0,1-3.41,1,1.29,1.29,0,0,1-.32-.84c0-1,1.35-1.86,1.55-2.9,1-1.72,1.18-2.87,2.56-4.42.34-.74,1.89-2.18,2.09-3.13,1.09-1.64,2.24-3.21,3.19-4.94a4.33,4.33,0,0,0-1.15-.17,20.2,20.2,0,0,0-3.47.43,14.74,14.74,0,0,1-2.3.29,7.73,7.73,0,0,1-2.84-.29,1.77,1.77,0,0,1-1-1.52V3.36c.55-.11,1-.6,1.58-.57A1,1,0,0,1,16,2.9c0,.29.37.49.54.72,2.93,0,5.55-.78,8.39-1a1.75,1.75,0,0,0,.57.09c.49,0,1-.12,1.52-.12a1.36,1.36,0,0,1,1.5.92C28.35,4.65,27,4.94,26.48,6c-2.64,4-5.6,7.84-7.95,11.89V18c0,.08,0,.17,0,.23a.33.33,0,0,0,.17.31c3.13-1.46,6.55-2.12,9.62-3.73a.34.34,0,0,1,.17,0c.55,0,1.21-.32,1.75-.32a.92.92,0,0,1,.95.69C31,16.46,29.35,16.74,28.32,17.06Z" />
                    <path d="M39.75,14l0,0a8.84,8.84,0,0,1-2.67,3.76,3.94,3.94,0,0,1-2,.54,3.08,3.08,0,0,1-1.58-.43c-.78-.77-2-.86-2.47-2a13.53,13.53,0,0,1-.17-2,4,4,0,0,1,.34-1.81c1.24-1,2.47-2.7,4.16-2.81a2.68,2.68,0,0,1,.66-.09,3.5,3.5,0,0,1,2.56,1.29,4,4,0,0,1,1.24,2.73A2.49,2.49,0,0,1,39.75,14ZM37.6,13.1a3,3,0,0,0-.06-.52,1.83,1.83,0,0,0-1.38-1.12,1.35,1.35,0,0,0-.34.06,3.71,3.71,0,0,0-2.44,2.06.8.8,0,0,0-.18.52,1.41,1.41,0,0,0,.52,1.09,1.29,1.29,0,0,0,1.12.69,3.94,3.94,0,0,0,1.66-.66,3.11,3.11,0,0,0,1.1-2.1Z" />
                    <path d="M48.48,14c-.54,1.9-2.07,4.57-4.71,4.57s-4.22-2.24-4.22-4.77a6.92,6.92,0,0,1,.75-2.38c1-1.41,2-3,4.1-3,1.9,0,4.2,2.62,4.2,4.66A3.37,3.37,0,0,1,48.48,14ZM46.27,13c0-.77-1-2.21-1.89-2.21-1,0-2.62,1.92-2.62,3.36a2.47,2.47,0,0,0,1.9,2.13c1.29,0,2.61-1.67,2.61-3.25Z" />
                    <path d="M54.28,17.12a3.62,3.62,0,0,1-2,.54,8.72,8.72,0,0,1-2.73-.54c-.26-.38-.89-.64-.89-1.1a.36.36,0,0,1,0-.17,1.2,1.2,0,0,1,1-.49c.72,0,1.52.46,2.21.49.43,0,.81-.2,1.06-.89a20.68,20.68,0,0,1-2.55-2,4.33,4.33,0,0,1-1.32-2.32,2.81,2.81,0,0,1,1.58-1.79A6.66,6.66,0,0,1,53,8.3a2,2,0,0,1,1.5.69,5.63,5.63,0,0,1,.11.75.83.83,0,0,1-.14.46.85.85,0,0,1-.89.54c-.4,0-.89-.2-1.24-.2s-.6.4-.6,1.12a22.79,22.79,0,0,1,2.84,2.12,3.13,3.13,0,0,1,.75,1.33l0,0h0A2.55,2.55,0,0,1,54.28,17.12Z" />
                    <path d="M63.33,17.86v0a.82.82,0,0,1-.89.55,1.3,1.3,0,0,1-.69-.2,3.49,3.49,0,0,1-.54-2.13c0-.52,0-1,0-1.55,0-1-.15-2.3-1-2.93a2.76,2.76,0,0,0-1.43,1.78,15.78,15.78,0,0,0-.32,3.88v.26a3.49,3.49,0,0,1-.83,1,1.73,1.73,0,0,1-1.47-1c-.14-2.36-.11-5.46-.2-8a55.5,55.5,0,0,0-.66-8.27A1,1,0,0,1,56.1.83,1.44,1.44,0,0,1,57.42,2.1a50.17,50.17,0,0,1,.83,7.84,9.36,9.36,0,0,1,2.1-.52,2,2,0,0,1,1.29.43,4.64,4.64,0,0,1,1.2,1.58,8.52,8.52,0,0,1,.84,4.05A9.18,9.18,0,0,1,63.33,17.86Z" />
                    <path d="M72.75,7.06c-.57-.2-1.37-.08-1.55-.89a1.32,1.32,0,0,1,0-.31,1.11,1.11,0,0,1,.74-1.09c.63.26,1.55.37,1.61,1.29C73.44,6.46,73,6.78,72.75,7.06Zm.38,11.4a.77.77,0,0,1-.41-.11L72,17.63l-.29-7.23a1.16,1.16,0,0,1,1.12-.72,1.11,1.11,0,0,1,.92.46,10.79,10.79,0,0,1,.46,3.36c0,.57,0,1.17,0,1.78a14.74,14.74,0,0,0,.14,2.21h0C74.1,17.89,73.64,18.46,73.13,18.46Z" />
                    <path d="M80.51,17.12a3.63,3.63,0,0,1-2,.54,8.72,8.72,0,0,1-2.73-.54c-.26-.38-.89-.64-.89-1.1a.36.36,0,0,1,0-.17,1.21,1.21,0,0,1,1-.49c.72,0,1.53.46,2.21.49.44,0,.81-.2,1.07-.89a20.74,20.74,0,0,1-2.56-2,4.33,4.33,0,0,1-1.32-2.32,2.84,2.84,0,0,1,1.58-1.79,6.71,6.71,0,0,1,2.3-.54A2,2,0,0,1,80.68,9a5.63,5.63,0,0,1,.11.75.76.76,0,0,1-.14.46.85.85,0,0,1-.89.54c-.4,0-.89-.2-1.23-.2s-.61.4-.61,1.12a23.66,23.66,0,0,1,2.85,2.12,3.22,3.22,0,0,1,.74,1.33l0,0h0A2.54,2.54,0,0,1,80.51,17.12Z" />
                    <path d="M97.31,14l0,0a8.84,8.84,0,0,1-2.67,3.76,3.94,3.94,0,0,1-2,.54,3.08,3.08,0,0,1-1.58-.43c-.78-.77-2-.86-2.47-2a13.48,13.48,0,0,1-.18-2,4,4,0,0,1,.35-1.81c1.23-1,2.47-2.7,4.16-2.81a2.68,2.68,0,0,1,.66-.09,3.5,3.5,0,0,1,2.56,1.29,4,4,0,0,1,1.23,2.73A2.49,2.49,0,0,1,97.31,14Zm-2.15-.91a3,3,0,0,0-.06-.52,1.83,1.83,0,0,0-1.38-1.12,1.35,1.35,0,0,0-.34.06,3.75,3.75,0,0,0-2.45,2.06.85.85,0,0,0-.17.52,1.41,1.41,0,0,0,.52,1.09,1.29,1.29,0,0,0,1.12.69,3.94,3.94,0,0,0,1.66-.66,3.11,3.11,0,0,0,1.1-2.1Z" />
                    <path d="M104.49,17.55a.34.34,0,0,1-.17,0c-.69,0-1.12-.84-1.52-1.29-.78.57-1.24,1.66-2.16,2.15a1,1,0,0,1-.43,0c-1.06,0-1.75-1.06-2.38-1.75a13,13,0,0,1-.69-4.51c0-.86.06-1.73.14-2.56A.91.91,0,0,1,98,9.28a1.81,1.81,0,0,1,.72.14l.57.75.09,4.62c.26.32.08,1,.63,1,0,0,.09,0,.11,0a12.08,12.08,0,0,0,1.9-4.6,1.22,1.22,0,0,1,1-.57,1,1,0,0,1,.49.14c1.43,1.44.08,4.28,1.75,5.8A1.17,1.17,0,0,1,104.49,17.55Z" />
                    <path d="M112.76,12.06a2.69,2.69,0,0,0-1.55-.54,1.79,1.79,0,0,0-.66.11c-2.32,1-1.46,3.85-1.84,5.95-.34.14-.69.57-1.06.57a.61.61,0,0,1-.43-.2c-1.69-2.56-2-5.69-2.44-8.82v-.2a.75.75,0,0,1,.43-.77,1.38,1.38,0,0,1,.46-.09c.86,0,1.06,1,1.41,1.67a.8.8,0,0,1,0,.28c0,.2,0,.43,0,.63a.82.82,0,0,0,.28.72,9.69,9.69,0,0,1,3.34-1.92,2.91,2.91,0,0,0,.51,0,2.32,2.32,0,0,1,2.24,1l0,.06v.26A1.26,1.26,0,0,1,112.76,12.06Z" />
                    <path d="M126,18a16,16,0,0,1-2.67.43,2.54,2.54,0,0,1-2.24-1.09,4.16,4.16,0,0,1-.63-2.3c0-1.92,1.09-4,1.81-5.6.49-1.15,1.84-1.81,1.84-3.24a1.43,1.43,0,0,1,.6-.12c1.09,0,2,1.26,2.36,2.13a4.55,4.55,0,0,1-1,2.95c-.2.29-.37.41-.54.43-.46,0-.84-.68-1.24-.71-.17,0-.34.14-.54.54a12,12,0,0,0-.81,4.54,1.3,1.3,0,0,0,1.12.57,2.58,2.58,0,0,0,.46-.05c1.09-.26,2.07-1.64,3.07-1.67a1.57,1.57,0,0,1,1.24.83C128.7,17.14,126.89,17.17,126,18Z" />
                    <path d="M137.89,14l0,0a8.76,8.76,0,0,1-2.67,3.76,3.89,3.89,0,0,1-2,.54,3.08,3.08,0,0,1-1.58-.43c-.77-.77-2-.86-2.47-2a13.53,13.53,0,0,1-.17-2,4,4,0,0,1,.34-1.81c1.24-1,2.47-2.7,4.17-2.81a2.68,2.68,0,0,1,.66-.09,3.5,3.5,0,0,1,2.55,1.29A4,4,0,0,1,138,13.18,2.49,2.49,0,0,1,137.89,14Zm-2.15-.91a3,3,0,0,0-.06-.52,1.81,1.81,0,0,0-1.38-1.12,1.35,1.35,0,0,0-.34.06,3.71,3.71,0,0,0-2.44,2.06.79.79,0,0,0-.17.52,1.4,1.4,0,0,0,.51,1.09,1.3,1.3,0,0,0,1.12.69,4,4,0,0,0,1.67-.66,3.14,3.14,0,0,0,1.09-2.1Z" />
                    <path d="M145.28,18.24c-1.33-.18-1.5-1.12-1.5-2.22,0-.4,0-.83,0-1.26,0-1-.14-1.92-1.15-2.3-1.26,1.44-1.72,3.48-2.29,5.34a1.24,1.24,0,0,1-1,.58,1,1,0,0,1-.49-.14,1.71,1.71,0,0,1-.78-1.5c0-.69.26-1.46.29-2.18a2.5,2.5,0,0,0-.09-.66v-.09c0-1.46-.6-2.84-.6-4.13a.11.11,0,0,0,0-.09,1.33,1.33,0,0,1,.86-.31,1.27,1.27,0,0,1,1.15.68c.23.46.18,1.07.64,1.47a2.8,2.8,0,0,1,2.26-1.29h.18c2.29.46,2.64,3,3.21,4.91l0,0a3,3,0,0,0-.05.54c0,.46.08,1,.08,1.44A1.15,1.15,0,0,1,145.28,18.24Z" />
                    <path d="M154.06,19.27a1.11,1.11,0,0,0-.46-.09c-.54,0-1,.38-1.46.38a.89.89,0,0,1-.69-.35c-1.87-.95-2.3-3-2.3-5v-.17a2,2,0,0,1,0-.49c0-.37,0-.71,0-1.06a2.13,2.13,0,0,0-.31-1.21c-.43,0-1,.18-1.55.18a1.29,1.29,0,0,1-1.21-.61.66.66,0,0,1-.11-.4,3.13,3.13,0,0,1,.25-.77h.12c.8,0,1.38-.49,2.15-.52a1.15,1.15,0,0,1,.49.11A4,4,0,0,0,149.44,7V5.83a6.24,6.24,0,0,1,.14-1.46c.32-1.33-.05-3.11.84-4a.93.93,0,0,1,.4,0,1.21,1.21,0,0,1,1.23,1.06c-.68,2.27-.48,4.91-.77,7.35a1.89,1.89,0,0,0,1.09.29c.57,0,1.18-.17,1.75-.15a2,2,0,0,1,1.12.29.61.61,0,0,1,.2.55,3.87,3.87,0,0,1-.37,1,4.48,4.48,0,0,1-1.29.15c-.32,0-.63,0-.95,0a3.46,3.46,0,0,0-1.64.31,8.33,8.33,0,0,1,0,.86c0,.72-.06,1.47-.06,2.19,0,1.37.21,2.67,1.32,3.44a4.64,4.64,0,0,0,3.14-1.89.89.89,0,0,1,.28,0c.46,0,.92.08,1.06.57v0A4.81,4.81,0,0,1,154.06,19.27Z" />
                    <path d="M163.8,12.06a2.72,2.72,0,0,0-1.55-.54,1.79,1.79,0,0,0-.66.11c-2.33,1-1.46,3.85-1.84,5.95-.34.14-.69.57-1.06.57a.61.61,0,0,1-.43-.2c-1.7-2.56-2-5.69-2.44-8.82v-.2a.75.75,0,0,1,.43-.77,1.34,1.34,0,0,1,.46-.09c.86,0,1.06,1,1.4,1.67a.8.8,0,0,1,0,.28c0,.2,0,.43,0,.63a.83.83,0,0,0,.29.72,9.64,9.64,0,0,1,3.33-1.92,3,3,0,0,0,.52,0,2.32,2.32,0,0,1,2.24,1l0,.06v.26A1.26,1.26,0,0,1,163.8,12.06Z" />
                    <path d="M166,7.06c-.57-.2-1.38-.08-1.55-.89a1.39,1.39,0,0,1-.06-.31,1.12,1.12,0,0,1,.75-1.09c.63.26,1.55.37,1.61,1.29C166.7,6.46,166.3,6.78,166,7.06Zm.38,11.4a.77.77,0,0,1-.41-.11l-.71-.72L165,10.4a1.16,1.16,0,0,1,1.12-.72,1.11,1.11,0,0,1,.92.46,10.79,10.79,0,0,1,.46,3.36c0,.57,0,1.17,0,1.78a14.74,14.74,0,0,0,.14,2.21h0C167.36,17.89,166.9,18.46,166.39,18.46Z" />
                    <path d="M176.38,16.37a8,8,0,0,1-2.7,2.09c-.34,0-.69,0-1,0-1.41,0-2.73-.28-3.48-1.57C168.66,12,169,6,168.43,1V.83a.74.74,0,0,1,.8-.83A1.69,1.69,0,0,1,171,1.64a2.43,2.43,0,0,1-.2,1.09c.2,1,.06,3.67.23,4.65.11.69,0,1.38.2,2,.72-.11,1.29-.72,2-.86h.06A4,4,0,0,1,177,10.68l0,0a4.83,4.83,0,0,1,.4,2A7,7,0,0,1,176.38,16.37Zm-1.23-4.08h0c-.2-.69-.66-1.46-1.47-1.46a.8.8,0,0,0-.26,0,4.6,4.6,0,0,0-1.6,4c0,.32,0,.66,0,1a.63.63,0,0,0,.66.37c.41,0,.87-.26,1.3-.26,0,0,.06,0,.06,0a5.83,5.83,0,0,0,1.32-3.67Z" />
                    <path d="M185,17.55a.38.38,0,0,1-.18,0c-.69,0-1.12-.84-1.52-1.29-.77.57-1.23,1.66-2.15,2.15a1.06,1.06,0,0,1-.43,0c-1.07,0-1.75-1.06-2.39-1.75a13.19,13.19,0,0,1-.69-4.51,24,24,0,0,1,.15-2.56.89.89,0,0,1,.74-.31,1.81,1.81,0,0,1,.72.14l.58.75.08,4.62c.26.32.09,1,.63,1a.25.25,0,0,0,.12,0,12.22,12.22,0,0,0,1.89-4.6,1.24,1.24,0,0,1,.95-.57.93.93,0,0,1,.49.14c1.44,1.44.09,4.28,1.75,5.8A1.16,1.16,0,0,1,185,17.55Z" />
                    <path d="M193.47,19.27a1.14,1.14,0,0,0-.46-.09c-.55,0-1,.38-1.46.38a.89.89,0,0,1-.69-.35c-1.87-.95-2.3-3-2.3-5v-.17a1.34,1.34,0,0,1,0-.49c0-.37,0-.71,0-1.06a2.14,2.14,0,0,0-.32-1.21c-.43,0-1,.18-1.55.18a1.26,1.26,0,0,1-1.2-.61.67.67,0,0,1-.12-.4,2.8,2.8,0,0,1,.26-.77h.11c.81,0,1.38-.49,2.16-.52a1.22,1.22,0,0,1,.49.11A4,4,0,0,0,188.85,7V5.83A6.24,6.24,0,0,1,189,4.37c.32-1.33-.06-3.11.83-4a1,1,0,0,1,.4,0,1.22,1.22,0,0,1,1.24,1.06c-.69,2.27-.49,4.91-.78,7.35a1.92,1.92,0,0,0,1.1.29c.57,0,1.17-.17,1.75-.15a2.06,2.06,0,0,1,1.12.29.61.61,0,0,1,.2.55,3.87,3.87,0,0,1-.37,1,4.59,4.59,0,0,1-1.3.15c-.31,0-.63,0-.94,0a3.46,3.46,0,0,0-1.64.31,8.33,8.33,0,0,1,0,.86c0,.72-.06,1.47-.06,2.19,0,1.37.2,2.67,1.32,3.44A4.64,4.64,0,0,0,195,15.74a1,1,0,0,1,.29,0c.46,0,.92.08,1.06.57v0A4.81,4.81,0,0,1,193.47,19.27Z" />
                    <path d="M197.29,7.06c-.57-.2-1.38-.08-1.55-.89a1.39,1.39,0,0,1-.06-.31,1.12,1.12,0,0,1,.75-1.09c.63.26,1.55.37,1.61,1.29C198,6.46,197.58,6.78,197.29,7.06Zm.37,11.4a.76.76,0,0,1-.4-.11l-.72-.72-.28-7.23a1.16,1.16,0,0,1,1.12-.72,1.11,1.11,0,0,1,.92.46,10.51,10.51,0,0,1,.45,3.36c0,.57,0,1.17,0,1.78a13.53,13.53,0,0,0,.14,2.21h0C198.64,17.89,198.18,18.46,197.66,18.46Z" />
                    <path d="M208.52,14l0,0a8.84,8.84,0,0,1-2.67,3.76,3.94,3.94,0,0,1-2,.54,3.08,3.08,0,0,1-1.58-.43c-.78-.77-2-.86-2.47-2a13.48,13.48,0,0,1-.18-2,4,4,0,0,1,.35-1.81c1.23-1,2.47-2.7,4.16-2.81a2.68,2.68,0,0,1,.66-.09,3.5,3.5,0,0,1,2.56,1.29,4,4,0,0,1,1.23,2.73A2.49,2.49,0,0,1,208.52,14Zm-2.15-.91a3,3,0,0,0-.06-.52,1.83,1.83,0,0,0-1.38-1.12,1.35,1.35,0,0,0-.34.06,3.75,3.75,0,0,0-2.45,2.06.85.85,0,0,0-.17.52,1.41,1.41,0,0,0,.52,1.09,1.29,1.29,0,0,0,1.12.69,3.94,3.94,0,0,0,1.66-.66,3.11,3.11,0,0,0,1.1-2.1Z" />
                    <path d="M215.9,18.24c-1.32-.18-1.49-1.12-1.49-2.22,0-.4,0-.83,0-1.26,0-1-.15-1.92-1.15-2.3-1.27,1.44-1.72,3.48-2.3,5.34a1.21,1.21,0,0,1-1,.58,1.07,1.07,0,0,1-.49-.14,1.73,1.73,0,0,1-.77-1.5c0-.69.26-1.46.29-2.18a2.5,2.5,0,0,0-.09-.66v-.09c0-1.46-.6-2.84-.6-4.13a.11.11,0,0,0,0-.09,1.31,1.31,0,0,1,.86-.31,1.27,1.27,0,0,1,1.15.68c.23.46.17,1.07.63,1.47a2.82,2.82,0,0,1,2.27-1.29h.17c2.3.46,2.64,3,3.22,4.91l0,0a3,3,0,0,0-.06.54c0,.46.09,1,.09,1.44A1.16,1.16,0,0,1,215.9,18.24Z" />
                    <path d="M231.55,19.27a1.11,1.11,0,0,0-.46-.09c-.54,0-1,.38-1.46.38a.89.89,0,0,1-.69-.35c-1.87-.95-2.3-3-2.3-5v-.17a2,2,0,0,1,0-.49c0-.37,0-.71,0-1.06a2.13,2.13,0,0,0-.31-1.21c-.43,0-1,.18-1.55.18a1.29,1.29,0,0,1-1.21-.61.66.66,0,0,1-.11-.4,3.13,3.13,0,0,1,.25-.77h.12c.8,0,1.38-.49,2.15-.52a1.15,1.15,0,0,1,.49.11A4,4,0,0,0,226.93,7V5.83a6.24,6.24,0,0,1,.14-1.46c.32-1.33,0-3.11.84-4a.93.93,0,0,1,.4,0,1.21,1.21,0,0,1,1.23,1.06c-.68,2.27-.48,4.91-.77,7.35a1.89,1.89,0,0,0,1.09.29c.57,0,1.18-.17,1.75-.15a2,2,0,0,1,1.12.29.61.61,0,0,1,.2.55,3.87,3.87,0,0,1-.37,1,4.48,4.48,0,0,1-1.29.15c-.32,0-.63,0-1,0a3.48,3.48,0,0,0-1.64.31,8.33,8.33,0,0,1,0,.86c0,.72-.06,1.47-.06,2.19,0,1.37.21,2.67,1.32,3.44a4.64,4.64,0,0,0,3.14-1.89.89.89,0,0,1,.28,0c.46,0,.92.08,1.07.57v0A4.85,4.85,0,0,1,231.55,19.27Z" />
                    <path d="M241.43,14l0,0a8.76,8.76,0,0,1-2.67,3.76,3.89,3.89,0,0,1-2,.54,3.11,3.11,0,0,1-1.58-.43c-.77-.77-2-.86-2.47-2a15.06,15.06,0,0,1-.17-2,4.12,4.12,0,0,1,.34-1.81c1.24-1,2.47-2.7,4.17-2.81a2.68,2.68,0,0,1,.66-.09,3.48,3.48,0,0,1,2.55,1.29,4,4,0,0,1,1.24,2.73A2.49,2.49,0,0,1,241.43,14Zm-2.15-.91a3,3,0,0,0-.06-.52,1.81,1.81,0,0,0-1.38-1.12,1.43,1.43,0,0,0-.34.06,3.71,3.71,0,0,0-2.44,2.06.79.79,0,0,0-.17.52,1.44,1.44,0,0,0,.51,1.09,1.3,1.3,0,0,0,1.12.69,4,4,0,0,0,1.67-.66,3.14,3.14,0,0,0,1.09-2.1Z" />
                    <path d="M256.28,14.24a17.61,17.61,0,0,1-.28,3.25,1.41,1.41,0,0,1-1.3.72,2.08,2.08,0,0,1-.74-.15,7.32,7.32,0,0,1-2.87.86,2.45,2.45,0,0,1-1.44-.43c-.75-.17-1.15-1-1.61-1.6a4.17,4.17,0,0,1-.14-1,3.36,3.36,0,0,1,1-2.44,4.16,4.16,0,0,1,3-1.46h.17a.15.15,0,0,1,.09,0c.51,0,.8.49,1.26.49a1.77,1.77,0,0,0,.37-.06,2.61,2.61,0,0,0,.18-.94,4.73,4.73,0,0,0-1.21-2.67.34.34,0,0,0-.17,0c-.69,0-1.5.74-2.07.74-.32,0-.57-.2-.72-.86.06-1.15,1.09-1.55,2-2,1.3.06,2.7.17,3.3,1.52a14.78,14.78,0,0,1,1.18,6Zm-3.36.69v-.2c-.17-.4-.4-.54-.69-.54-.54,0-1.2.69-1.6,1,0,.29-.29.6-.29.89a.43.43,0,0,0,.29.4,2.92,2.92,0,0,0,.94.17,1.4,1.4,0,0,0,1-.31.65.65,0,0,0,.46-.69C253,15.45,252.92,15.19,252.92,14.93Z" />
                    <path d="M269.21,18a16.18,16.18,0,0,1-2.67.43,2.53,2.53,0,0,1-2.24-1.09,4.16,4.16,0,0,1-.63-2.3c0-1.92,1.09-4,1.81-5.6.48-1.15,1.83-1.81,1.83-3.24a1.44,1.44,0,0,1,.61-.12c1.09,0,2,1.26,2.35,2.13a4.54,4.54,0,0,1-1,2.95c-.2.29-.38.41-.55.43-.46,0-.83-.68-1.23-.71-.18,0-.35.14-.55.54a12,12,0,0,0-.8,4.54,1.3,1.3,0,0,0,1.12.57,2.79,2.79,0,0,0,.46-.05c1.09-.26,2.06-1.64,3.07-1.67.4,0,.8.23,1.23.83C271.91,17.14,270.1,17.17,269.21,18Z" />
                    <path d="M274.69,18.7a.21.21,0,0,1-.14,0,1.83,1.83,0,0,1-1.41-1c0-.77,0-1.55,0-2.33,0-2.24.05-5,.05-7.26a61.84,61.84,0,0,0-.31-6.92V1a.89.89,0,0,1,.4-.87h.08A2.13,2.13,0,0,1,275,1.35a28.93,28.93,0,0,1,.29,4.45c0,2.24-.15,5-.15,7.3a28.87,28.87,0,0,0,.29,4.42C275.38,18,275,18.29,274.69,18.7Z" />
                    <path d="M282.59,18.67a2.59,2.59,0,0,1-1.2.25,11.79,11.79,0,0,1-2.85-.71,4.16,4.16,0,0,1-1.89-3.94,16,16,0,0,1,.14-2,10.46,10.46,0,0,1,1.5-3.1c.74-1,1.89-1,3-1a2.68,2.68,0,0,1,.66.09c.78.46,1.87,1.06,2.15,2.13,0,.08.21.83.21.91a2.36,2.36,0,0,1-1.5,2,28.25,28.25,0,0,1-4.28.84,1.72,1.72,0,0,0,.29,1.43,3.6,3.6,0,0,0,2.44,1.18,1.92,1.92,0,0,0,1.61-.6.77.77,0,0,1,.26,0,1.35,1.35,0,0,1,1,.6,1.27,1.27,0,0,1,0,.32C284.17,17.92,283.08,18.24,282.59,18.67Zm-.71-7.15c.4-.44-.12-1-.49-1a2,2,0,0,0-1.5.49,5,5,0,0,0-.6,1.15A6.16,6.16,0,0,0,281.88,11.52Z" />
                    <path d="M292.85,14.24a17.62,17.62,0,0,1-.29,3.25,1.4,1.4,0,0,1-1.29.72,2.09,2.09,0,0,1-.75-.15,7.32,7.32,0,0,1-2.87.86,2.45,2.45,0,0,1-1.44-.43c-.74-.17-1.15-1-1.61-1.6a4.17,4.17,0,0,1-.14-1,3.36,3.36,0,0,1,1-2.44,4.17,4.17,0,0,1,3-1.46h.17a.15.15,0,0,1,.09,0c.52,0,.8.49,1.26.49a2,2,0,0,0,.38-.06,2.83,2.83,0,0,0,.17-.94,4.66,4.66,0,0,0-1.21-2.67.32.32,0,0,0-.17,0c-.69,0-1.49.74-2.07.74-.31,0-.57-.2-.72-.86.06-1.15,1.1-1.55,2-2,1.3.06,2.7.17,3.31,1.52a15,15,0,0,1,1.17,6Zm-3.36.69v-.2c-.18-.4-.41-.54-.69-.54-.55,0-1.21.69-1.61,1,0,.29-.29.6-.29.89a.43.43,0,0,0,.29.4,3,3,0,0,0,.95.17,1.37,1.37,0,0,0,.94-.31.65.65,0,0,0,.46-.69C289.54,15.45,289.49,15.19,289.49,14.93Z" />
                    <path d="M301.06,18.24c-1.32-.18-1.49-1.12-1.49-2.22,0-.4,0-.83,0-1.26,0-1-.14-1.92-1.14-2.3-1.27,1.44-1.73,3.48-2.3,5.34a1.21,1.21,0,0,1-.95.58,1.07,1.07,0,0,1-.49-.14,1.73,1.73,0,0,1-.77-1.5c0-.69.26-1.46.28-2.18a2.48,2.48,0,0,0-.08-.66v-.09c0-1.46-.6-2.84-.6-4.13a.11.11,0,0,0,0-.09,1.31,1.31,0,0,1,.86-.31,1.27,1.27,0,0,1,1.15.68c.23.46.17,1.07.63,1.47a2.82,2.82,0,0,1,2.27-1.29h.17c2.3.46,2.64,3,3.22,4.91l0,0a3,3,0,0,0-.06.54c0,.46.09,1,.09,1.44A1.16,1.16,0,0,1,301.06,18.24Z" />
                    <path d="M308.18,18.67a2.59,2.59,0,0,1-1.2.25,11.79,11.79,0,0,1-2.85-.71,4.16,4.16,0,0,1-1.89-3.94,16,16,0,0,1,.14-2,10.46,10.46,0,0,1,1.5-3.1c.74-1,1.89-1,3-1a2.68,2.68,0,0,1,.66.09c.78.46,1.87,1.06,2.16,2.13,0,.08.2.83.2.91a2.36,2.36,0,0,1-1.5,2,28.25,28.25,0,0,1-4.28.84,1.72,1.72,0,0,0,.29,1.43,3.6,3.6,0,0,0,2.44,1.18,1.93,1.93,0,0,0,1.61-.6.77.77,0,0,1,.26,0,1.33,1.33,0,0,1,1,.6,1.27,1.27,0,0,1,0,.32C309.76,17.92,308.67,18.24,308.18,18.67Zm-.72-7.15c.41-.44-.11-1-.48-1a2,2,0,0,0-1.5.49,5,5,0,0,0-.6,1.15A6.09,6.09,0,0,0,307.46,11.52Z" />
                    <path d="M317.86,12.06a2.69,2.69,0,0,0-1.55-.54,1.79,1.79,0,0,0-.66.11c-2.33,1-1.46,3.85-1.84,5.95-.34.14-.69.57-1.06.57a.61.61,0,0,1-.43-.2c-1.69-2.56-2-5.69-2.44-8.82v-.2a.75.75,0,0,1,.43-.77,1.38,1.38,0,0,1,.46-.09c.86,0,1.06,1,1.41,1.67a1.19,1.19,0,0,1,0,.28c0,.2,0,.43,0,.63a.82.82,0,0,0,.28.72,9.74,9.74,0,0,1,3.33-1.92,3,3,0,0,0,.52,0,2.32,2.32,0,0,1,2.24,1l0,.06v.26A1.26,1.26,0,0,1,317.86,12.06Z" />
                    <path d="M5.66,40.18a3.66,3.66,0,0,1-2,.54,8.72,8.72,0,0,1-2.73-.54C.63,39.8,0,39.54,0,39.09a.38.38,0,0,1,0-.18,1.2,1.2,0,0,1,1-.48c.72,0,1.52.45,2.21.48.44,0,.81-.2,1.07-.89a20,20,0,0,1-2.56-2A4.33,4.33,0,0,1,.46,33.69,2.79,2.79,0,0,1,2,31.91a6.5,6.5,0,0,1,2.3-.55,2,2,0,0,1,1.49.69,5.63,5.63,0,0,1,.11.75.76.76,0,0,1-.14.46.84.84,0,0,1-.89.54c-.4,0-.89-.2-1.23-.2s-.61.4-.61,1.12a22.72,22.72,0,0,1,2.85,2.13,3.13,3.13,0,0,1,.74,1.32l0,0h0A2.53,2.53,0,0,1,5.66,40.18Z" />
                    <path d="M14.13,40.61a.34.34,0,0,1-.17,0c-.69,0-1.12-.83-1.52-1.29-.78.57-1.24,1.66-2.16,2.15a1,1,0,0,1-.43,0c-1.06,0-1.75-1.06-2.38-1.75a13,13,0,0,1-.69-4.51c0-.86.06-1.72.14-2.56a.9.9,0,0,1,.75-.31,1.81,1.81,0,0,1,.72.14l.57.75.09,4.62c.26.32.08,1,.63,1a.15.15,0,0,0,.11,0,12.08,12.08,0,0,0,1.9-4.6,1.22,1.22,0,0,1,1-.57,1.07,1.07,0,0,1,.49.14c1.43,1.44.08,4.28,1.75,5.8A1.17,1.17,0,0,1,14.13,40.61Z" />
                    <path d="M20.16,40.18a3.63,3.63,0,0,1-2,.54,8.71,8.71,0,0,1-2.72-.54c-.26-.38-.9-.64-.9-1.09a.38.38,0,0,1,0-.18,1.22,1.22,0,0,1,1-.48c.72,0,1.52.45,2.21.48.43,0,.8-.2,1.06-.89a20.68,20.68,0,0,1-2.55-2A4.41,4.41,0,0,1,15,33.69a2.82,2.82,0,0,1,1.58-1.78,6.5,6.5,0,0,1,2.3-.55,2,2,0,0,1,1.49.69,5.6,5.6,0,0,1,.12.75.83.83,0,0,1-.14.46.84.84,0,0,1-.89.54c-.41,0-.89-.2-1.24-.2s-.6.4-.6,1.12a21.92,21.92,0,0,1,2.84,2.13,3.14,3.14,0,0,1,.75,1.32l0,0h0A2.5,2.5,0,0,1,20.16,40.18Z" />
                    <path d="M28,42.33a1.17,1.17,0,0,0-.46-.08c-.55,0-1,.37-1.47.37a.89.89,0,0,1-.69-.35c-1.86-.94-2.29-3-2.29-5v-.18a1.34,1.34,0,0,1,0-.49c0-.37,0-.71,0-1.06a2.08,2.08,0,0,0-.31-1.2c-.43,0-1,.17-1.55.17A1.28,1.28,0,0,1,20,34a.79.79,0,0,1-.11-.41,3.43,3.43,0,0,1,.25-.77h.12c.8,0,1.38-.49,2.15-.52a1,1,0,0,1,.49.12,4,4,0,0,0,.43-2.27V28.89a5.74,5.74,0,0,1,.15-1.46c.31-1.32-.06-3.1.83-4.05a.94.94,0,0,1,.4,0,1.22,1.22,0,0,1,1.24,1.06c-.69,2.27-.49,4.91-.78,7.35a1.89,1.89,0,0,0,1.09.29c.58,0,1.18-.17,1.75-.14a2,2,0,0,1,1.12.28.62.62,0,0,1,.2.55,3.55,3.55,0,0,1-.37,1,4.48,4.48,0,0,1-1.29.15c-.32,0-.63,0-.95,0a3.61,3.61,0,0,0-1.64.31,8.43,8.43,0,0,1,0,.87c0,.71-.06,1.46-.06,2.18,0,1.38.21,2.67,1.33,3.44A4.64,4.64,0,0,0,29.5,38.8a.89.89,0,0,1,.28,0c.46,0,.92.08,1.07.57v0A4.84,4.84,0,0,1,28,42.33Z" />
                    <path d="M38.26,37.31A17.51,17.51,0,0,1,38,40.55a1.4,1.4,0,0,1-1.29.72,2.32,2.32,0,0,1-.75-.14,7.49,7.49,0,0,1-2.87.86,2.52,2.52,0,0,1-1.44-.43c-.74-.18-1.15-1-1.61-1.61a4.17,4.17,0,0,1-.14-1,3.37,3.37,0,0,1,1-2.44,4.15,4.15,0,0,1,3-1.46h.17a.11.11,0,0,1,.09,0c.52,0,.8.48,1.26.48a1.89,1.89,0,0,0,.38,0,2.93,2.93,0,0,0,.17-1,4.66,4.66,0,0,0-1.21-2.67.32.32,0,0,0-.17,0c-.69,0-1.49.75-2.07.75-.31,0-.57-.21-.71-.87.05-1.14,1.09-1.55,2-2,1.29.06,2.69.17,3.3,1.52a15,15,0,0,1,1.18,6ZM34.9,38v-.21c-.18-.4-.4-.54-.69-.54-.55,0-1.21.69-1.61,1,0,.29-.29.6-.29.89a.42.42,0,0,0,.29.4,3,3,0,0,0,.95.18,1.42,1.42,0,0,0,1-.32.65.65,0,0,0,.45-.69C35,38.51,34.9,38.25,34.9,38Z" />
                    <path d="M40.58,30.13C40,29.92,39.21,30,39,29.24a1.39,1.39,0,0,1-.05-.32,1.11,1.11,0,0,1,.74-1.09c.63.26,1.55.37,1.61,1.29C41.27,29.52,40.87,29.84,40.58,30.13ZM41,41.53a.79.79,0,0,1-.41-.12l-.71-.72-.29-7.23a1.16,1.16,0,0,1,1.12-.72,1.11,1.11,0,0,1,.92.46,10.79,10.79,0,0,1,.46,3.36c0,.57,0,1.18,0,1.78a14.66,14.66,0,0,0,.14,2.21h0C41.93,41,41.47,41.53,41,41.53Z" />
                    <path d="M50.55,41.3c-1.32-.17-1.49-1.12-1.49-2.21,0-.41,0-.84,0-1.27,0-1-.14-1.92-1.14-2.29C46.67,37,46.21,39,45.64,40.87a1.22,1.22,0,0,1-1,.57,1.07,1.07,0,0,1-.49-.14,1.72,1.72,0,0,1-.77-1.5c0-.69.26-1.46.28-2.18a2.48,2.48,0,0,0-.08-.66v-.08c0-1.47-.6-2.85-.6-4.14a.11.11,0,0,0,0-.09,1.31,1.31,0,0,1,.86-.31A1.26,1.26,0,0,1,45,33c.23.46.17,1.06.63,1.46a2.82,2.82,0,0,1,2.27-1.29h.17c2.3.46,2.64,3,3.22,4.91l0,0a3.09,3.09,0,0,0-.06.55c0,.46.09,1,.09,1.43A1.16,1.16,0,0,1,50.55,41.3Z" />
                    <path d="M59.74,37.31a17.51,17.51,0,0,1-.29,3.24,1.38,1.38,0,0,1-1.29.72,2.32,2.32,0,0,1-.75-.14,7.44,7.44,0,0,1-2.87.86,2.49,2.49,0,0,1-1.43-.43c-.75-.18-1.15-1-1.61-1.61a3.74,3.74,0,0,1-.15-1,3.37,3.37,0,0,1,1-2.44,4.16,4.16,0,0,1,3-1.46h.17a.09.09,0,0,1,.08,0c.52,0,.81.48,1.27.48a1.8,1.8,0,0,0,.37,0,2.93,2.93,0,0,0,.17-1,4.71,4.71,0,0,0-1.2-2.67.38.38,0,0,0-.18,0c-.68,0-1.49.75-2.06.75-.32,0-.58-.21-.72-.87.06-1.14,1.09-1.55,2-2,1.29.06,2.7.17,3.3,1.52a14.83,14.83,0,0,1,1.18,6ZM56.38,38v-.21c-.17-.4-.4-.54-.69-.54-.54,0-1.21.69-1.61,1,0,.29-.29.6-.29.89a.42.42,0,0,0,.29.4,3,3,0,0,0,1,.18,1.4,1.4,0,0,0,.95-.32.66.66,0,0,0,.46-.69C56.44,38.51,56.38,38.25,56.38,38Z" />
                    <path d="M68.36,39.43a8.25,8.25,0,0,1-2.7,2.1c-.35,0-.69,0-1,0-1.41,0-2.73-.29-3.47-1.58-.52-5-.15-10.89-.75-15.91v-.18a.75.75,0,0,1,.81-.83A1.69,1.69,0,0,1,63,24.7a2.41,2.41,0,0,1-.21,1.09c.21.95.06,3.68.23,4.65.12.69,0,1.38.2,2,.72-.11,1.3-.72,2-.86h0A4,4,0,0,1,69,33.74l0,0a5,5,0,0,1,.4,2A7,7,0,0,1,68.36,39.43Zm-1.24-4.08h0c-.2-.69-.66-1.46-1.46-1.46a.77.77,0,0,0-.26,0,4.62,4.62,0,0,0-1.61,4c0,.31,0,.66,0,1a.61.61,0,0,0,.66.37c.4,0,.86-.26,1.29-.26,0,0,.06,0,.06,0a5.81,5.81,0,0,0,1.32-3.67Z" />
                    <path d="M72.06,41.76c-.06,0-.11,0-.14,0a1.83,1.83,0,0,1-1.41-1c0-.77,0-1.55,0-2.32,0-2.24.06-5,.06-7.27a62,62,0,0,0-.32-6.92v-.17a.87.87,0,0,1,.41-.86h.08a2.14,2.14,0,0,1,1.67,1.2,28.93,28.93,0,0,1,.29,4.45c0,2.24-.15,5-.15,7.3a28.78,28.78,0,0,0,.29,4.42C72.75,41.07,72.35,41.35,72.06,41.76Z" />
                    <path d="M80,41.73a2.62,2.62,0,0,1-1.21.26,11.84,11.84,0,0,1-2.84-.72A4.16,4.16,0,0,1,74,37.33a14.3,14.3,0,0,1,.15-2,10.81,10.81,0,0,1,1.49-3.1c.75-1,1.9-1,3-1a2.64,2.64,0,0,1,.66.08c.77.46,1.86,1.06,2.15,2.13,0,.08.2.83.2.92a2.37,2.37,0,0,1-1.49,2,29.54,29.54,0,0,1-4.28.83,1.72,1.72,0,0,0,.29,1.43,3.58,3.58,0,0,0,2.44,1.18,1.9,1.9,0,0,0,1.61-.6.72.72,0,0,1,.25,0,1.35,1.35,0,0,1,1,.6,1.27,1.27,0,0,1,0,.32C81.54,41,80.45,41.3,80,41.73Zm-.72-7.15c.4-.43-.11-1-.49-1a2,2,0,0,0-1.49.49,5,5,0,0,0-.6,1.15A6.09,6.09,0,0,0,79.24,34.58Z" />
                    <path d="M98.74,25.27c-.37.32-.74.84-1.17.84a.44.44,0,0,1-.29-.12c-1.41-.63-.06-3.24-1.55-3.24a2.58,2.58,0,0,0-.43.05,11.43,11.43,0,0,0-1.53,6.12c0,.46,0,.92.06,1.38a3.87,3.87,0,0,0-.17.95,5.56,5.56,0,0,0,.49,2.06h.17c1,0,1.92-1,2.76-1,.4,0,.77.23,1.12.86a1.42,1.42,0,0,1-.89,1.29h-.18a4.66,4.66,0,0,0-2.84,1.18c.23,1.87,1,3.53,1.12,5.54a1.26,1.26,0,0,1-1.06.55,1.38,1.38,0,0,1-.66-.17l-.43-.29a39.79,39.79,0,0,1-1-4.45.8.8,0,0,0-.63-.32c-.54,0-1.2.46-1.86.46a.74.74,0,0,1-.26,0c-.32-.31-1-.37-1-1,.78-1,2.33-1,3.36-1.73v-.34c0-1.7-.17-3.53-.17-5.31,0-3.34.55-6.5,3.62-8.1a4,4,0,0,1,3.13,2,5.36,5.36,0,0,1,.46,1.92A2.61,2.61,0,0,1,98.74,25.27Z" />
                    <path d="M105.92,40.61a.36.36,0,0,1-.17,0c-.69,0-1.12-.83-1.52-1.29-.78.57-1.23,1.66-2.15,2.15a1.06,1.06,0,0,1-.43,0c-1.07,0-1.76-1.06-2.39-1.75a13.19,13.19,0,0,1-.69-4.51,24.1,24.1,0,0,1,.15-2.56.89.89,0,0,1,.74-.31,1.81,1.81,0,0,1,.72.14l.57.75.09,4.62c.26.32.09,1,.63,1a.18.18,0,0,0,.12,0,12.22,12.22,0,0,0,1.89-4.6,1.24,1.24,0,0,1,1-.57,1,1,0,0,1,.49.14c1.43,1.44.08,4.28,1.75,5.8A1.17,1.17,0,0,1,105.92,40.61Z" />
                    <path d="M114.37,42.33a1.17,1.17,0,0,0-.46-.08c-.55,0-1,.37-1.47.37a.91.91,0,0,1-.69-.35c-1.86-.94-2.29-3-2.29-5v-.18a1.34,1.34,0,0,1,0-.49c0-.37,0-.71,0-1.06a2.09,2.09,0,0,0-.32-1.2c-.43,0-1,.17-1.55.17a1.28,1.28,0,0,1-1.21-.6.79.79,0,0,1-.11-.41,3,3,0,0,1,.26-.77h.11c.81,0,1.38-.49,2.16-.52a1,1,0,0,1,.48.12,4.05,4.05,0,0,0,.44-2.27V28.89a5.75,5.75,0,0,1,.14-1.46c.31-1.32-.06-3.1.83-4.05a1,1,0,0,1,.4,0,1.22,1.22,0,0,1,1.24,1.06c-.69,2.27-.49,4.91-.78,7.35a1.91,1.91,0,0,0,1.09.29c.58,0,1.18-.17,1.76-.14a2.05,2.05,0,0,1,1.12.28.62.62,0,0,1,.2.55,3.59,3.59,0,0,1-.38,1,4.48,4.48,0,0,1-1.29.15c-.31,0-.63,0-1,0a3.57,3.57,0,0,0-1.63.31,8.43,8.43,0,0,1,0,.87c0,.71-.06,1.46-.06,2.18,0,1.38.2,2.67,1.32,3.44a4.64,4.64,0,0,0,3.13-1.89,1,1,0,0,1,.29,0c.46,0,.92.08,1.06.57v0A4.81,4.81,0,0,1,114.37,42.33Z" />
                    <path d="M123.88,40.61a.38.38,0,0,1-.18,0c-.69,0-1.12-.83-1.52-1.29-.77.57-1.23,1.66-2.15,2.15a1.06,1.06,0,0,1-.43,0c-1.07,0-1.76-1.06-2.39-1.75a13.19,13.19,0,0,1-.69-4.51,24.1,24.1,0,0,1,.15-2.56.89.89,0,0,1,.74-.31,1.81,1.81,0,0,1,.72.14l.58.75.08,4.62c.26.32.09,1,.63,1a.18.18,0,0,0,.12,0,12.22,12.22,0,0,0,1.89-4.6,1.24,1.24,0,0,1,.95-.57,1,1,0,0,1,.49.14c1.44,1.44.09,4.28,1.75,5.8A1.18,1.18,0,0,1,123.88,40.61Z" />
                    <path d="M132.15,35.12a2.79,2.79,0,0,0-1.55-.54,2,2,0,0,0-.66.11c-2.33,1-1.47,3.85-1.84,6-.35.14-.69.57-1.06.57a.63.63,0,0,1-.44-.2c-1.69-2.56-2-5.69-2.44-8.82V32a.76.76,0,0,1,.43-.77,1.43,1.43,0,0,1,.46-.09c.87,0,1.07,1,1.41,1.67a.8.8,0,0,1,0,.28c0,.21,0,.43,0,.64a.82.82,0,0,0,.29.71,9.64,9.64,0,0,1,3.33-1.92,3.08,3.08,0,0,0,.52,0,2.33,2.33,0,0,1,2.24,1l0,.06v.26A1.27,1.27,0,0,1,132.15,35.12Z" />
                    <path d="M138.55,41.73a2.61,2.61,0,0,1-1.2.26,11.8,11.8,0,0,1-2.85-.72,4.16,4.16,0,0,1-1.89-3.94,16,16,0,0,1,.14-2,10.58,10.58,0,0,1,1.5-3.1c.74-1,1.89-1,3-1a2.64,2.64,0,0,1,.66.08c.78.46,1.87,1.06,2.16,2.13,0,.08.2.83.2.92a2.38,2.38,0,0,1-1.5,2,29.29,29.29,0,0,1-4.28.83,1.72,1.72,0,0,0,.29,1.43,3.6,3.6,0,0,0,2.44,1.18,1.9,1.9,0,0,0,1.61-.6.77.77,0,0,1,.26,0,1.33,1.33,0,0,1,1,.6,1.27,1.27,0,0,1,0,.32C140.13,41,139,41.3,138.55,41.73Zm-.72-7.15c.41-.43-.11-1-.48-1a2,2,0,0,0-1.5.49,5,5,0,0,0-.6,1.15A6.09,6.09,0,0,0,137.83,34.58Z" />
                    <path d="M142.54,39.72a1.31,1.31,0,0,1-1.23-.86,2.56,2.56,0,0,1-.14-.69.57.57,0,0,1,.14-.41.77.77,0,0,1,.75-.4,1.39,1.39,0,0,1,.63.15c.2.37.6.31.83.69A1.79,1.79,0,0,1,142.54,39.72Z" />
                    <path d="M165.64,29c-1.78,3.33-2.13,7.23-2.9,11-.32.38-.44,1.07-.92,1.13h-.15c-.83,0-1.15-1-1.55-1.5a35.91,35.91,0,0,1-1.92-7.72l-.29-.29a45.4,45.4,0,0,0-3.16,8.93c-.34.29-.4,1-.89,1a.3.3,0,0,1-.2-.06.6.6,0,0,1-.29.09c-.4,0-.63-.49-.94-.72a40.55,40.55,0,0,1-1-8.47c-.06-1.12-.15-2.39-.15-3.62V26.68a.7.7,0,0,1,.44-.14c.4,0,.8.25,1.2.28l.26.55.32,8.67.25.29c1.47-3.07,2.62-6.23,3.85-9.39a1.67,1.67,0,0,1,1.09-.69.65.65,0,0,1,.43.14,5,5,0,0,1,.69,2.65,28.73,28.73,0,0,0,1.38,7.26,16.6,16.6,0,0,0,1-3.56,34.87,34.87,0,0,1,2.7-7.18c.11-.49.69-.55,1-.8.61,0,.84.69,1.12,1.09l0,0C166.84,27,165.81,27.83,165.64,29Z" />
                    <path d="M170.23,41.73A2.61,2.61,0,0,1,169,42a11.8,11.8,0,0,1-2.85-.72,4.16,4.16,0,0,1-1.89-3.94,16,16,0,0,1,.14-2,10.58,10.58,0,0,1,1.5-3.1c.74-1,1.89-1,3-1a2.64,2.64,0,0,1,.66.08c.78.46,1.87,1.06,2.15,2.13,0,.08.21.83.21.92a2.38,2.38,0,0,1-1.5,2,29.29,29.29,0,0,1-4.28.83,1.72,1.72,0,0,0,.29,1.43,3.6,3.6,0,0,0,2.44,1.18,1.89,1.89,0,0,0,1.61-.6.77.77,0,0,1,.26,0,1.33,1.33,0,0,1,1,.6,1.27,1.27,0,0,1,0,.32C171.81,41,170.72,41.3,170.23,41.73Zm-.71-7.15c.4-.43-.12-1-.49-1a2,2,0,0,0-1.5.49,5,5,0,0,0-.6,1.15A6.16,6.16,0,0,0,169.52,34.58Z" />
                    <path d="M189.28,40.09a1,1,0,0,1-.44.78c-.34.11-.57.46-.94.46a.38.38,0,0,1-.18,0,3.94,3.94,0,0,1-1.14-1.44,5.35,5.35,0,0,1-4.34,2.27,4,4,0,0,1-.6,0,4.11,4.11,0,0,1-2.3-4v-.17a7.35,7.35,0,0,1,4.19-6.75.94.94,0,0,1,.6-.12c.41,0,.81.12,1.27.12.08,0,.4.2.52.17l0-8a1.08,1.08,0,0,1,.84-.46,1.87,1.87,0,0,1,1.4.89c0,.74,0,1.52,0,2.27a93.68,93.68,0,0,0,.2,11.71s.2.41.2.43C188.56,38.88,189.25,39.52,189.28,40.09Zm-3.45-5.77a1.45,1.45,0,0,0-1.26-.78,2,2,0,0,0-1.64,1,4.1,4.1,0,0,0-1,3,8.45,8.45,0,0,0,.35,2.3.58.58,0,0,0,.4.17,1.41,1.41,0,0,0,.75-.26c1.29-1.12,2.55-2.56,2.58-4.31A4.48,4.48,0,0,0,185.83,34.32Z" />
                    <path d="M195.82,41.73a2.61,2.61,0,0,1-1.2.26,11.72,11.72,0,0,1-2.84-.72,4.15,4.15,0,0,1-1.9-3.94,16,16,0,0,1,.14-2,10.58,10.58,0,0,1,1.5-3.1c.74-1,1.89-1,3-1a2.64,2.64,0,0,1,.66.08c.78.46,1.87,1.06,2.16,2.13,0,.08.2.83.2.92a2.38,2.38,0,0,1-1.5,2,29.46,29.46,0,0,1-4.27.83,1.68,1.68,0,0,0,.28,1.43,3.6,3.6,0,0,0,2.44,1.18,1.89,1.89,0,0,0,1.61-.6.77.77,0,0,1,.26,0,1.37,1.37,0,0,1,1,.6,1.89,1.89,0,0,1,0,.32C197.4,41,196.31,41.3,195.82,41.73Zm-.71-7.15c.4-.43-.12-1-.49-1s-1.18.06-1.49.49a4.75,4.75,0,0,0-.61,1.15A6.16,6.16,0,0,0,195.11,34.58Z" />
                    <path d="M203.26,40.18a3.63,3.63,0,0,1-2,.54,8.71,8.71,0,0,1-2.72-.54c-.26-.38-.89-.64-.89-1.09a.55.55,0,0,1,0-.18,1.22,1.22,0,0,1,1-.48c.72,0,1.52.45,2.21.48.43,0,.8-.2,1.06-.89a20.68,20.68,0,0,1-2.55-2,4.33,4.33,0,0,1-1.32-2.32,2.77,2.77,0,0,1,1.58-1.78,6.45,6.45,0,0,1,2.29-.55,2,2,0,0,1,1.5.69c0,.23.11.49.11.75a1.08,1.08,0,0,1-1,1c-.41,0-.89-.2-1.24-.2s-.6.4-.6,1.12a21.92,21.92,0,0,1,2.84,2.13,3,3,0,0,1,.75,1.32l0,0h0A2.54,2.54,0,0,1,203.26,40.18Z" />
                    <path d="M206.05,30.13c-.57-.21-1.38-.09-1.55-.89a1.47,1.47,0,0,1-.06-.32,1.12,1.12,0,0,1,.75-1.09c.63.26,1.55.37,1.61,1.29C206.74,29.52,206.34,29.84,206.05,30.13Zm.37,11.4a.78.78,0,0,1-.4-.12l-.72-.72L205,33.46a1.16,1.16,0,0,1,1.12-.72,1.11,1.11,0,0,1,.92.46,10.51,10.51,0,0,1,.45,3.36c0,.57,0,1.18,0,1.78a13.46,13.46,0,0,0,.14,2.21h0C207.4,41,206.94,41.53,206.42,41.53Z" />
                    <path d="M217.22,43c-.4,2.13-1.18,4.8-3.62,5.4a2.67,2.67,0,0,1-.92.09,4.4,4.4,0,0,1-3.41-2,.8.8,0,0,1,0-.26,2.17,2.17,0,0,1,.6-1.32c1.27,0,1.47,1.63,2.73,1.61a1.73,1.73,0,0,0,1.72-1A10.07,10.07,0,0,0,215,41.9a7,7,0,0,0-.77-3.39c-.92.55-2,1.49-3.1,1.52a1.84,1.84,0,0,1-1.24-.51,3.59,3.59,0,0,1-1.35-3,4.65,4.65,0,0,1,0-.63c.23-2.19,1.06-4.57,3.3-5.34,1,.37,2.1.34,2.73,1.43v.06c0,.75.6,1.29.89,2C215.81,37.1,217.05,39.83,217.22,43Zm-5.08-10a5.43,5.43,0,0,0-1.29,3.39,4,4,0,0,0,.31,1.58,3.53,3.53,0,0,0,2.13-2.65l0,0C213,34.52,212.94,33.49,212.14,33Z" />
                    <path d="M224.52,41.3c-1.32-.17-1.5-1.12-1.5-2.21,0-.41,0-.84,0-1.27,0-1-.14-1.92-1.15-2.29-1.26,1.43-1.72,3.47-2.29,5.34a1.24,1.24,0,0,1-1,.57,1,1,0,0,1-.49-.14,1.72,1.72,0,0,1-.77-1.5c0-.69.25-1.46.28-2.18a3,3,0,0,0-.08-.66v-.08c0-1.47-.61-2.85-.61-4.14a.11.11,0,0,0,0-.09,1.34,1.34,0,0,1,.87-.31A1.26,1.26,0,0,1,219,33c.23.46.17,1.06.63,1.46a2.82,2.82,0,0,1,2.27-1.29h.17c2.3.46,2.64,3,3.21,4.91l0,0a3.09,3.09,0,0,0-.06.55c0,.46.08,1,.08,1.43A1.15,1.15,0,0,1,224.52,41.3Z" />
                    <path d="M241.23,37.08l0,0a8.76,8.76,0,0,1-2.67,3.76,3.89,3.89,0,0,1-2,.54,3.08,3.08,0,0,1-1.58-.43c-.78-.77-2-.86-2.47-2a13.53,13.53,0,0,1-.17-2,4,4,0,0,1,.34-1.81c1.24-1,2.47-2.7,4.17-2.81a2.68,2.68,0,0,1,.66-.09,3.5,3.5,0,0,1,2.55,1.29,4,4,0,0,1,1.24,2.73A2.59,2.59,0,0,1,241.23,37.08Zm-2.15-.92a3,3,0,0,0-.06-.52,1.81,1.81,0,0,0-1.38-1.12,1.35,1.35,0,0,0-.34.06,3.71,3.71,0,0,0-2.44,2.06.79.79,0,0,0-.17.52,1.4,1.4,0,0,0,.51,1.09,1.3,1.3,0,0,0,1.12.69,4,4,0,0,0,1.67-.66,3.14,3.14,0,0,0,1.09-2.09Z" />
                    <path d="M248.41,40.61a.36.36,0,0,1-.17,0c-.69,0-1.12-.83-1.52-1.29-.78.57-1.24,1.66-2.15,2.15a1.09,1.09,0,0,1-.44,0c-1.06,0-1.75-1.06-2.38-1.75a13,13,0,0,1-.69-4.51,24.1,24.1,0,0,1,.15-2.56.89.89,0,0,1,.74-.31,1.81,1.81,0,0,1,.72.14l.58.75.08,4.62c.26.32.09,1,.63,1a.18.18,0,0,0,.12,0,12.22,12.22,0,0,0,1.89-4.6,1.24,1.24,0,0,1,.95-.57,1.07,1.07,0,0,1,.49.14c1.43,1.44.09,4.28,1.75,5.8A1.17,1.17,0,0,1,248.41,40.61Z" />
                    <path d="M256.69,35.12a2.8,2.8,0,0,0-1.56-.54,2,2,0,0,0-.66.11c-2.32,1-1.46,3.85-1.83,6-.35.14-.69.57-1.07.57a.63.63,0,0,1-.43-.2c-1.69-2.56-2-5.69-2.44-8.82V32a.76.76,0,0,1,.43-.77,1.38,1.38,0,0,1,.46-.09c.86,0,1.07,1,1.41,1.67a.8.8,0,0,1,0,.28c0,.21,0,.43,0,.64a.82.82,0,0,0,.29.71,9.55,9.55,0,0,1,3.33-1.92,2.91,2.91,0,0,0,.51,0,2.35,2.35,0,0,1,2.25,1l0,.06v.26A1.26,1.26,0,0,1,256.69,35.12Z" />
                    <path d="M271.79,36.76c-1.12.86-2,2.18-3.47,2.18,0,0-.06,0-.06,0a.73.73,0,0,0-.46.66c0,.38.26.81.26,1.18v.09l.43,5.77c-.2.4-.2.86-.57,1.15-1.38-.09-1.64-1-1.64-2.18,0-.64.09-1.33.06-2a3.57,3.57,0,0,0-.15-1.17,13.94,13.94,0,0,0-.89-4.48,6.35,6.35,0,0,0,.06-.72c0-1.75-.86-3.33-.86-5.2a5.67,5.67,0,0,1,.11-1.15c0-.4.46-.4.72-.6.63.17,1.18.77,1.84.77a1.11,1.11,0,0,0,.63-.2,4.75,4.75,0,0,1,1.24-.17,4.37,4.37,0,0,1,3.5,1.78h.06a2.15,2.15,0,0,1,.43,1.29A6.44,6.44,0,0,1,271.79,36.76Zm-.94-3.45h-.06a1.32,1.32,0,0,0-1.24-.6c-.43,0-.89.09-1.32.06h-.43a1.43,1.43,0,0,0-.72,1.29c0,.92.58,2,.61,2.9a6.22,6.22,0,0,0,2-1.06c.49-.72,1.18-1.38,1.18-2.3Z" />
                    <path d="M280.87,35.12a2.79,2.79,0,0,0-1.55-.54,2,2,0,0,0-.66.11c-2.33,1-1.47,3.85-1.84,6-.34.14-.69.57-1.06.57a.59.59,0,0,1-.43-.2c-1.7-2.56-2-5.69-2.44-8.82V32a.75.75,0,0,1,.43-.77,1.34,1.34,0,0,1,.46-.09c.86,0,1.06,1,1.4,1.67a.8.8,0,0,1,0,.28c0,.21,0,.43,0,.64a.82.82,0,0,0,.29.71,9.64,9.64,0,0,1,3.33-1.92,3,3,0,0,0,.52,0,2.32,2.32,0,0,1,2.24,1l0,.06v.26A1.26,1.26,0,0,1,280.87,35.12Z" />
                    <path d="M290.66,37.08l0,0A8.76,8.76,0,0,1,288,40.81a3.89,3.89,0,0,1-2,.54,3.08,3.08,0,0,1-1.58-.43c-.77-.77-2-.86-2.47-2a13.53,13.53,0,0,1-.17-2,4,4,0,0,1,.34-1.81c1.24-1,2.47-2.7,4.17-2.81a2.68,2.68,0,0,1,.66-.09,3.5,3.5,0,0,1,2.55,1.29,4,4,0,0,1,1.24,2.73A2.59,2.59,0,0,1,290.66,37.08Zm-2.15-.92a3,3,0,0,0-.06-.52,1.81,1.81,0,0,0-1.38-1.12,1.35,1.35,0,0,0-.34.06,3.71,3.71,0,0,0-2.44,2.06.79.79,0,0,0-.17.52,1.4,1.4,0,0,0,.51,1.09,1.3,1.3,0,0,0,1.12.69,4,4,0,0,0,1.67-.66,3.14,3.14,0,0,0,1.09-2.09Z" />
                    <path d="M300.57,40.09a1,1,0,0,1-.43.78c-.34.11-.57.46-.95.46a.36.36,0,0,1-.17,0,4.09,4.09,0,0,1-1.15-1.44,5.33,5.33,0,0,1-4.33,2.27,2.94,2.94,0,0,1-2.9-4v-.17a7.35,7.35,0,0,1,4.19-6.75.94.94,0,0,1,.6-.12c.4,0,.81.12,1.27.12.08,0,.4.2.51.17l0-8a1.08,1.08,0,0,1,.83-.46,1.88,1.88,0,0,1,1.41.89c0,.74,0,1.52,0,2.27a97.68,97.68,0,0,0,.2,11.71s.2.41.2.43C299.85,38.88,300.54,39.52,300.57,40.09Zm-3.44-5.77a1.45,1.45,0,0,0-1.27-.78,2,2,0,0,0-1.63,1,4.1,4.1,0,0,0-1,3,8.09,8.09,0,0,0,.35,2.3.56.56,0,0,0,.4.17,1.38,1.38,0,0,0,.74-.26c1.3-1.12,2.56-2.56,2.59-4.31A5.07,5.07,0,0,0,297.13,34.32Z" />
                    <path d="M308.39,40.61a.38.38,0,0,1-.18,0c-.69,0-1.12-.83-1.52-1.29-.77.57-1.23,1.66-2.15,2.15a1.06,1.06,0,0,1-.43,0c-1.07,0-1.76-1.06-2.39-1.75a13.19,13.19,0,0,1-.69-4.51,24.1,24.1,0,0,1,.15-2.56.89.89,0,0,1,.74-.31,1.81,1.81,0,0,1,.72.14l.58.75.08,4.62c.26.32.09,1,.63,1a.18.18,0,0,0,.12,0,12.22,12.22,0,0,0,1.89-4.6,1.24,1.24,0,0,1,.95-.57,1,1,0,0,1,.49.14c1.44,1.44.09,4.28,1.75,5.8A1.18,1.18,0,0,1,308.39,40.61Z" />
                    <path d="M314.47,41a16,16,0,0,1-2.67.43,2.54,2.54,0,0,1-2.24-1.09,4.14,4.14,0,0,1-.63-2.3c0-1.92,1.09-4,1.81-5.6.49-1.15,1.84-1.81,1.84-3.24a1.43,1.43,0,0,1,.6-.12c1.09,0,2,1.27,2.36,2.13a4.6,4.6,0,0,1-1,3,.9.9,0,0,1-.54.43c-.46,0-.83-.69-1.24-.72-.17,0-.34.14-.54.54a12.25,12.25,0,0,0-.81,4.54,1.3,1.3,0,0,0,1.12.57,2.58,2.58,0,0,0,.46,0c1.09-.26,2.07-1.64,3.08-1.67.4,0,.8.23,1.23.84C317.17,40.21,315.37,40.23,314.47,41Z" />
                    <path d="M324.24,42.33a1.14,1.14,0,0,0-.46-.08c-.54,0-1,.37-1.46.37a.89.89,0,0,1-.69-.35c-1.87-.94-2.3-3-2.3-5v-.18a1.34,1.34,0,0,1,0-.49c0-.37,0-.71,0-1.06a2.09,2.09,0,0,0-.32-1.2c-.43,0-1,.17-1.55.17a1.25,1.25,0,0,1-1.2-.6.72.72,0,0,1-.12-.41,3,3,0,0,1,.26-.77h.11c.81,0,1.38-.49,2.16-.52a1.1,1.1,0,0,1,.49.12,4,4,0,0,0,.43-2.27V28.89a6.24,6.24,0,0,1,.14-1.46c.32-1.32-.06-3.1.83-4.05a1,1,0,0,1,.41,0,1.21,1.21,0,0,1,1.23,1.06c-.69,2.27-.49,4.91-.78,7.35a1.92,1.92,0,0,0,1.1.29c.57,0,1.17-.17,1.75-.14a2.05,2.05,0,0,1,1.12.28.62.62,0,0,1,.2.55,3.87,3.87,0,0,1-.37,1,4.59,4.59,0,0,1-1.3.15c-.31,0-.63,0-.95,0a3.57,3.57,0,0,0-1.63.31,8.43,8.43,0,0,1,0,.87c0,.71-.06,1.46-.06,2.18,0,1.38.2,2.67,1.32,3.44a4.64,4.64,0,0,0,3.13-1.89,1,1,0,0,1,.29,0c.46,0,.92.08,1.06.57v0A4.81,4.81,0,0,1,324.24,42.33Z" />
                    <path d="M331.74,40.18a3.66,3.66,0,0,1-2,.54,8.72,8.72,0,0,1-2.73-.54c-.26-.38-.89-.64-.89-1.09a.38.38,0,0,1,0-.18,1.2,1.2,0,0,1,1-.48c.72,0,1.52.45,2.21.48.43,0,.81-.2,1.07-.89a20,20,0,0,1-2.56-2,4.33,4.33,0,0,1-1.32-2.32,2.79,2.79,0,0,1,1.58-1.78,6.5,6.5,0,0,1,2.3-.55,2,2,0,0,1,1.49.69,5.63,5.63,0,0,1,.11.75.76.76,0,0,1-.14.46.84.84,0,0,1-.89.54c-.4,0-.89-.2-1.24-.2s-.6.4-.6,1.12A22.72,22.72,0,0,1,332,36.85a3.13,3.13,0,0,1,.74,1.32l0,0h0A2.56,2.56,0,0,1,331.74,40.18Z" />
                    <path d="M46.38,62.49a8.12,8.12,0,0,1-2.69,2.1c-.35,0-.69,0-1,0-1.41,0-2.73-.29-3.47-1.58-.52-5-.15-10.89-.75-15.91V47a.75.75,0,0,1,.8-.84A1.69,1.69,0,0,1,41,47.76a2.28,2.28,0,0,1-.2,1.09c.2.95.06,3.68.23,4.65.12.69,0,1.38.2,2,.72-.11,1.3-.71,2-.86h.06A4,4,0,0,1,47,56.81l0,0a5.05,5.05,0,0,1,.4,2A6.9,6.9,0,0,1,46.38,62.49Zm-1.23-4.08h0c-.2-.69-.66-1.46-1.46-1.46a.74.74,0,0,0-.26,0A4.62,4.62,0,0,0,41.79,61c0,.31,0,.66,0,1a.61.61,0,0,0,.66.37c.4,0,.86-.25,1.29-.25,0,0,.06,0,.06,0a5.85,5.85,0,0,0,1.32-3.68Z" />
                    <path d="M55.83,60.37a17.5,17.5,0,0,1-.28,3.24,1.41,1.41,0,0,1-1.3.72,2.31,2.31,0,0,1-.74-.14,7.55,7.55,0,0,1-2.87.86,2.52,2.52,0,0,1-1.44-.43c-.75-.18-1.15-1-1.61-1.61a4.17,4.17,0,0,1-.14-1,3.36,3.36,0,0,1,1-2.44,4.16,4.16,0,0,1,3-1.46h.17a.11.11,0,0,1,.09,0c.51,0,.8.49,1.26.49a1.77,1.77,0,0,0,.37-.06,2.66,2.66,0,0,0,.18-1,4.73,4.73,0,0,0-1.21-2.67.34.34,0,0,0-.17,0c-.69,0-1.49.75-2.07.75-.32,0-.57-.2-.72-.86.06-1.15,1.09-1.55,2-2,1.29.06,2.7.18,3.31,1.53a14.91,14.91,0,0,1,1.17,6Zm-3.36.69v-.2c-.17-.41-.4-.55-.68-.55-.55,0-1.21.69-1.61,1,0,.29-.29.61-.29.89a.44.44,0,0,0,.29.41,3.28,3.28,0,0,0,.94.17,1.4,1.4,0,0,0,1-.32.65.65,0,0,0,.46-.69C52.53,61.57,52.47,61.31,52.47,61.06Z" />
                    <path d="M61.84,63.24a3.66,3.66,0,0,1-2,.54,8.44,8.44,0,0,1-2.73-.54c-.26-.37-.89-.63-.89-1.09a.32.32,0,0,1,0-.17,1.18,1.18,0,0,1,1-.49c.72,0,1.52.46,2.21.49.43,0,.81-.21,1.07-.9a20,20,0,0,1-2.56-2,4.29,4.29,0,0,1-1.32-2.32A2.79,2.79,0,0,1,58.22,55a6.5,6.5,0,0,1,2.3-.55,2,2,0,0,1,1.49.69,5.63,5.63,0,0,1,.11.75.74.74,0,0,1-.14.46.84.84,0,0,1-.89.54c-.4,0-.89-.2-1.23-.2s-.61.4-.61,1.12a23.74,23.74,0,0,1,2.85,2.13,3.13,3.13,0,0,1,.74,1.32l0,0h0A2.53,2.53,0,0,1,61.84,63.24Z" />
                    <path d="M69.05,64.79a2.67,2.67,0,0,1-1.21.26A11.72,11.72,0,0,1,65,64.33a4.13,4.13,0,0,1-1.9-3.93,14.57,14.57,0,0,1,.15-2,10.68,10.68,0,0,1,1.49-3.1c.75-1,1.89-1,3-1a3.13,3.13,0,0,1,.66.08c.78.46,1.87,1.07,2.16,2.13,0,.09.2.83.2.92a2.38,2.38,0,0,1-1.49,2,28.23,28.23,0,0,1-4.28.83,1.75,1.75,0,0,0,.28,1.44,3.64,3.64,0,0,0,2.45,1.17,1.88,1.88,0,0,0,1.6-.6.8.8,0,0,1,.26,0,1.37,1.37,0,0,1,1,.6,1.27,1.27,0,0,1,0,.32C70.63,64,69.53,64.36,69.05,64.79Zm-.72-7.15c.4-.43-.12-1-.49-1s-1.18,0-1.49.48a4.75,4.75,0,0,0-.61,1.15A6.1,6.1,0,0,0,68.33,57.64Z" />
                    <path d="M81.22,63.15a1,1,0,0,1-.43.78c-.34.11-.57.46-.94.46a.38.38,0,0,1-.18,0,4.09,4.09,0,0,1-1.15-1.44,5.33,5.33,0,0,1-4.33,2.27,4.22,4.22,0,0,1-.61,0,4.12,4.12,0,0,1-2.29-4V61a7.36,7.36,0,0,1,4.19-6.75,1,1,0,0,1,.6-.11c.41,0,.81.11,1.27.11.08,0,.4.2.51.17l0-8a1.08,1.08,0,0,1,.84-.46,1.88,1.88,0,0,1,1.4.89c0,.75,0,1.52,0,2.27a97.9,97.9,0,0,0,.2,11.72s.21.4.21.43C80.51,62,81.2,62.58,81.22,63.15Zm-3.44-5.77a1.44,1.44,0,0,0-1.27-.77,1.94,1.94,0,0,0-1.63.94,4.1,4.1,0,0,0-1,3,8.45,8.45,0,0,0,.35,2.3.58.58,0,0,0,.4.17,1.41,1.41,0,0,0,.75-.26c1.29-1.12,2.55-2.56,2.58-4.31A5.07,5.07,0,0,0,77.78,57.38Z" />
                    <path d="M97.37,60.14l0,0a8.7,8.7,0,0,1-2.67,3.76,3.9,3.9,0,0,1-2,.55A3.21,3.21,0,0,1,91.16,64c-.77-.78-2-.87-2.47-2a15.37,15.37,0,0,1-.17-2,4,4,0,0,1,.35-1.81c1.23-1,2.46-2.69,4.16-2.81a2.68,2.68,0,0,1,.66-.09,3.49,3.49,0,0,1,2.56,1.3,4,4,0,0,1,1.23,2.72A2.89,2.89,0,0,1,97.37,60.14Zm-2.16-.92a3.07,3.07,0,0,0-.06-.52,1.8,1.8,0,0,0-1.37-1.12,1.43,1.43,0,0,0-.35.06A3.75,3.75,0,0,0,91,59.71a.8.8,0,0,0-.17.51,1.44,1.44,0,0,0,.51,1.09,1.3,1.3,0,0,0,1.13.69,4,4,0,0,0,1.66-.66,3.1,3.1,0,0,0,1.09-2.09Z" />
                    <path d="M104.75,64.36c-1.32-.17-1.5-1.12-1.5-2.21,0-.4,0-.84,0-1.27,0-1-.14-1.92-1.15-2.29-1.26,1.43-1.72,3.47-2.29,5.34a1.24,1.24,0,0,1-1,.57,1,1,0,0,1-.49-.14,1.71,1.71,0,0,1-.77-1.49c0-.69.25-1.47.28-2.19a3,3,0,0,0-.08-.66v-.08c0-1.47-.61-2.85-.61-4.14a.15.15,0,0,0,0-.09,1.3,1.3,0,0,1,.86-.31,1.26,1.26,0,0,1,1.15.69c.22.46.17,1.06.63,1.46a2.82,2.82,0,0,1,2.27-1.29h.17c2.3.46,2.64,3,3.21,4.91l0,0a3.09,3.09,0,0,0-.06.55c0,.46.08,1,.08,1.43A1.15,1.15,0,0,1,104.75,64.36Z" />
                    <path d="M120.23,58.18a2.79,2.79,0,0,0-1.55-.54,2,2,0,0,0-.66.11c-2.33,1-1.47,3.85-1.84,6-.35.14-.69.57-1.06.57a.59.59,0,0,1-.43-.2c-1.7-2.55-2-5.68-2.44-8.82v-.2a.76.76,0,0,1,.43-.77,1.34,1.34,0,0,1,.46-.09c.86,0,1.06,1,1.4,1.67a.83.83,0,0,1,0,.29c0,.2,0,.43,0,.63a.85.85,0,0,0,.29.72,9.52,9.52,0,0,1,3.33-1.93,3,3,0,0,0,.52,0,2.31,2.31,0,0,1,2.24,1l0,.05v.26A1.26,1.26,0,0,1,120.23,58.18Z" />
                    <path d="M126.63,64.79a2.61,2.61,0,0,1-1.2.26,11.8,11.8,0,0,1-2.85-.72,4.14,4.14,0,0,1-1.89-3.93,16.35,16.35,0,0,1,.14-2,10.71,10.71,0,0,1,1.5-3.1c.74-1,1.89-1,3-1a3.2,3.2,0,0,1,.66.08c.78.46,1.87,1.07,2.16,2.13,0,.09.2.83.2.92a2.37,2.37,0,0,1-1.5,2,28,28,0,0,1-4.28.83,1.75,1.75,0,0,0,.29,1.44,3.64,3.64,0,0,0,2.44,1.17,1.89,1.89,0,0,0,1.61-.6.77.77,0,0,1,.26,0,1.33,1.33,0,0,1,1,.6,1.27,1.27,0,0,1,0,.32C128.21,64,127.12,64.36,126.63,64.79Zm-.71-7.15c.4-.43-.12-1-.49-1s-1.18,0-1.5.48a5,5,0,0,0-.6,1.15A6.16,6.16,0,0,0,125.92,57.64Z" />
                    <path d="M136.28,64.36c-1.32-.17-1.49-1.12-1.49-2.21,0-.4,0-.84,0-1.27,0-1-.14-1.92-1.15-2.29-1.26,1.43-1.72,3.47-2.3,5.34a1.22,1.22,0,0,1-.94.57,1,1,0,0,1-.49-.14,1.7,1.7,0,0,1-.78-1.49c0-.69.26-1.47.29-2.19a2.5,2.5,0,0,0-.09-.66v-.08c0-1.47-.6-2.85-.6-4.14a.11.11,0,0,0,0-.09,1.33,1.33,0,0,1,.86-.31,1.26,1.26,0,0,1,1.15.69c.23.46.17,1.06.63,1.46a2.83,2.83,0,0,1,2.27-1.29h.17c2.3.46,2.65,3,3.22,4.91l0,0a3.09,3.09,0,0,0-.06.55c0,.46.09,1,.09,1.43A1.15,1.15,0,0,1,136.28,64.36Z" />
                    <path d="M143.64,64.85a3,3,0,0,1-1.3.26,13.27,13.27,0,0,1-3-.75,4.32,4.32,0,0,1-2-4.11,16.52,16.52,0,0,1,.4-2.12,6.38,6.38,0,0,1,1.35-2.36,4.46,4.46,0,0,1,3.19-1.86A2.87,2.87,0,0,1,143,54c.83.46,2,.69,2.32,1.78,0,.09,0,.72,0,.84,0,.94-.31,1.09-.89,1.57-1.18.92-3.62,1.35-4.91,2a4.34,4.34,0,0,0-.06.64,2.31,2.31,0,0,0,1.35,2,3.75,3.75,0,0,0,1.7.28c.66,0,1-.34,1.43-.89a1,1,0,0,1,.29,0,1.37,1.37,0,0,1,1.06.64,1.16,1.16,0,0,1,0,.31C145.3,64,144.15,64.39,143.64,64.85ZM142.17,57a.36.36,0,0,0,0-.63c-.08,0-.31-.12-.66.23l-.86,1A3.81,3.81,0,0,0,142.17,57Z" />
                    <path d="M156.5,62.52c-.08.69-.31,1.55-1.12,1.69h-.17a1.72,1.72,0,0,1-1.12-.51,13.73,13.73,0,0,1-2-3.07c-.63.77-.32,3.07-1.18,3.76a1.71,1.71,0,0,1-1.49-.86,18.8,18.8,0,0,1-1.15-2.47c-.49-1.73-2.32-3.82-2.32-5.49v-.11a1.22,1.22,0,0,1,.94-.41,1.86,1.86,0,0,1,1.64,1.18c.29.72.8,2.76,1.38,3.36.63-.86.43-3.13,1.66-3.59a1.73,1.73,0,0,1,1.18.75c.86,1.23.63,3.56,1.9,4.45l.28-.32c.38-1.43.32-4.22,1.24-5.34h.49c.51,0,1.06.09,1.06.78h0C157.42,58,156.93,60.8,156.5,62.52Z" />
                    <path d="M165.29,60.37a17.5,17.5,0,0,1-.28,3.24,1.41,1.41,0,0,1-1.3.72,2.31,2.31,0,0,1-.74-.14,7.54,7.54,0,0,1-2.88.86,2.51,2.51,0,0,1-1.43-.43c-.75-.18-1.15-1-1.61-1.61a3.73,3.73,0,0,1-.14-1,3.36,3.36,0,0,1,1-2.44,4.16,4.16,0,0,1,3-1.46h.17a.11.11,0,0,1,.09,0c.51,0,.8.49,1.26.49a1.88,1.88,0,0,0,.37-.06,2.66,2.66,0,0,0,.18-1,4.73,4.73,0,0,0-1.21-2.67.36.36,0,0,0-.17,0c-.69,0-1.5.75-2.07.75-.32,0-.57-.2-.72-.86.06-1.15,1.09-1.55,2-2,1.29.06,2.7.18,3.3,1.53a14.75,14.75,0,0,1,1.18,6Zm-3.36.69v-.2c-.17-.41-.4-.55-.69-.55-.54,0-1.2.69-1.6,1,0,.29-.29.61-.29.89a.44.44,0,0,0,.29.41,3.22,3.22,0,0,0,.94.17,1.4,1.4,0,0,0,.95-.32.66.66,0,0,0,.46-.69C162,61.57,161.93,61.31,161.93,61.06Z" />
                    <path d="M173.91,62.49a8.15,8.15,0,0,1-2.7,2.1c-.34,0-.69,0-1,0-1.41,0-2.73-.29-3.48-1.58-.52-5-.14-10.89-.75-15.91V47a.75.75,0,0,1,.81-.84,1.69,1.69,0,0,1,1.78,1.64,2.4,2.4,0,0,1-.2,1.09c.2.95.06,3.68.23,4.65.11.69,0,1.38.2,2,.72-.11,1.29-.71,2-.86h.06a4,4,0,0,1,3.67,2.16l0,0a4.89,4.89,0,0,1,.4,2A7,7,0,0,1,173.91,62.49Zm-1.24-4.08h0c-.21-.69-.67-1.46-1.47-1.46a.77.77,0,0,0-.26,0A4.64,4.64,0,0,0,169.31,61c0,.31,0,.66,0,1a.62.62,0,0,0,.66.37c.41,0,.87-.25,1.3-.25,0,0,0,0,0,0a5.8,5.8,0,0,0,1.32-3.68Z" />
                    <path d="M177.62,64.82a.22.22,0,0,1-.15,0,1.83,1.83,0,0,1-1.41-1c0-.77,0-1.55,0-2.32,0-2.24.05-5,.05-7.27a61.94,61.94,0,0,0-.31-6.92v-.17c0-.38.11-.75.4-.86h.09a2.12,2.12,0,0,1,1.66,1.2,29,29,0,0,1,.29,4.45c0,2.24-.15,5-.15,7.3a28.78,28.78,0,0,0,.29,4.42C178.3,64.13,177.9,64.42,177.62,64.82Z" />
                    <path d="M185.51,64.79a2.61,2.61,0,0,1-1.2.26,11.8,11.8,0,0,1-2.85-.72,4.14,4.14,0,0,1-1.89-3.93,16.35,16.35,0,0,1,.14-2,10.71,10.71,0,0,1,1.5-3.1c.74-1,1.89-1,3-1a3.2,3.2,0,0,1,.66.08c.78.46,1.87,1.07,2.15,2.13,0,.09.21.83.21.92a2.37,2.37,0,0,1-1.5,2,28,28,0,0,1-4.28.83,1.75,1.75,0,0,0,.29,1.44,3.64,3.64,0,0,0,2.44,1.17,1.9,1.9,0,0,0,1.61-.6.77.77,0,0,1,.26,0,1.35,1.35,0,0,1,1,.6,1.27,1.27,0,0,1,0,.32C187.09,64,186,64.36,185.51,64.79Zm-.72-7.15c.41-.43-.11-1-.48-1s-1.18,0-1.5.48a5,5,0,0,0-.6,1.15A6.09,6.09,0,0,0,184.79,57.64Z" />
                    <path d="M196.14,53.19c-.57-.2-1.38-.09-1.55-.89a1.47,1.47,0,0,1-.06-.32,1.12,1.12,0,0,1,.75-1.09c.63.26,1.55.37,1.61,1.29C196.83,52.58,196.43,52.9,196.14,53.19Zm.37,11.4a.76.76,0,0,1-.4-.12l-.72-.71-.28-7.24a1.16,1.16,0,0,1,1.12-.72,1.11,1.11,0,0,1,.92.46,10.51,10.51,0,0,1,.45,3.36c0,.57,0,1.18,0,1.78a13.46,13.46,0,0,0,.14,2.21h0C197.49,64,197,64.59,196.51,64.59Z" />
                    <path d="M206.11,64.36c-1.33-.17-1.5-1.12-1.5-2.21,0-.4,0-.84,0-1.27,0-1-.14-1.92-1.15-2.29-1.26,1.43-1.72,3.47-2.29,5.34a1.25,1.25,0,0,1-.95.57,1,1,0,0,1-.49-.14,1.7,1.7,0,0,1-.78-1.49c0-.69.26-1.47.29-2.19a2.5,2.5,0,0,0-.09-.66v-.08c0-1.47-.6-2.85-.6-4.14a.11.11,0,0,0,0-.09,1.33,1.33,0,0,1,.86-.31,1.26,1.26,0,0,1,1.15.69c.23.46.18,1.06.64,1.46a2.8,2.8,0,0,1,2.26-1.29h.18c2.29.46,2.64,3,3.21,4.91l0,0a3.12,3.12,0,0,0,0,.55c0,.46.08,1,.08,1.43A1.15,1.15,0,0,1,206.11,64.36Z" />
                    <path d="M215.9,66.05c-.4,2.13-1.18,4.8-3.62,5.4a2.46,2.46,0,0,1-.92.09,4.36,4.36,0,0,1-3.41-2,.77.77,0,0,1,0-.26,2.12,2.12,0,0,1,.6-1.32c1.26,0,1.46,1.63,2.73,1.61a1.75,1.75,0,0,0,1.72-1,10,10,0,0,0,.66-3.59,7,7,0,0,0-.77-3.39c-.92.55-2,1.5-3.11,1.52a1.84,1.84,0,0,1-1.23-.51,3.58,3.58,0,0,1-1.35-3,4.65,4.65,0,0,1,0-.63c.23-2.18,1.06-4.57,3.3-5.34,1,.37,2.1.34,2.73,1.43v.06c0,.75.6,1.29.89,2C214.49,60.17,215.73,62.89,215.9,66.05Zm-5.08-10a5.44,5.44,0,0,0-1.3,3.39,4,4,0,0,0,.32,1.58A3.5,3.5,0,0,0,212,58.39l0,0C211.65,57.58,211.62,56.55,210.82,56.06Z" />
                    <path d="M223.22,58.18a2.77,2.77,0,0,0-1.55-.54,2,2,0,0,0-.66.11c-2.32,1-1.46,3.85-1.84,6-.34.14-.68.57-1.06.57a.63.63,0,0,1-.43-.2c-1.69-2.55-2-5.68-2.44-8.82v-.2a.76.76,0,0,1,.43-.77,1.38,1.38,0,0,1,.46-.09c.86,0,1.06,1,1.41,1.67a.83.83,0,0,1,0,.29c0,.2,0,.43,0,.63a.82.82,0,0,0,.29.72,9.43,9.43,0,0,1,3.33-1.93,2.91,2.91,0,0,0,.51,0,2.31,2.31,0,0,1,2.24,1l0,.05v.26A1.26,1.26,0,0,1,223.22,58.18Z" />
                    <path d="M229.63,64.79a2.62,2.62,0,0,1-1.21.26,11.84,11.84,0,0,1-2.84-.72,4.15,4.15,0,0,1-1.9-3.93,14.57,14.57,0,0,1,.15-2,10.94,10.94,0,0,1,1.49-3.1c.75-1,1.9-1,3-1a3.2,3.2,0,0,1,.66.08c.77.46,1.86,1.07,2.15,2.13,0,.09.2.83.2.92a2.36,2.36,0,0,1-1.49,2,28.23,28.23,0,0,1-4.28.83,1.75,1.75,0,0,0,.29,1.44,3.61,3.61,0,0,0,2.44,1.17,1.9,1.9,0,0,0,1.61-.6.72.72,0,0,1,.25,0,1.35,1.35,0,0,1,1,.6,1.27,1.27,0,0,1,0,.32C231.21,64,230.12,64.36,229.63,64.79Zm-.72-7.15c.4-.43-.11-1-.49-1s-1.17,0-1.49.48a4.56,4.56,0,0,0-.6,1.15A6.09,6.09,0,0,0,228.91,57.64Z" />
                    <path d="M241.81,63.15a1,1,0,0,1-.43.78c-.35.11-.58.46-.95.46a.32.32,0,0,1-.17,0,4,4,0,0,1-1.15-1.44,5.35,5.35,0,0,1-4.34,2.27,4,4,0,0,1-.6,0,4.11,4.11,0,0,1-2.3-4V61a7.36,7.36,0,0,1,4.19-6.75,1,1,0,0,1,.61-.11c.4,0,.8.11,1.26.11.09,0,.4.2.52.17l0-8a1.06,1.06,0,0,1,.83-.46,1.87,1.87,0,0,1,1.4.89c0,.75,0,1.52,0,2.27a93.88,93.88,0,0,0,.2,11.72s.2.4.2.43C241.09,62,241.78,62.58,241.81,63.15Zm-3.45-5.77a1.44,1.44,0,0,0-1.26-.77,2,2,0,0,0-1.64.94,4.09,4.09,0,0,0-1,3,8.46,8.46,0,0,0,.34,2.3.6.6,0,0,0,.4.17,1.41,1.41,0,0,0,.75-.26c1.29-1.12,2.56-2.56,2.58-4.31A4.48,4.48,0,0,0,238.36,57.38Z" />
                    <path d="M243.93,53.19c-.57-.2-1.38-.09-1.55-.89a1.39,1.39,0,0,1,0-.32,1.11,1.11,0,0,1,.74-1.09c.63.26,1.55.37,1.61,1.29C244.62,52.58,244.22,52.9,243.93,53.19Zm.38,11.4a.79.79,0,0,1-.41-.12l-.71-.71-.29-7.24A1.16,1.16,0,0,1,244,55.8a1.11,1.11,0,0,1,.92.46,10.79,10.79,0,0,1,.46,3.36c0,.57,0,1.18,0,1.78a14.66,14.66,0,0,0,.14,2.21h0C245.28,64,244.82,64.59,244.31,64.59Z" />
                    <path d="M252.21,64.79a2.67,2.67,0,0,1-1.21.26,11.72,11.72,0,0,1-2.84-.72,4.13,4.13,0,0,1-1.9-3.93,16.35,16.35,0,0,1,.14-2,11,11,0,0,1,1.5-3.1c.74-1,1.89-1,3-1a3.2,3.2,0,0,1,.66.08c.78.46,1.87,1.07,2.16,2.13,0,.09.2.83.2.92a2.37,2.37,0,0,1-1.5,2,28.15,28.15,0,0,1-4.27.83,1.75,1.75,0,0,0,.28,1.44,3.64,3.64,0,0,0,2.44,1.17,1.89,1.89,0,0,0,1.61-.6.77.77,0,0,1,.26,0,1.37,1.37,0,0,1,1,.6,1.89,1.89,0,0,1,0,.32C253.78,64,252.69,64.36,252.21,64.79Zm-.72-7.15c.4-.43-.12-1-.49-1s-1.18,0-1.49.48a4.75,4.75,0,0,0-.61,1.15A6.16,6.16,0,0,0,251.49,57.64Z" />
                    <path d="M261.85,64.36c-1.32-.17-1.49-1.12-1.49-2.21,0-.4,0-.84,0-1.27,0-1-.14-1.92-1.15-2.29-1.26,1.43-1.72,3.47-2.3,5.34a1.22,1.22,0,0,1-.94.57,1,1,0,0,1-.49-.14,1.7,1.7,0,0,1-.78-1.49c0-.69.26-1.47.29-2.19a2.5,2.5,0,0,0-.09-.66v-.08c0-1.47-.6-2.85-.6-4.14a.11.11,0,0,0,0-.09,1.33,1.33,0,0,1,.86-.31,1.26,1.26,0,0,1,1.15.69c.23.46.18,1.06.63,1.46a2.83,2.83,0,0,1,2.27-1.29h.18c2.29.46,2.64,3,3.21,4.91l0,0a3.12,3.12,0,0,0-.05.55c0,.46.08,1,.08,1.43A1.15,1.15,0,0,1,261.85,64.36Z" />
                    <path d="M270.64,65.39a1.11,1.11,0,0,0-.46-.08c-.54,0-1,.37-1.46.37a.89.89,0,0,1-.69-.35c-1.87-.94-2.3-3-2.3-5v-.18a1.29,1.29,0,0,1,0-.48c0-.38,0-.72,0-1.07a2.16,2.16,0,0,0-.31-1.2c-.43,0-1,.17-1.55.17a1.28,1.28,0,0,1-1.21-.6.71.71,0,0,1-.12-.4,3.4,3.4,0,0,1,.26-.78H263c.8,0,1.38-.49,2.15-.52a1,1,0,0,1,.49.12,4,4,0,0,0,.43-2.27V52a6.29,6.29,0,0,1,.14-1.46c.32-1.32-.05-3.1.84-4.05a.93.93,0,0,1,.4,0,1.21,1.21,0,0,1,1.23,1.06c-.69,2.27-.48,4.91-.77,7.35a1.89,1.89,0,0,0,1.09.29c.57,0,1.18-.17,1.75-.14a2.12,2.12,0,0,1,1.12.28.62.62,0,0,1,.2.55,3.75,3.75,0,0,1-.37,1,4.48,4.48,0,0,1-1.29.15c-.32,0-.64,0-.95,0a3.47,3.47,0,0,0-1.64.32,8.14,8.14,0,0,1,0,.86c0,.71-.06,1.46-.06,2.18,0,1.38.2,2.67,1.32,3.45a4.65,4.65,0,0,0,3.13-1.9.92.92,0,0,1,.29,0c.46,0,.92.09,1.06.57v0A4.79,4.79,0,0,1,270.64,65.39Z" />
                    <path d="M278.14,63.24a3.63,3.63,0,0,1-2,.54,8.5,8.5,0,0,1-2.73-.54c-.25-.37-.89-.63-.89-1.09a.32.32,0,0,1,0-.17,1.19,1.19,0,0,1,1-.49c.71,0,1.52.46,2.21.49.43,0,.8-.21,1.06-.9a20.74,20.74,0,0,1-2.56-2,4.35,4.35,0,0,1-1.32-2.32A2.79,2.79,0,0,1,274.52,55a6.5,6.5,0,0,1,2.3-.55,2,2,0,0,1,1.49.69,5.6,5.6,0,0,1,.12.75.76.76,0,0,1-.15.46.83.83,0,0,1-.89.54c-.4,0-.89-.2-1.23-.2s-.6.4-.6,1.12a23.63,23.63,0,0,1,2.84,2.13,3.25,3.25,0,0,1,.75,1.32l0,0h0A2.5,2.5,0,0,1,278.14,63.24Z" />
                    <path d="M293.68,52.1a6.75,6.75,0,0,0-4.25,1.69,1.27,1.27,0,0,0,1.32.23,1.1,1.1,0,0,1,.54,0,.79.79,0,0,1,.87.66,1.46,1.46,0,0,1,0,.37c0,1.56-2.85,1-3.13,2.5.17.72.54.61.83.61.57,0,1.81-.61,2.32-.61s.69.2.78.89a2.28,2.28,0,0,1-.8,1.38c-1.07,0-2.16.55-3.19.55a2.37,2.37,0,0,1-1.35-.41c-.58-.4-.75-1.63-.86-2.24v0c0-.84,1.29-1.53,1.29-2.27,0-.26-.17-.55-.6-.84a1.49,1.49,0,0,1-.09-.48c0-1.15,1.23-2.1,2.15-2.82a13.47,13.47,0,0,1,3-1.38.74.74,0,0,1,.26,0,1.48,1.48,0,0,1,1.15.84v0a.67.67,0,0,1,.11.4A1.68,1.68,0,0,1,293.68,52.1ZM291,66.8v.26a1.36,1.36,0,0,1-1,.4.89.89,0,0,1-.43-.11,2.11,2.11,0,0,1-.69-1.64c0-1.15.58-2.44.58-3.42v-.08a1,1,0,0,1,.89-.55,1.14,1.14,0,0,1,1,.66h-.06a2.78,2.78,0,0,1,.09.72h0C291.44,64.21,291,65.59,291,66.8Zm1.61-18.49a1.68,1.68,0,0,1-.95.4.78.78,0,0,1-.43-.12,2.33,2.33,0,0,1-.69-1.86c0-1.1.37-2.33.43-3.25a1.12,1.12,0,0,1,1.06-.8.9.9,0,0,1,.84.54h0c0,.23,0,.49,0,.75C292.9,45.35,292.67,47,292.59,48.31Z" />
                    <path d="M44.16,87.85a2.62,2.62,0,0,1-1.21.26,11.84,11.84,0,0,1-2.84-.72,4.15,4.15,0,0,1-1.9-3.93,14.3,14.3,0,0,1,.15-2,10.87,10.87,0,0,1,1.49-3.11c.75-1,1.9-1,3-1a2.68,2.68,0,0,1,.66.09c.77.46,1.86,1.06,2.15,2.12,0,.09.2.83.2.92a2.36,2.36,0,0,1-1.49,2,28.23,28.23,0,0,1-4.28.83,1.74,1.74,0,0,0,.29,1.44A3.61,3.61,0,0,0,42.84,86a1.9,1.9,0,0,0,1.61-.6.74.74,0,0,1,.26,0,1.33,1.33,0,0,1,1,.61,1.2,1.2,0,0,1,0,.31C45.74,87.1,44.65,87.42,44.16,87.85Zm-.72-7.15c.4-.43-.11-1-.49-1s-1.17,0-1.49.48a5.18,5.18,0,0,0-.6,1.15A6.09,6.09,0,0,0,43.44,80.7Z" />
                    <path d="M51.66,87.13a15.19,15.19,0,0,1-2.67.43,2.5,2.5,0,0,1-2.24-1.09,4.15,4.15,0,0,1-.64-2.3c0-1.92,1.1-4,1.81-5.59.49-1.15,1.84-1.81,1.84-3.25a1.42,1.42,0,0,1,.6-.11c1.1,0,2,1.26,2.36,2.12a4.6,4.6,0,0,1-1,3,.86.86,0,0,1-.54.43c-.46,0-.83-.69-1.24-.72-.17,0-.34.14-.54.55a12.16,12.16,0,0,0-.81,4.53,1.28,1.28,0,0,0,1.12.58,2.64,2.64,0,0,0,.46-.06C51.22,85.35,52.2,84,53.21,84c.4,0,.8.22,1.23.83C54.36,86.33,52.55,86.36,51.66,87.13Z" />
                    <path d="M63.55,83.2l0,0a8.81,8.81,0,0,1-2.68,3.76,3.8,3.8,0,0,1-2,.55,3.21,3.21,0,0,1-1.58-.43c-.77-.78-2-.86-2.47-2a15.37,15.37,0,0,1-.17-2,3.91,3.91,0,0,1,.35-1.8c1.23-1,2.47-2.7,4.16-2.82a2.64,2.64,0,0,1,.66-.08,3.48,3.48,0,0,1,2.56,1.29,4,4,0,0,1,1.23,2.73A2.87,2.87,0,0,1,63.55,83.2Zm-2.16-.92a3.19,3.19,0,0,0-.06-.52A1.82,1.82,0,0,0,60,80.64a1.43,1.43,0,0,0-.35.06,3.77,3.77,0,0,0-2.44,2.07.81.81,0,0,0-.17.52,1.44,1.44,0,0,0,.52,1.09,1.3,1.3,0,0,0,1.12.69,4.13,4.13,0,0,0,1.66-.66,3.14,3.14,0,0,0,1.09-2.1Z" />
                    <path d="M75.09,82.8a1.89,1.89,0,0,1-1.17.28l-1,0a2.65,2.65,0,0,0-1,.15,30.83,30.83,0,0,1-6.75.72,1.61,1.61,0,0,1-.83-1.35A1.56,1.56,0,0,1,64.5,82a1.24,1.24,0,0,1,.8-.25c.46,0,.92.17,1.41.17a1.3,1.3,0,0,0,.69-.2,26.33,26.33,0,0,0,6.92-1l0,0c.51,0,.91.54,1.17.92v0A1.22,1.22,0,0,1,75.09,82.8Z" />
                    <path d="M86.7,71.4c-.38.31-.75.83-1.18.83a.46.46,0,0,1-.29-.12c-1.41-.63-.06-3.24-1.55-3.24a2.9,2.9,0,0,0-.43.06A11.39,11.39,0,0,0,81.73,75c0,.46,0,.92.06,1.38a3.54,3.54,0,0,0-.18,1,5.61,5.61,0,0,0,.49,2.07h.17c1,0,1.93-1,2.76-1,.4,0,.78.23,1.12.86a1.42,1.42,0,0,1-.89,1.3h-.17a4.62,4.62,0,0,0-2.84,1.17c.22,1.87,1,3.53,1.11,5.55a1.26,1.26,0,0,1-1.06.54,1.38,1.38,0,0,1-.66-.17l-.43-.29a37.72,37.72,0,0,1-1-4.45.8.8,0,0,0-.64-.32c-.54,0-1.2.46-1.86.46a.77.77,0,0,1-.26,0c-.32-.31-1-.37-1-1,.77-1,2.32-1,3.36-1.72V80c0-1.69-.18-3.53-.18-5.31,0-3.33.55-6.49,3.62-8.1a4.05,4.05,0,0,1,3.13,2,5.36,5.36,0,0,1,.46,1.92A3,3,0,0,1,86.7,71.4Z" />
                    <path d="M94.83,81.25a2.73,2.73,0,0,0-1.56-.55,1.81,1.81,0,0,0-.66.12c-2.32,1-1.46,3.84-1.83,5.94-.35.14-.69.57-1.07.57a.59.59,0,0,1-.43-.2c-1.69-2.55-2-5.68-2.44-8.81v-.2a.78.78,0,0,1,.43-.78,1.38,1.38,0,0,1,.46-.09c.86,0,1.06,1,1.41,1.67a.86.86,0,0,1,0,.29c0,.2,0,.43,0,.63a.84.84,0,0,0,.29.72,9.43,9.43,0,0,1,3.33-1.93,2.91,2.91,0,0,0,.51,0,2.31,2.31,0,0,1,2.24,1l0,.06V80A1.29,1.29,0,0,1,94.83,81.25Z" />
                    <path d="M97,76.25c-.58-.2-1.38-.09-1.55-.89a1.47,1.47,0,0,1-.06-.32A1.13,1.13,0,0,1,96.18,74c.63.26,1.55.38,1.6,1.29C97.73,75.65,97.33,76,97,76.25Zm.37,11.4a.84.84,0,0,1-.4-.11l-.72-.72L96,79.58a1.18,1.18,0,0,1,1.12-.72,1.13,1.13,0,0,1,.92.46,10.55,10.55,0,0,1,.46,3.36c0,.58,0,1.18,0,1.78a13.38,13.38,0,0,0,.15,2.21h0C98.39,87.08,97.93,87.65,97.41,87.65Z" />
                    <path d="M105.31,87.85a2.65,2.65,0,0,1-1.21.26,11.84,11.84,0,0,1-2.84-.72,4.15,4.15,0,0,1-1.9-3.93,14.3,14.3,0,0,1,.15-2A10.61,10.61,0,0,1,101,78.37c.75-1,1.9-1,3-1a2.68,2.68,0,0,1,.66.09c.77.46,1.86,1.06,2.15,2.12,0,.09.2.83.2.92a2.36,2.36,0,0,1-1.49,2,28.23,28.23,0,0,1-4.28.83,1.74,1.74,0,0,0,.29,1.44A3.61,3.61,0,0,0,104,86a1.9,1.9,0,0,0,1.61-.6.72.72,0,0,1,.25,0,1.33,1.33,0,0,1,1,.61,1.2,1.2,0,0,1,0,.31C106.89,87.1,105.8,87.42,105.31,87.85Zm-.72-7.15c.4-.43-.11-1-.49-1s-1.17,0-1.49.48a4.74,4.74,0,0,0-.6,1.15A6.09,6.09,0,0,0,104.59,80.7Z" />
                    <path d="M115,87.42c-1.32-.17-1.49-1.12-1.49-2.21,0-.4,0-.83,0-1.26,0-1-.14-1.93-1.14-2.3-1.27,1.43-1.73,3.47-2.3,5.34a1.22,1.22,0,0,1-1,.57,1,1,0,0,1-.49-.14,1.71,1.71,0,0,1-.77-1.49c0-.69.26-1.47.28-2.19a2.48,2.48,0,0,0-.08-.66V83c0-1.47-.6-2.85-.6-4.14a.08.08,0,0,0,0-.08,1.32,1.32,0,0,1,.86-.32,1.26,1.26,0,0,1,1.15.69c.23.46.17,1.06.63,1.46a2.82,2.82,0,0,1,2.27-1.29h.17c2.3.46,2.64,3.05,3.22,4.91l0,0a3.19,3.19,0,0,0-.06.55c0,.46.09,1,.09,1.44A1.15,1.15,0,0,1,115,87.42Z" />
                    <path d="M126.07,86.21a1,1,0,0,1-.43.78c-.34.11-.57.46-.94.46a.38.38,0,0,1-.18,0A4.09,4.09,0,0,1,123.37,86,5.33,5.33,0,0,1,119,88.25a4.22,4.22,0,0,1-.61,0,4.11,4.11,0,0,1-2.29-4v-.17a7.37,7.37,0,0,1,4.19-6.75,1,1,0,0,1,.6-.11c.4,0,.81.11,1.27.11.08,0,.4.2.51.17l0-8a1.08,1.08,0,0,1,.84-.46,1.88,1.88,0,0,1,1.4.89c0,.75,0,1.52,0,2.27a97.9,97.9,0,0,0,.2,11.72s.21.4.21.43C125.36,85,126,85.64,126.07,86.21Zm-3.44-5.77a1.44,1.44,0,0,0-1.27-.77,1.94,1.94,0,0,0-1.63.94,4.1,4.1,0,0,0-1,3,8.39,8.39,0,0,0,.35,2.3.58.58,0,0,0,.4.17,1.33,1.33,0,0,0,.75-.26c1.29-1.12,2.55-2.55,2.58-4.31A5.15,5.15,0,0,0,122.63,80.44Z" />
                    <path d="M129,87.88a.22.22,0,0,1-.15,0,1.81,1.81,0,0,1-1.4-1c0-.78,0-1.56,0-2.33,0-2.24.06-5,.06-7.27a62.08,62.08,0,0,0-.32-6.92v-.17c0-.37.11-.75.4-.86h.09a2.11,2.11,0,0,1,1.66,1.2,29.05,29.05,0,0,1,.29,4.46c0,2.24-.14,5-.14,7.29a28.76,28.76,0,0,0,.28,4.42C129.64,87.19,129.23,87.48,129,87.88Z" />
                    <path d="M137.25,85.24a43.58,43.58,0,0,0-2.33,9.56,1.08,1.08,0,0,1-1.12.75,1.39,1.39,0,0,1-.63-.15c-.46-.23-.37-.69-.63-1,.46-1.84.86-3.71,1.35-5.55.28-1.2.6-2.41,1-3.59-.86-1.26-2-2.38-3-3.58a9.11,9.11,0,0,1-2-3.51c0-.31-.41-.92-.29-1.15.29-.46,1.21-.46,1.23-.46a1.33,1.33,0,0,1,1.18.89c.4.87.55,2.13,1.18,2.62,1,.63,1.18,1.92,2.27,2.32l.31-.31,1.5-5a.89.89,0,0,1,.8-.57,1.31,1.31,0,0,1,1.21,1.17v.12A51,51,0,0,1,137.25,85.24Z" />
                    <path d="M153.16,82.88c-1.12.86-2,2.19-3.48,2.19,0,0-.05,0-.05,0a.69.69,0,0,0-.46.66c0,.37.26.8.26,1.18V87l.43,5.77c-.2.4-.2.86-.58,1.15-1.38-.09-1.63-1-1.63-2.18,0-.63.08-1.32,0-2a3.94,3.94,0,0,0-.14-1.18,14.28,14.28,0,0,0-.89-4.48,6.35,6.35,0,0,0,.06-.72c0-1.75-.86-3.33-.86-5.2A4.9,4.9,0,0,1,146,77c0-.41.46-.41.72-.61.63.18,1.18.78,1.84.78a1.19,1.19,0,0,0,.63-.2,4.33,4.33,0,0,1,4.74,1.61H154a2.08,2.08,0,0,1,.43,1.29A6.43,6.43,0,0,1,153.16,82.88Zm-.95-3.44h-.06a1.28,1.28,0,0,0-1.23-.61c-.43,0-.89.09-1.32.06h-.43a1.43,1.43,0,0,0-.72,1.29c0,.92.57,2,.6,2.9A5.76,5.76,0,0,0,151,82c.49-.72,1.18-1.38,1.18-2.3Z" />
                    <path d="M162.81,83.43a17.51,17.51,0,0,1-.29,3.24,1.38,1.38,0,0,1-1.29.72,2.32,2.32,0,0,1-.75-.14,7.44,7.44,0,0,1-2.87.86,2.49,2.49,0,0,1-1.43-.43c-.75-.17-1.15-1-1.61-1.61a3.72,3.72,0,0,1-.15-1,3.39,3.39,0,0,1,1-2.45,4.15,4.15,0,0,1,3-1.46h.18a.09.09,0,0,1,.08,0c.52,0,.81.49,1.27.49a1.88,1.88,0,0,0,.37-.06,2.65,2.65,0,0,0,.17-1,4.74,4.74,0,0,0-1.2-2.67.38.38,0,0,0-.18,0c-.69,0-1.49.75-2.06.75-.32,0-.58-.2-.72-.86.06-1.15,1.09-1.55,2-2,1.29.05,2.7.17,3.3,1.52a14.92,14.92,0,0,1,1.18,6Zm-3.36.69v-.2c-.17-.41-.4-.55-.69-.55-.55,0-1.21.69-1.61,1,0,.28-.28.6-.28.88a.43.43,0,0,0,.28.41,3.35,3.35,0,0,0,.95.17,1.42,1.42,0,0,0,1-.32.66.66,0,0,0,.46-.69C159.51,84.63,159.45,84.38,159.45,84.12Z" />
                    <path d="M168.87,87.13a15,15,0,0,1-2.67.43A2.51,2.51,0,0,1,164,86.47a4.14,4.14,0,0,1-.63-2.3c0-1.92,1.09-4,1.81-5.59.49-1.15,1.83-1.81,1.83-3.25a1.49,1.49,0,0,1,.61-.11c1.09,0,2,1.26,2.35,2.12a4.59,4.59,0,0,1-1,3,.85.85,0,0,1-.55.43c-.46,0-.83-.69-1.23-.72-.17,0-.35.14-.55.55a12.15,12.15,0,0,0-.8,4.53,1.28,1.28,0,0,0,1.12.58,2.77,2.77,0,0,0,.46-.06c1.09-.26,2.07-1.64,3.07-1.66.4,0,.8.22,1.24.83C171.57,86.33,169.76,86.36,168.87,87.13Z" />
                    <path d="M180.21,86.44a2.28,2.28,0,0,1-1.29.49,2.32,2.32,0,0,1-.77-.14c-1.3-.58-2.24-2-3.54-2.5a2.07,2.07,0,0,0-.17.92c0,.43.06.86.06,1.23a1.15,1.15,0,0,1-1.06,1.32c-1.07,0-1.15-.8-1.15-1.69v-.43a3.09,3.09,0,0,0-.15-1.09c.09-1.84.15-3.73.15-5.6,0-2.5-.09-6.72-.29-9.1.06-.46.58-.46.83-.69a.38.38,0,0,0,.18,0c.6,0,.94.72,1.17,1.17.18,3,0,8.1.35,10.83a31.36,31.36,0,0,0,3.33-4.62,1.14,1.14,0,0,1,.66-.18.89.89,0,0,1,.55.18,1.13,1.13,0,0,1,.66,1,1.66,1.66,0,0,1-.23.75l-3.19,4.31,0,.43c1.23.92,2.38,2,3.87,2.21a1.19,1.19,0,0,1,0,1.26Z" />
                    <path d="M189,83.43a17.51,17.51,0,0,1-.29,3.24,1.4,1.4,0,0,1-1.29.72,2.32,2.32,0,0,1-.75-.14,7.49,7.49,0,0,1-2.87.86,2.52,2.52,0,0,1-1.44-.43c-.74-.17-1.15-1-1.61-1.61a4.16,4.16,0,0,1-.14-1,3.42,3.42,0,0,1,1-2.45,4.19,4.19,0,0,1,3-1.46h.17a.11.11,0,0,1,.09,0c.52,0,.8.49,1.26.49a2,2,0,0,0,.38-.06,2.89,2.89,0,0,0,.17-1A4.76,4.76,0,0,0,185.44,78a.34.34,0,0,0-.17,0c-.69,0-1.49.75-2.07.75-.31,0-.57-.2-.72-.86.06-1.15,1.1-1.55,2-2,1.29.05,2.69.17,3.3,1.52a14.91,14.91,0,0,1,1.17,6Zm-3.36.69v-.2c-.18-.41-.41-.55-.69-.55-.55,0-1.21.69-1.61,1,0,.28-.29.6-.29.88a.44.44,0,0,0,.29.41,3.29,3.29,0,0,0,1,.17,1.38,1.38,0,0,0,.94-.32.65.65,0,0,0,.46-.69C185.64,84.63,185.59,84.38,185.59,84.12Z" />
                    <path d="M198.37,89.11c-.41,2.13-1.18,4.8-3.62,5.4a2.46,2.46,0,0,1-.92.09,4.38,4.38,0,0,1-3.42-2,.77.77,0,0,1,0-.26A2.13,2.13,0,0,1,191,91c1.26,0,1.46,1.64,2.72,1.61a1.75,1.75,0,0,0,1.73-1A10,10,0,0,0,196.1,88a6.84,6.84,0,0,0-.78-3.39c-.92.55-2,1.5-3.1,1.53a1.85,1.85,0,0,1-1.23-.52,3.58,3.58,0,0,1-1.35-3c0-.2,0-.4,0-.63.23-2.18,1.07-4.57,3.31-5.34,1,.37,2.09.34,2.73,1.43v.06c0,.75.6,1.29.88,2C197,83.23,198.19,86,198.37,89.11Zm-5.09-10A5.48,5.48,0,0,0,192,82.51a4,4,0,0,0,.32,1.58,3.52,3.52,0,0,0,2.12-2.64l0,0C194.12,80.64,194.09,79.61,193.28,79.12Z" />
                    <path d="M199.77,76.25c-.57-.2-1.37-.09-1.55-.89a2.3,2.3,0,0,1-.05-.32,1.12,1.12,0,0,1,.74-1.09c.63.26,1.55.38,1.61,1.29C200.46,75.65,200.06,76,199.77,76.25Zm.38,11.4a.81.81,0,0,1-.4-.11l-.72-.72-.29-7.24a1.16,1.16,0,0,1,1.12-.72,1.13,1.13,0,0,1,.92.46,10.84,10.84,0,0,1,.46,3.36c0,.58,0,1.18,0,1.78a14.57,14.57,0,0,0,.14,2.21h0C201.12,87.08,200.67,87.65,200.15,87.65Z" />
                    <path d="M209.74,87.42c-1.32-.17-1.49-1.12-1.49-2.21,0-.4,0-.83,0-1.26,0-1-.15-1.93-1.15-2.3-1.27,1.43-1.73,3.47-2.3,5.34a1.22,1.22,0,0,1-1,.57,1,1,0,0,1-.49-.14,1.71,1.71,0,0,1-.77-1.49c0-.69.26-1.47.29-2.19a2.5,2.5,0,0,0-.09-.66V83c0-1.47-.6-2.85-.6-4.14a.08.08,0,0,0,0-.08,1.32,1.32,0,0,1,.86-.32,1.26,1.26,0,0,1,1.15.69c.23.46.17,1.06.63,1.46a2.82,2.82,0,0,1,2.27-1.29h.17c2.3.46,2.64,3.05,3.22,4.91l0,0a3.19,3.19,0,0,0-.06.55c0,.46.09,1,.09,1.44A1.15,1.15,0,0,1,209.74,87.42Z" />
                    <path d="M219.53,89.11c-.4,2.13-1.17,4.8-3.61,5.4a2.48,2.48,0,0,1-.92.09,4.38,4.38,0,0,1-3.42-2,.77.77,0,0,1,0-.26,2.17,2.17,0,0,1,.6-1.32c1.27,0,1.47,1.64,2.73,1.61a1.73,1.73,0,0,0,1.72-1,9.77,9.77,0,0,0,.66-3.59,6.84,6.84,0,0,0-.77-3.39c-.92.55-2,1.5-3.1,1.53a1.87,1.87,0,0,1-1.24-.52,3.61,3.61,0,0,1-1.35-3,4.65,4.65,0,0,1,0-.63c.23-2.18,1.06-4.57,3.3-5.34,1,.37,2.1.34,2.73,1.43v.06c0,.75.61,1.29.89,2C218.13,83.23,219.36,86,219.53,89.11Zm-5.08-10a5.43,5.43,0,0,0-1.29,3.39,4,4,0,0,0,.31,1.58,3.53,3.53,0,0,0,2.13-2.64l0,0C215.28,80.64,215.25,79.61,214.45,79.12Z" />
                    <path d="M231.48,86.3a3.64,3.64,0,0,1-2,.55,8.56,8.56,0,0,1-2.73-.55c-.25-.37-.88-.63-.88-1.09a.47.47,0,0,1,0-.17,1.2,1.2,0,0,1,1-.49c.72,0,1.52.46,2.21.49.43,0,.8-.2,1.06-.89a22.79,22.79,0,0,1-2.55-2,4.44,4.44,0,0,1-1.33-2.33A2.82,2.82,0,0,1,227.86,78a6.73,6.73,0,0,1,2.3-.55,2,2,0,0,1,1.49.69,5.6,5.6,0,0,1,.12.75.81.81,0,0,1-.14.46.84.84,0,0,1-.89.54c-.41,0-.89-.2-1.24-.2s-.6.41-.6,1.12A23.63,23.63,0,0,1,231.74,83a3.25,3.25,0,0,1,.75,1.32l0,0h0A2.49,2.49,0,0,1,231.48,86.3Z" />
                    <path d="M241.42,83.2l0,0a8.79,8.79,0,0,1-2.67,3.76,3.85,3.85,0,0,1-2,.55,3.18,3.18,0,0,1-1.58-.43c-.78-.78-2-.86-2.47-2a13.79,13.79,0,0,1-.17-2,3.92,3.92,0,0,1,.34-1.8c1.23-1,2.47-2.7,4.16-2.82a2.64,2.64,0,0,1,.66-.08,3.47,3.47,0,0,1,2.56,1.29,4,4,0,0,1,1.23,2.73A2.56,2.56,0,0,1,241.42,83.2Zm-2.15-.92a3.19,3.19,0,0,0-.06-.52,1.85,1.85,0,0,0-1.38-1.12,1.28,1.28,0,0,0-.34.06A3.76,3.76,0,0,0,235,82.77a.81.81,0,0,0-.17.52,1.44,1.44,0,0,0,.52,1.09,1.31,1.31,0,0,0,1.12.69,4.13,4.13,0,0,0,1.66-.66,3.16,3.16,0,0,0,1.1-2.1Z" />
                    <path d="M243.66,87.88c-.06,0-.11,0-.14,0a1.84,1.84,0,0,1-1.41-1c0-.78,0-1.56,0-2.33,0-2.24.06-5,.06-7.27a62.08,62.08,0,0,0-.32-6.92v-.17c0-.37.12-.75.4-.86h.09a2.14,2.14,0,0,1,1.67,1.2,29,29,0,0,1,.28,4.46c0,2.24-.14,5-.14,7.29a28.78,28.78,0,0,0,.29,4.42C244.35,87.19,244,87.48,243.66,87.88Z" />
                    <path d="M252.82,86.73a.34.34,0,0,1-.17,0c-.69,0-1.12-.83-1.52-1.29-.78.57-1.24,1.67-2.16,2.15a1,1,0,0,1-.43,0c-1.06,0-1.75-1.06-2.38-1.75a13,13,0,0,1-.69-4.51c0-.86.06-1.72.14-2.55a.92.92,0,0,1,.75-.32,2,2,0,0,1,.72.14l.57.75.09,4.62c.26.32.09,1,.63,1a.18.18,0,0,0,.12,0,12.25,12.25,0,0,0,1.89-4.59,1.21,1.21,0,0,1,1-.58,1.07,1.07,0,0,1,.49.14c1.43,1.44.08,4.28,1.75,5.81A1.15,1.15,0,0,1,252.82,86.73Z" />
                    <path d="M261.27,88.45a1.39,1.39,0,0,0-.46-.08c-.55,0-1,.37-1.47.37a.88.88,0,0,1-.69-.34c-1.86-1-2.29-3-2.29-5v-.17a1.34,1.34,0,0,1,0-.49c0-.38,0-.72,0-1.06a2.09,2.09,0,0,0-.31-1.21c-.43,0-1,.17-1.55.17a1.28,1.28,0,0,1-1.21-.6.78.78,0,0,1-.11-.4,3,3,0,0,1,.26-.78h.11c.8,0,1.38-.49,2.15-.52a1,1,0,0,1,.49.12,4,4,0,0,0,.43-2.27V75a5.79,5.79,0,0,1,.15-1.46c.31-1.32-.06-3.1.83-4a.94.94,0,0,1,.4,0,1.22,1.22,0,0,1,1.24,1.06c-.69,2.27-.49,4.92-.78,7.36a1.9,1.9,0,0,0,1.09.28c.58,0,1.18-.17,1.75-.14a2,2,0,0,1,1.12.29.57.57,0,0,1,.2.54,3.61,3.61,0,0,1-.37,1A4.86,4.86,0,0,1,261,80c-.32,0-.63,0-1,0a3.49,3.49,0,0,0-1.64.32,8.14,8.14,0,0,1,0,.86c0,.72-.05,1.46-.05,2.18,0,1.38.2,2.67,1.32,3.45a4.66,4.66,0,0,0,3.13-1.9.89.89,0,0,1,.28,0c.46,0,.92.09,1.07.57v0A4.84,4.84,0,0,1,261.27,88.45Z" />
                    <path d="M265.09,76.25c-.58-.2-1.38-.09-1.55-.89a1.47,1.47,0,0,1-.06-.32,1.13,1.13,0,0,1,.75-1.09c.63.26,1.55.38,1.6,1.29C265.78,75.65,265.37,76,265.09,76.25Zm.37,11.4a.84.84,0,0,1-.4-.11l-.72-.72-.29-7.24a1.18,1.18,0,0,1,1.12-.72,1.13,1.13,0,0,1,.92.46,10.55,10.55,0,0,1,.46,3.36c0,.58,0,1.18,0,1.78a13.38,13.38,0,0,0,.15,2.21h0C266.44,87.08,266,87.65,265.46,87.65Z" />
                    <path d="M276.32,83.2l0,0a8.7,8.7,0,0,1-2.67,3.76,3.8,3.8,0,0,1-2,.55,3.21,3.21,0,0,1-1.58-.43c-.77-.78-2-.86-2.47-2a15.37,15.37,0,0,1-.17-2,3.91,3.91,0,0,1,.35-1.8c1.23-1,2.47-2.7,4.16-2.82a2.64,2.64,0,0,1,.66-.08,3.48,3.48,0,0,1,2.56,1.29,4,4,0,0,1,1.23,2.73A2.87,2.87,0,0,1,276.32,83.2Zm-2.16-.92a4.8,4.8,0,0,0-.05-.52,1.85,1.85,0,0,0-1.38-1.12,1.43,1.43,0,0,0-.35.06,3.77,3.77,0,0,0-2.44,2.07.81.81,0,0,0-.17.52,1.44,1.44,0,0,0,.52,1.09,1.3,1.3,0,0,0,1.12.69,4.13,4.13,0,0,0,1.66-.66,3.14,3.14,0,0,0,1.09-2.1Z" />
                    <path d="M283.7,87.42c-1.32-.17-1.5-1.12-1.5-2.21,0-.4,0-.83,0-1.26,0-1-.14-1.93-1.15-2.3-1.26,1.43-1.72,3.47-2.29,5.34a1.24,1.24,0,0,1-1,.57,1,1,0,0,1-.49-.14,1.71,1.71,0,0,1-.77-1.49c0-.69.25-1.47.28-2.19a3,3,0,0,0-.08-.66V83c0-1.47-.61-2.85-.61-4.14a.11.11,0,0,0,0-.08,1.32,1.32,0,0,1,.86-.32,1.26,1.26,0,0,1,1.15.69c.22.46.17,1.06.63,1.46a2.82,2.82,0,0,1,2.27-1.29h.17c2.3.46,2.64,3.05,3.21,4.91l0,0a3.19,3.19,0,0,0-.06.55c0,.46.08,1,.08,1.44A1.15,1.15,0,0,1,283.7,87.42Z" />
                    <path d="M290.07,86.3a3.63,3.63,0,0,1-2,.55,8.45,8.45,0,0,1-2.73-.55c-.26-.37-.89-.63-.89-1.09a.32.32,0,0,1,0-.17,1.17,1.17,0,0,1,1-.49c.72,0,1.52.46,2.21.49.43,0,.81-.2,1.06-.89a22.79,22.79,0,0,1-2.55-2,4.35,4.35,0,0,1-1.32-2.33A2.77,2.77,0,0,1,286.46,78a6.67,6.67,0,0,1,2.29-.55,2,2,0,0,1,1.5.69,5.63,5.63,0,0,1,.11.75.81.81,0,0,1-.14.46.84.84,0,0,1-.89.54c-.4,0-.89-.2-1.24-.2s-.6.41-.6,1.12A22.86,22.86,0,0,1,290.33,83a3,3,0,0,1,.75,1.32l0,0h0A2.53,2.53,0,0,1,290.07,86.3Z" />
                    <path d="M293.09,85.84a1.29,1.29,0,0,1-1.23-.86,2.57,2.57,0,0,1-.15-.69.52.52,0,0,1,.15-.4.74.74,0,0,1,.74-.4,1.39,1.39,0,0,1,.63.14c.21.37.61.32.84.69A1.81,1.81,0,0,1,293.09,85.84Z" />
                </g>
            </g>
        </svg>
    )
}
