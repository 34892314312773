import React from "react"

export default () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256.85 200.8">
            <defs>
                <style></style>
            </defs>
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                    <path d="M92.93,198.2a8.08,8.08,0,0,1-6.58,2.58,7.41,7.41,0,0,1-5.49-2.5c-.67-.67-1.08-1.5-1.75-2.16-.92-1-.67-2.92-2.41-3.33-.84,1.08-1.59,2.16-2.42,3.16-4,5-10.41,4.83-14.15-.42a16,16,0,0,1-2.75-6.66,91.35,91.35,0,0,1-1.75-11.66c-.5-8.41-.67-16.82-.92-25.23a21.33,21.33,0,0,1,.17-4,7.78,7.78,0,0,1,.67-2.42,5.15,5.15,0,0,1,4.74-3.16,4.89,4.89,0,0,1,4.83,3,11.89,11.89,0,0,1,1,3.83c1,7.24,1.92,14.49,2.92,21.73.16,1.17.41,2.33.66,3.5.08.25.33.66.42.66a1.6,1.6,0,0,0,.91-.5c.67-1,1.17-2.08,1.84-3.08a21.43,21.43,0,0,1,2.41-3.16c2.58-2.5,5.66-2.25,8,.41.25.34.42.67.67,1s.5,1.09,1.08.75c.34-.16.5-.83.59-1.33.16-1.5.25-3,.33-4.5a118.87,118.87,0,0,1,4.25-22.31,24.06,24.06,0,0,1,2-4.83,7.76,7.76,0,0,1,6-4.08,7,7,0,0,1,7.83,5.83,8.78,8.78,0,0,1-.5,4.83c-2.75,7.24-3.92,14.9-4.75,22.56-.75,6.5-1.5,13-2.33,19.4A22.46,22.46,0,0,1,96,194.2a21.83,21.83,0,0,1-3,4.25Z" />
                    <path d="M130.57,158.23c-.16,2-.25,4-.33,5.5-.08,5.74-.5,11,.42,16.15.25,1.41.58,2.83.91,4.25a18.7,18.7,0,0,1,.5,2.16,6.21,6.21,0,0,1-2.58,6.08,6.65,6.65,0,0,1-7,.67,8.07,8.07,0,0,1-4.42-5.17c-.58-1.75-.91-3.58-1.41-5.41a1.46,1.46,0,0,0-1.83-1.33,7.09,7.09,0,0,1-1.25.25c-1.75,0-2.08.75-2.42,2.41-.16,1-.16,2-.33,3a21.89,21.89,0,0,1-.67,3.41,4.37,4.37,0,0,1-8.41-.16,18.53,18.53,0,0,1-.5-5.58c.34-4.66.67-9.33,1.42-13.91.83-5,2.08-9.91,3.33-14.82a36,36,0,0,1,3.83-9.66,19.06,19.06,0,0,1,5.08-5.91,9,9,0,0,1,13.49,2.75,19.54,19.54,0,0,1,1.83,5.33,35.7,35.7,0,0,1,.42,9.91ZM115.75,172a15.22,15.22,0,0,0,.92-3.33,18.17,18.17,0,0,0,0-4.17c-.25-2.49-2,1.17-2.17,1.92a20.5,20.5,0,0,0-.33,5,1.6,1.6,0,0,0,.17.91.79.79,0,0,0,.83.34.9.9,0,0,0,.5-.59Z" />
                    <path d="M160.88,172A30.84,30.84,0,0,1,162.13,184a21.12,21.12,0,0,1-1.66,7.41c-2,4.92-5.83,7.75-11.33,8.33a13.5,13.5,0,0,1-12.82-5.25,15.06,15.06,0,0,1-3.08-12.9,10.18,10.18,0,0,1,.92-2.67,3.31,3.31,0,0,1,3.66-1.33,3,3,0,0,1,2.41,2.5c.09.75-.08,1.58,0,2.41a10.23,10.23,0,0,0,.59,3.17c.75,1.83,2.33,3.25,3.08,2.91,1.33-.58,1.5-2,1.75-3.08a17,17,0,0,0-3.42-15.15,26,26,0,0,0-2.66-2.58c-.92-.84-1.92-1.59-2.75-2.5a14.83,14.83,0,0,1-4.25-14.16A20.77,20.77,0,0,1,138,140.91a12.33,12.33,0,0,1,11.24-4,9.47,9.47,0,0,1,8.32,7.91,6.25,6.25,0,0,1-.5,3.92c-1.08,2.33-2.66,3.16-5.33,2.74l-.74-.24c-.75-.25-1.59-.75-2.25.08a3.1,3.1,0,0,0,0,3.75c.83.91,1.83,1.74,2.74,2.58a30.45,30.45,0,0,1,9.25,14.65Z" />
                    <path d="M163.55,188.87c-.33-2.33-.5-4.66-.75-7-.92-6.25-.5-12.49-.17-18.74.34-6.41.84-12.82,1.34-19.15a17.93,17.93,0,0,1,1.08-4.66,6.34,6.34,0,0,1,7-4.08,6.45,6.45,0,0,1,5.42,5.49,32.25,32.25,0,0,1,.33,5.67c0,3.66-.08,7.32-.08,11,0,1.16.08,2.33.16,3.49s.59,1.59,1.58,1.59c1.42,0,2.17-.42,2.25-1.5.25-3.17.42-6.33.67-9.5.17-2.91.33-5.82.66-8.74a9.71,9.71,0,0,1,1.25-3.66,5.44,5.44,0,0,1,9.75.58,14.63,14.63,0,0,1,.83,4.25c.5,12.32.5,24.64.25,37q-.12,5.87-.5,11.74a8.52,8.52,0,0,1-.67,2.91,4.2,4.2,0,0,1-4.16,2.58,4.53,4.53,0,0,1-4.16-2.66,3.11,3.11,0,0,1-.34-.75c-1.41-6.33-3.41-12.57-3.41-19.23,0-.84,0-1.67-.08-2.5-.09-1.25-.59-1.75-1.5-1.75-1.25,0-2.25.58-2.42,1.42a9.34,9.34,0,0,0-.25,2.16c0,3.08.09,6.16.09,9.24a37,37,0,0,1-.34,5.25,7.32,7.32,0,0,1-.91,2.91,6.47,6.47,0,0,1-10.66,1.42,8.6,8.6,0,0,1-2.33-4.66Z" />
                    <path d="M29.39,96.82c-.16,2-.25,3.94-.33,5.42-.08,5.67-.5,10.85.42,16,.25,1.4.58,2.8.92,4.19.16.74.41,1.4.5,2.14a6.08,6.08,0,0,1-2.59,6,6.71,6.71,0,0,1-7,.66,8,8,0,0,1-4.42-5.1c-.58-1.72-.92-3.53-1.42-5.34a1.46,1.46,0,0,0-1.83-1.32,6.15,6.15,0,0,1-1.25.25c-1.75,0-2.08.74-2.42,2.39-.16,1-.16,2-.33,3A21.61,21.61,0,0,1,9,128.39a4.4,4.4,0,0,1-8.42-.17,18.08,18.08,0,0,1-.5-5.51c.34-4.6.67-9.2,1.42-13.73.83-4.93,2.08-9.78,3.33-14.63a35.54,35.54,0,0,1,3.84-9.54A19,19,0,0,1,13.72,79a9,9,0,0,1,13.51,2.71A19.07,19.07,0,0,1,29.06,87a34.81,34.81,0,0,1,.42,9.78ZM14.56,110.38a14.86,14.86,0,0,0,.91-3.29,16.62,16.62,0,0,0,0-4.11c-.25-2.46-2,1.15-2.16,1.89a19.58,19.58,0,0,0-.34,4.94,1.59,1.59,0,0,0,.17.9A.8.8,0,0,0,14,111a.86.86,0,0,0,.5-.58Z" />
                    <path d="M46.9,117.2h-.25c-.25-2.71-.5-5.42-.84-8.13a11,11,0,0,0-.66-2.22,13.59,13.59,0,0,0-.75,2.46c-.17,3.13-.34,6.25-.34,9.37.09,3.87.34,7.73.5,11.6a15,15,0,0,1-.16,3.37A5.38,5.38,0,0,1,40.06,138a5,5,0,0,1-5.33-2.14,8.73,8.73,0,0,1-1.5-3A80.35,80.35,0,0,1,31.81,124a98.36,98.36,0,0,1,.67-26.15,65.15,65.15,0,0,1,1.75-7.07,12.16,12.16,0,0,1,3.66-5.67,11.08,11.08,0,0,1,16,.74A19.39,19.39,0,0,1,57.82,93a26.89,26.89,0,0,0,.83,2.88c.33.82.08,2,1.42,2.38a13.17,13.17,0,0,0,.41-2.46c.17-4,.25-8.06.42-12.09a26.22,26.22,0,0,1,.58-5.1,5.11,5.11,0,0,1,4.84-3.7A5,5,0,0,1,71,79.22a20.59,20.59,0,0,1,.08,2.22c.08,1.4.08,2.8.17,4.19A109.12,109.12,0,0,1,67,121.89a54.45,54.45,0,0,1-2.67,7.24,17,17,0,0,1-3.42,4.93,6.83,6.83,0,0,1-11.08-1.32,18.87,18.87,0,0,1-2.17-6c-.59-3.12-.75-6.33-1.09-9.45Z" />
                    <path d="M72.57,124.77a48.38,48.38,0,0,1-1.33-12.42,194.63,194.63,0,0,1,1.5-26.22,21.15,21.15,0,0,1,.91-4.61,6,6,0,0,1,5.42-4.27,19.09,19.09,0,0,1,15.42,4.44A29,29,0,0,1,104.5,99a38.21,38.21,0,0,1-5.34,27.71A17.61,17.61,0,0,1,87.57,135a11.92,11.92,0,0,1-14.25-7.72A23.84,23.84,0,0,1,72.57,124.77Zm16.92-3.29a30.75,30.75,0,0,0,2.84-20.55c-.84-1.81-1.59-3.7-2.5-5.51a9.65,9.65,0,0,0-3.59-3.7c-.92-.58-1.75-.25-1.83.82-.42,8.06-.75,16.11-1.09,24.17a30.73,30.73,0,0,0,.25,4.69,9.4,9.4,0,0,0,.67,2.46,1.72,1.72,0,0,0,2.25.74,5.8,5.8,0,0,0,2.84-3.12Z" />
                    <path d="M144.67,74.54c5.34,2.13,7.59,6.33,8,11.75a23,23,0,0,1-2.5,11.76c-1,2-2.25,3.78-3.41,5.67-.84,1.4-.84,1.48.33,2.8.5.57,1,1.07,1.5,1.64a18.73,18.73,0,0,1-1.83,24.75,13.07,13.07,0,0,1-20.09-2.06,22.08,22.08,0,0,1-3.59-10.36,61.5,61.5,0,0,1,0-11.75,113.15,113.15,0,0,1,6.51-29.68,20,20,0,0,1,1.91-4.28c1.84-3,4.67-3.94,8-2.63,1.75.66,3.41,1.48,5.16,2.22ZM137.25,117c0,.25.09.49.09.74h-.25c.41,1.4.83,2.8,1.25,4.19a.84.84,0,0,0,1.25.17,5,5,0,0,0,1-5.67c-.58-1.07-1.25-2.64-2.42-2.39-1.5.25-.75,2-.92,3Zm6.59-23.68A15.38,15.38,0,0,0,145.42,87a6.79,6.79,0,0,0-1.75-4.6c-.58-.66-1.5-.58-1.75.16a10.21,10.21,0,0,0-.41,1.48c-.84,4.19-1.67,8.39-2.42,12.58A12,12,0,0,0,139,99c1.26.16,1.59-1,2.09-1.65a32.07,32.07,0,0,0,2.5-4Z" />
                    <path d="M188.77,86.46a33.62,33.62,0,0,1,2.5,8.88,66,66,0,0,1-.08,16.19c-.5,4.94-1.26,10-3.42,14.39s-6,8.39-10.84,9.7c-7.08,1.89-14.75-2.3-18.76-8.3s-5.08-13.49-5.33-20.72c-.25-6.74.17-13.57,2.58-19.81s7.92-14.06,15.26-14c8,.08,15.09,6.91,18.17,13.73ZM175.1,116.3a48.48,48.48,0,0,0,3.25-24.75,2.12,2.12,0,0,0-.09-.74c-.25-1.48-1-6.16-2.83-6.65-2.83-.75-3.83,4.27-4.33,6.41-2.09,8.79-3.26,18.33.16,26.72a2.23,2.23,0,0,0,.84,1.23c1.25.74,2.58-.82,3.08-2.14Z" />
                    <path d="M193.27,124.77a48.42,48.42,0,0,1-1.34-12.42,198,198,0,0,1,1.5-26.22,21.77,21.77,0,0,1,.92-4.61,6,6,0,0,1,5.42-4.27,19.1,19.1,0,0,1,15.42,4.44,29,29,0,0,1,10,17.26,38.16,38.16,0,0,1-5.33,27.71,17.61,17.61,0,0,1-11.59,8.3A11.92,11.92,0,0,1,194,127.24,23.84,23.84,0,0,1,193.27,124.77Zm16.92-3.29A30.74,30.74,0,0,0,213,100.93c-.83-1.81-1.58-3.7-2.5-5.51a9.62,9.62,0,0,0-3.58-3.7c-.92-.58-1.75-.25-1.84.82-.41,8.06-.75,16.11-1.08,24.17a29.5,29.5,0,0,0,.25,4.69,8.68,8.68,0,0,0,.67,2.46,1.72,1.72,0,0,0,2.25.74,5.82,5.82,0,0,0,2.83-3.12Z" />
                    <path d="M227.19,66.89a5.3,5.3,0,0,1,4.67-2.71A5.68,5.68,0,0,1,237.53,68c.58,1.65.83,3.37,1.25,5,.58,2.22,1.08,4.44,1.67,6.66a.93.93,0,0,0,1.66.24l5-7.64a35,35,0,0,1,2-2.88A4.33,4.33,0,0,1,254.2,68,4.39,4.39,0,0,1,256.78,73a13.55,13.55,0,0,1-.41,1.73c-2.34,6.66-4.67,13.32-9.17,19a5.38,5.38,0,0,0-.92,3.7c.17,7,.33,14,.33,21a65.51,65.51,0,0,1-.5,8.14,6.46,6.46,0,0,1-6.08,5.75,6.25,6.25,0,0,1-6.67-4.52,19.61,19.61,0,0,1-.5-3.87c-.58-7.07-.08-14,.42-21,.08-1.15.16-2.3.33-3.46a5.91,5.91,0,0,0-1.83-5.75,14.49,14.49,0,0,1-3.09-5.1c-2.25-5.67-2.83-11.67-2.75-17.76a9.11,9.11,0,0,1,1.25-4.11Z" />
                    <path d="M74.62,49.76h.09c-.09-2-.17-4-.35-6C74.27,42.51,73.75,42,72.7,42s-2.09,0-3.14.09c-1.66.08-2.1.61-2.19,2.35-.08,2.89-.08,5.77-.26,8.65a20.23,20.23,0,0,1-.61,4.54,7.43,7.43,0,0,1-6.37,5.76c-3.15.53-6.29-1.39-7.86-4.45a12,12,0,0,1-1.14-5.76C51,46.79,50.87,40.42,50.87,34c0-6.72.17-13.45.44-20.17a18.47,18.47,0,0,1,1.13-5.76,8.33,8.33,0,0,1,7.42-5.94,7.1,7.1,0,0,1,8,7,20.77,20.77,0,0,1-.26,2.36c-.18,2.71-.44,5.42-.53,8.12s.09,5.42.18,8.12c.09,2.1.61,2.62,2.79,2.71h.79c2.71,0,3.06-.35,3.06-3.14.08-5.94.17-11.88.35-17.82a22.28,22.28,0,0,1,.87-4.89A6.15,6.15,0,0,1,78.9.42,6.05,6.05,0,0,1,87.11,4.7a26,26,0,0,1,.52,4.54q.8,21.75,1.49,43.49a15.84,15.84,0,0,1-.35,4.54,6.67,6.67,0,0,1-6.2,5.15A6.81,6.81,0,0,1,75.76,58a16.83,16.83,0,0,1-.7-3.06c-.26-1.66-.35-3.32-.52-5Z" />
                    <path d="M109.73,53.69c-.18-.7-.35-1.48-.44-1.83-.09-4.46-1.48-3.85-4.37-3.32a2.49,2.49,0,0,0-2.18,1.92c-1,3.23-2.1,6.37-3.23,9.52A29.59,29.59,0,0,1,98,63.82a5,5,0,0,1-5,2.53,4.67,4.67,0,0,1-3.67-4.19,9.84,9.84,0,0,1-.09-2.71A41.16,41.16,0,0,1,90.17,55c1.74-8.47,3.58-16.94,5.32-25.5a43.11,43.11,0,0,1,5.94-15.19,16.41,16.41,0,0,1,5.76-5.68c4.55-2.45,8.82-2,12.93.87A14,14,0,0,1,125,16a25.42,25.42,0,0,1,1.57,12.48c-.44,3.32-1,6.64-1.75,9.87a48.46,48.46,0,0,0-1.48,11.79c0,.87,0,1.83-.09,2.71A6.89,6.89,0,0,1,117,59.1c-3.23.27-6-1.83-7.07-5.32Zm-.79-16.24c.44-2.54.79-5.07,1.14-7.43-.35-.34-.53-.52-.62-.52-.26.09-.61.18-.69.35-.7,2.45-1.4,4.89-2,7.34-.09.26.43.69.78.87.18.09.62-.09.88-.26s.61-.44.61-.7Z" />
                    <path d="M143.44,46.7h-.27c-.26-2.88-.52-5.76-.87-8.64a12.42,12.42,0,0,0-.7-2.36,14.47,14.47,0,0,0-.78,2.62c-.18,3.32-.35,6.64-.35,10,.08,4.1.35,8.2.52,12.31a16.3,16.3,0,0,1-.17,3.58,5.7,5.7,0,0,1-4.54,4.63,5.26,5.26,0,0,1-5.59-2.27,9.44,9.44,0,0,1-1.58-3.23,88.08,88.08,0,0,1-1.48-9.44,105.83,105.83,0,0,1,.7-27.76,70.45,70.45,0,0,1,1.83-7.52,13,13,0,0,1,3.85-6,11.49,11.49,0,0,1,16.76.79,20.69,20.69,0,0,1,4.11,7.59,27.94,27.94,0,0,0,.87,3.06c.35.87.09,2.18,1.48,2.53a13.29,13.29,0,0,0,.44-2.62c.17-4.28.26-8.56.44-12.83a27,27,0,0,1,.61-5.42,5.34,5.34,0,0,1,5.06-3.93,5.21,5.21,0,0,1,4.89,4.63,20.69,20.69,0,0,1,.09,2.36c.09,1.48.09,3,.17,4.45a117,117,0,0,1-4.45,38.51,57.71,57.71,0,0,1-2.79,7.69,18.33,18.33,0,0,1-3.58,5.24,7.12,7.12,0,0,1-11.62-1.4,20.78,20.78,0,0,1-2.27-6.38c-.61-3.31-.78-6.72-1.13-10Z" />
                    <path d="M170.33,54.74a52.3,52.3,0,0,1-1.39-13.19,210.81,210.81,0,0,1,1.57-27.86,22.64,22.64,0,0,1,1-4.89,6.27,6.27,0,0,1,5.67-4.54A19.83,19.83,0,0,1,193.3,9a30.94,30.94,0,0,1,10.48,18.34,41,41,0,0,1-5.59,29.43,18.51,18.51,0,0,1-12.14,8.82c-5.06,1.22-12-.44-14.93-8.21C170.68,56.22,170.42,55,170.33,54.74Zm17.73-3.5a33,33,0,0,0,3-21.83c-.87-1.92-1.66-3.93-2.62-5.85a10.12,10.12,0,0,0-3.76-3.93c-1-.61-1.83-.26-1.92.88-.43,8.55-.78,17.11-1.13,25.67a32,32,0,0,0,.26,5,9.9,9.9,0,0,0,.7,2.62,1.8,1.8,0,0,0,2.36.78,6.18,6.18,0,0,0,3-3.32Z" />
                </g>
            </g>
        </svg>
    )
}
