import React from "react"

export default () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 280.47 196.72">
            <defs>
                <style></style>
            </defs>
            <title>A cleaner future text</title>
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                    <path d="M73.64,152.85h.25c.17-5.66.5-11.33.5-17,0-6.16,2.58-9.33,8.83-11.24,3.33-1,6.66-2,10.24-1.75a17.6,17.6,0,0,1,7.33,2.58,4.75,4.75,0,0,1-1.33,8.75c-1.42.33-2.83.66-4.25.91-1.83.25-3.75.42-5.58.67a3,3,0,0,0-1.58.58,2.29,2.29,0,0,0-.42,1.5c0,.25.5.66.84.75a9.56,9.56,0,0,0,2.16.08,9.68,9.68,0,0,1,2.17.08,4.24,4.24,0,0,1,3.58,3.84,3.9,3.9,0,0,1-3.33,3.91c-1.42.33-2.92.42-4.33.67a1.6,1.6,0,0,0-1.34,1.49c-.08,1.34-.16,2.67-.16,4-.09,7.83-.17,15.65-.34,23.48a11.51,11.51,0,0,1-.5,3.42,6.24,6.24,0,0,1-6.07,4.74,6.6,6.6,0,0,1-6-5,13.51,13.51,0,0,1-.33-2.58,106.66,106.66,0,0,1-.34-19.24c.09-1.58,0-3.16,0-4.75Z" />
                    <path d="M134.51,155h.25c-.41,5.66-.75,11.32-1.41,17a55.46,55.46,0,0,1-1.83,8.91,21.09,21.09,0,0,1-2.67,5.91c-4,6-11,7-16.65,2.5a20.12,20.12,0,0,1-5.5-7.16,31.56,31.56,0,0,1-2.58-8.66,79.88,79.88,0,0,1-.5-20.9A74,74,0,0,1,105,143a17,17,0,0,1,1.83-4.83,5.94,5.94,0,0,1,6.74-2.83c2.75.58,4.17,2.58,4.67,5.16a11.73,11.73,0,0,1,.08,3.83,126.5,126.5,0,0,0,.5,25.73,32.15,32.15,0,0,0,1,4.75c.08.5.66.83,1,1.25a4.75,4.75,0,0,0,.91-1.34,57.67,57.67,0,0,0,2.5-13.65c.5-5.33.5-10.74,1.83-16a14.73,14.73,0,0,1,1-3.25,4.1,4.1,0,0,1,4.42-2.08A3.83,3.83,0,0,1,134.6,143a6.69,6.69,0,0,1,.16,1.25v11Z" />
                    <path d="M144.76,177.24h-.25V155.51c0-3.75,0-7.49-.09-11.24,0-1.33-.25-1.58-1.5-1.83-.74-.17-1.49-.25-2.24-.42a7,7,0,0,1-5.58-6.91A6.83,6.83,0,0,1,141,128.2a40.54,40.54,0,0,1,7.16-.58c3.42,0,6.83.25,10.24.41a19.5,19.5,0,0,1,3.75.59,7,7,0,0,1,5.41,6.24,7.12,7.12,0,0,1-4.58,6.91l-.75.25c-1.16.42-2.83-.16-3.41,1.17-.42,1-.08,2.33-.08,3.5-.09,7.49-.17,15-.17,22.48,0,3,.25,6,.33,9a33.43,33.43,0,0,1-.16,5,6.86,6.86,0,0,1-13.58.25,49.76,49.76,0,0,1-.66-6.17Z" />
                    <path d="M199,155h.25c-.42,5.66-.75,11.32-1.42,17a54.4,54.4,0,0,1-1.83,8.91,20.81,20.81,0,0,1-2.67,5.91c-4,6-11,7-16.65,2.5a20.09,20.09,0,0,1-5.49-7.16,31.24,31.24,0,0,1-2.59-8.66,80.32,80.32,0,0,1-.5-20.9,74,74,0,0,1,1.42-9.49,17,17,0,0,1,1.83-4.83,6,6,0,0,1,6.75-2.83c2.74.58,4.16,2.58,4.66,5.16a11.73,11.73,0,0,1,.08,3.83,127.22,127.22,0,0,0,.5,25.73,33.49,33.49,0,0,0,1,4.75c.08.5.67.83,1,1.25a5,5,0,0,0,.92-1.34,57.65,57.65,0,0,0,2.49-13.65c.5-5.33.5-10.74,1.84-16a14.73,14.73,0,0,1,1-3.25,4.08,4.08,0,0,1,4.41-2.08,3.82,3.82,0,0,1,3.08,3.25,6.71,6.71,0,0,1,.17,1.25v11Z" />
                    <path d="M202.71,188.15c-.91-4.83-1.66-9.66-2.49-14.49-.67-8.07-.92-16.15.33-24.14a61.51,61.51,0,0,1,2.25-9.58,30,30,0,0,1,9.66-14.07,36.58,36.58,0,0,1,4.74-3.17c9.33-5,20.57-.58,24.15,10.08a22.08,22.08,0,0,1-1.41,17.9,33.75,33.75,0,0,1-10.33,12.08c-.92.66-1.83,1.24-2.75,1.91s-1,1.08-.25,2c3.33,4.16,6.58,8.33,10,12.41a23.87,23.87,0,0,0,9,6.41c2.58,1.08,5.24,1.58,8,.41a4.17,4.17,0,0,1,3.16-.25A3.88,3.88,0,0,1,258.5,192a3.56,3.56,0,0,1-.58.59c-4.83,2.74-9.66,5.24-15.49,3.66a28.44,28.44,0,0,1-14.24-8.41,68.93,68.93,0,0,1-4.74-6.08c-1.5-2.16-2.83-4.41-4.25-6.58-.75-1.16-1.5-2.33-2.33-3.41-.5-.67-1-1.58-1.91-1.25-.34.17-.5,1.25-.5,1.92a39.88,39.88,0,0,0,.25,4.49c.25,3.25.58,6.5.91,9.74a15.79,15.79,0,0,1,.09,2.83,6.28,6.28,0,0,1-4.5,5.83,6,6,0,0,1-6.83-2.58,14.16,14.16,0,0,1-1.83-4.49Zm23.57-43.46a9.43,9.43,0,0,0,1.17-5.33,9.63,9.63,0,0,0-.5-1.67c-.67-1.58-1.84-2-3.33-1.08a15.21,15.21,0,0,0-5.58,6.33,29.5,29.5,0,0,0-3,11c-.08.75.58,1.17,1.33.75a22.58,22.58,0,0,0,10-9.91Z" />
                    <path d="M263.09,126.7a22.25,22.25,0,0,1,3.41.25,15.92,15.92,0,0,1,4.41,1.42,6.93,6.93,0,0,1,3.5,7.66,7.08,7.08,0,0,1-6.24,5.66,35.45,35.45,0,0,1-4.25,0c-1.25,0-2.5-.08-3.75-.08a2,2,0,0,0-2.25,1.74,35.1,35.1,0,0,0-.58,5.17c-.08.91.5,1.41,1.42,1.5a43.41,43.41,0,0,1,4.91.5,3.59,3.59,0,0,1,2.66,4.24,4.06,4.06,0,0,1-3.49,3.33c-1.17.17-2.33.09-3.5.17-.75.08-1.33.5-1.17,1.33a60.67,60.67,0,0,0,1.25,6.66,2.72,2.72,0,0,0,2.83,2.17c1.5-.09,3-.34,4.5-.34a11.67,11.67,0,0,1,3.41.25,5.58,5.58,0,0,1,2.17,9.25,11.38,11.38,0,0,1-2.75,2.08,14.51,14.51,0,0,1-18.32-2.91,20.09,20.09,0,0,1-4.49-8.67,108.82,108.82,0,0,1-2.83-17.23A33.17,33.17,0,0,1,244.6,139c.17-.74.42-1.49.67-2.24,2.08-6.42,6.41-9.75,12.9-10.08a41.25,41.25,0,0,1,4.58.17Z" />
                    <path d="M7.14,116.35A36.88,36.88,0,0,1,.81,86.72,62,62,0,0,1,5.48,73a28,28,0,0,1,5.46-7.6c6.82-6.73,21-7,26.7,4.68a38.53,38.53,0,0,1,3.61,12.28A14.65,14.65,0,0,1,41,87.4a7.15,7.15,0,0,1-6.24,5.66,7.13,7.13,0,0,1-7.6-3.61,20.58,20.58,0,0,1-1.66-4.87c-.58-2-1.07-4.1-1.75-6.14-.39-1.17-1.56-1.27-2.34-.3l-.29.3c-3.32,5.84-5.46,12-4.78,18.81a19.94,19.94,0,0,0,4,9.74,9.21,9.21,0,0,0,2.53,2.24,4,4,0,0,0,5.07-.58,9.07,9.07,0,0,0,2.53-4.29,16.69,16.69,0,0,1,1.27-3.7,4,4,0,0,1,4.58-2.24,4,4,0,0,1,3.12,3.5,2.89,2.89,0,0,1,.1,1.27c-.69,4.09-.88,8.29-2.73,12.09a19.39,19.39,0,0,1-5.56,6.92c-5.46,4.28-11.59,4.58-17.44.78A27,27,0,0,1,7,116.45Z" />
                    <path d="M71.85,127.46c-2.63,2.24-12.18,5-15.5,4.38a14.31,14.31,0,0,1-10.13-6.92,27.66,27.66,0,0,1-4.29-12.27,80.16,80.16,0,0,1-.58-10.82c.19-9.75.58-19.49,1.07-29.24A19.53,19.53,0,0,1,44.07,66c1.56-3.41,4.29-5.26,7.9-5.07s6,2.44,7.21,6A22.24,22.24,0,0,1,60.35,73c.29,7,.39,14,.39,21,0,4.78-.19,9.55-.19,14.33a27,27,0,0,0,.38,4,3.72,3.72,0,0,0,3.81,3.41c1.07,0,2.14-.29,3.21-.29a6.11,6.11,0,0,1,6.14,4.58,6.65,6.65,0,0,1-2.34,7.12Z" />
                    <path d="M69.7,101.83A77.71,77.71,0,0,1,67,78.25a41.78,41.78,0,0,1,2.33-12.67A16.1,16.1,0,0,1,82.57,54.37c4.09-.68,8.09-1,12.08.39a10.18,10.18,0,0,1,5.17,3.8,7.07,7.07,0,0,1-5,11.21c-1.46.19-3,0-4.58.1-1.17.09-2.34.29-3.51.48A2.76,2.76,0,0,0,84.13,73c-.1.48-.2,1-.29,1.46-.39,2.34,0,2.82,2.24,2.82s4.48-.19,6.72-.09a8.47,8.47,0,0,1,3.8,1.07,5.7,5.7,0,0,1,.59,8.87,7.41,7.41,0,0,1-4.49,2.24l-7,.88c-1.27.19-2,.78-1.85,2a37.62,37.62,0,0,0,1,6.52A3.58,3.58,0,0,0,89,101.54c2-.19,4-.78,6-1.07a11.59,11.59,0,0,1,4.1-.1,6.75,6.75,0,0,1,2.34,12A35.68,35.68,0,0,1,96,115c-12.18,4.87-22.9-1.95-26.12-13Z" />
                    <path d="M105,111.18h-.29c0-6.33-.1-12.67.2-18.9a65.3,65.3,0,0,1,1.26-10.63,19.65,19.65,0,0,1,5.56-10.13c7.11-7,16.86-5.75,22.61.78a24.31,24.31,0,0,1,4.19,7,68.45,68.45,0,0,1,3.9,15.79A137.36,137.36,0,0,1,144,116.93a29.86,29.86,0,0,1-.39,5.07,8.23,8.23,0,0,1-16.08.29,53.32,53.32,0,0,1-.88-5.55,19.06,19.06,0,0,0-.49-2.54c-.49-2.24-.88-2.53-3.51-2.63a1.85,1.85,0,0,0-2.14,1.76c-.29,2.34-.49,4.67-.78,7a24.71,24.71,0,0,1-1,4.49,6.75,6.75,0,0,1-7.11,4.67,6.91,6.91,0,0,1-6.24-5.26,18,18,0,0,1-.58-4c-.1-1.56-.1-3.12-.1-4.68v-4.68ZM123.79,98c.1-.1,0-.3,0-.78-.19-.69-.39-1.66-.58-2.63,0-.1-.29-.3-.29-.3-.3.2-.69.39-.69.59a21.87,21.87,0,0,0-.29,3.51c0,.29.68.58,1.07.87l.88-1.17Z" />
                    <path d="M146.11,114.79c-.68-5.26-1.07-10.53-1.56-15.79.49-4.77.88-9.55,1.56-14.23a29.43,29.43,0,0,1,3.6-10.52,27.85,27.85,0,0,1,2.34-3.41c5.75-6.92,14.43-7.51,21.15-1.47a23.69,23.69,0,0,1,6.14,9.26c1.37,3.51,2.34,7.12,3.51,10.62.1.3.39.49.58.78.3-.29.69-.58.78-1a4.12,4.12,0,0,0,.1-1.56c.1-3,0-6.14.39-9.16.49-4.39,1.17-8.77,2.05-13.06a15.28,15.28,0,0,1,2.43-5.07,6,6,0,0,1,5.75-2.53c3.32.29,5.75,3.8,4.1,6.63-2.54,4.28-2.63,8.77-2.73,13.35-.1,6.62,0,13.25.1,19.88a60.05,60.05,0,0,1-2.05,17.64,24.53,24.53,0,0,1-3,6.92c-4,6-11.4,6.92-16.86,2.24a17.42,17.42,0,0,1-4.39-6.14,55.19,55.19,0,0,1-4-13.16c-.39-1.85-.88-3.7-1.37-5.55,0-.1-.29-.2-.48-.2s-.3.1-.3.2a15.9,15.9,0,0,0-.29,2c0,3.71.1,7.41.1,11.12a29.63,29.63,0,0,1-.29,5.06,8.6,8.6,0,0,1-6.92,7.31,8.48,8.48,0,0,1-9.16-4.29,20.58,20.58,0,0,1-1.95-6.33Z" />
                    <path d="M201.75,105.69A77.77,77.77,0,0,1,199,82.1a41.78,41.78,0,0,1,2.33-12.67,16.12,16.12,0,0,1,13.26-11.21c4.09-.68,8.09-1,12.08.39a10.18,10.18,0,0,1,5.17,3.8,7.07,7.07,0,0,1-5,11.21c-1.46.2-3,0-4.58.1-1.17.1-2.34.29-3.51.49a2.74,2.74,0,0,0-2.63,2.63c-.1.49-.2,1-.29,1.46-.39,2.34,0,2.83,2.24,2.83s4.48-.2,6.72-.1a8.6,8.6,0,0,1,3.8,1.07,5.7,5.7,0,0,1,.59,8.87,7.3,7.3,0,0,1-4.49,2.24l-7,.88c-1.27.19-2,.78-1.85,2a37.87,37.87,0,0,0,1,6.53,3.58,3.58,0,0,0,4.19,2.82c2-.19,4-.78,6-1.07a11.83,11.83,0,0,1,4.1-.1,6.35,6.35,0,0,1,5,5.27,6.27,6.27,0,0,1-2.63,6.72,35.68,35.68,0,0,1-5.46,2.63c-12.18,4.88-22.9-2-26.12-13Z" />
                    <path d="M266.28,60.76C273.18,64,276.51,71,275.4,78.64c-.61,3.94-1.6,7-3.7,9.49-1.6,2-1.35,3.7-.36,5.67,1.47,2.83,2.83,5.79,4.19,8.75,1.11,2.59,2.34,5.18,3.2,7.89a36.13,36.13,0,0,1,1.73,8,8.38,8.38,0,0,1-5.3,8.26,7.38,7.38,0,0,1-8.76-2.46,27.93,27.93,0,0,1-3.32-6.29c-1-2.34-1.48-4.93-2.35-7.4-1.11-3.2-2.22-6.41-3.33-9.49a3.56,3.56,0,0,0-1.23-1.48,1.7,1.7,0,0,0-2.59.87,15.23,15.23,0,0,0-.12,2.34c0,3,.12,5.91,0,8.75a19.7,19.7,0,0,1-.74,5.3,7.23,7.23,0,0,1-5.92,5.55,7.12,7.12,0,0,1-7.52-3.45,14.06,14.06,0,0,1-2.09-6.29,139.41,139.41,0,0,1-.74-14.42c.12-6.29.74-12.58,1.23-18.86a40.27,40.27,0,0,1,1-6.66c1.85-8.51,7-13.32,15.41-14.3a21.81,21.81,0,0,1,12.2,2.22ZM254.69,72.84c-.24.37-.12,1-.37,2.47,0,1.23,0,3.45.13,5.67a1,1,0,0,0,1.23.86,9,9,0,0,0,4.56-7.77,2.3,2.3,0,0,0-1.48-2.34,7.06,7.06,0,0,0-2.71-.37c-.62.13-1.23.87-1.73,1.48Z" />
                    <path d="M152.75,23.86c-.21,2.37-.33,4.74-.45,6.52-.15,6.82-.7,13.05.34,19.18.28,1.69.66,3.37,1,5a23.85,23.85,0,0,1,.57,2.58,7.36,7.36,0,0,1-3.12,7.19,7.91,7.91,0,0,1-8.31.72,9.57,9.57,0,0,1-5.19-6.17c-.67-2.08-1-4.26-1.62-6.44a1.74,1.74,0,0,0-2.17-1.6,7.71,7.71,0,0,1-1.48.28c-2.08,0-2.48.87-2.89,2.84-.21,1.19-.22,2.38-.43,3.56a27.92,27.92,0,0,1-.82,4,5.2,5.2,0,0,1-10-.29,21.21,21.21,0,0,1-.54-6.62c.44-5.53.88-11.07,1.82-16.5,1-5.92,2.57-11.74,4.1-17.56a42.83,42.83,0,0,1,4.64-11.42,22.71,22.71,0,0,1,6.09-7c5.57-4,12.58-2.57,16,3.39A23.05,23.05,0,0,1,152.46,12a42.45,42.45,0,0,1,.39,11.76ZM135,40a17.76,17.76,0,0,0,1.12-3.94,20.37,20.37,0,0,0,0-4.94c-.27-3-2.38,1.36-2.58,2.25a23.7,23.7,0,0,0-.45,5.93,2,2,0,0,0,.19,1.09,1,1,0,0,0,1,.4,1,1,0,0,0,.6-.69Z" />
                </g>
            </g>
        </svg>
    )
}
