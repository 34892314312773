import React from "react"

export default () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 336 209.3">
            <defs>
                <style></style>
            </defs>
            <title>Dishwash liquid concentrate text</title>
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                    <path d="M122.81,192.4c-2.25,1.91-10.41,4.25-13.24,3.75a12.3,12.3,0,0,1-8.66-5.92,23.69,23.69,0,0,1-3.66-10.49,69.79,69.79,0,0,1-.5-9.24c.17-8.33.5-16.65.92-25a16.54,16.54,0,0,1,1.41-5.66,6.7,6.7,0,0,1,6.75-4.33c3.16.16,5.16,2.08,6.16,5.08a19.13,19.13,0,0,1,1,5.24c.25,6,.33,12,.33,18,0,4.08-.17,8.16-.17,12.24a22,22,0,0,0,.34,3.41,3.17,3.17,0,0,0,3.24,2.92c.92,0,1.84-.25,2.75-.25a5.22,5.22,0,0,1,5.25,3.91,5.69,5.69,0,0,1-2,6.08Z" />
                    <path d="M141.47,186.84c.08,4.66-.09,9.32-.17,14a12.26,12.26,0,0,1-.33,3,6.37,6.37,0,0,1-5.5,4.91,6.72,6.72,0,0,1-6.74-3.92c-.34-.66-.5-1.33-.84-2.16-.08-1.42-.33-3-.33-4.58.08-8.74.17-17.49.33-26.23a20.1,20.1,0,0,1,.34-3.91,8.78,8.78,0,0,1,1.33-3.17,5.61,5.61,0,0,1,9.49.09,8.46,8.46,0,0,1,1.42,4.49c.25,5.83.66,11.66.83,17.49Z" />
                    <path d="M143.21,175.83c-.41-2.83-.58-5.75-.91-8.66A103.36,103.36,0,0,1,144,153.68,34,34,0,0,1,149,141.27a61.25,61.25,0,0,1,9.16-11c3.75-3.66,8.5-4.33,13.41-4.08,5.16.25,8.91,2.92,11.66,7.33a28.43,28.43,0,0,1,4.08,15.9c-.25,13.33-3.08,26-10.58,37.22a5.9,5.9,0,0,0-1.16,4.08c.16,1.34.16,2.67.25,4a15.87,15.87,0,0,0,2.16,7.41,4.33,4.33,0,0,1-.67,5.91,5.6,5.6,0,0,1-6.49.42,9.22,9.22,0,0,1-3.25-4.08,32,32,0,0,1-2.41-8.83c-.25-1.66-.59-1.91-2.25-2H161.2c-4.33.16-7.83-1.67-10.91-4.58a23.4,23.4,0,0,1-7.08-13.24Zm23.65-32.72a63.8,63.8,0,0,0-4.49,21.31c.08,1.58,0,3.33.25,5.08a53.45,53.45,0,0,0,1.49,6.33,1.19,1.19,0,0,0,2,.67,28.67,28.67,0,0,0,3.33-3.92c3.33-5.16,5.08-11,6.25-17a39.53,39.53,0,0,0,.75-10.41,14.46,14.46,0,0,0-2.67-7.75,1.87,1.87,0,0,0-3-.25,15.93,15.93,0,0,0-4.17,6Z" />
                    <path d="M218.66,165.36h.25c-.42,5.66-.75,11.32-1.42,17a54.4,54.4,0,0,1-1.83,8.91,20.94,20.94,0,0,1-2.67,5.91c-4,6-11,7-16.65,2.5a20.09,20.09,0,0,1-5.49-7.16,31.24,31.24,0,0,1-2.59-8.66,79.88,79.88,0,0,1-.49-20.9,72.22,72.22,0,0,1,1.41-9.49,17,17,0,0,1,1.83-4.83,5.94,5.94,0,0,1,6.75-2.83c2.74.58,4.16,2.58,4.66,5.16a11.73,11.73,0,0,1,.08,3.83,127.22,127.22,0,0,0,.5,25.73,33.78,33.78,0,0,0,1,4.75c.09.5.67.83,1,1.25a5,5,0,0,0,.92-1.34,57.65,57.65,0,0,0,2.49-13.65c.5-5.33.5-10.74,1.84-16a14.73,14.73,0,0,1,1-3.25,4.08,4.08,0,0,1,4.41-2.08,3.8,3.8,0,0,1,3.08,3.25,6.6,6.6,0,0,1,.17,1.25v11Z" />
                    <path d="M235.65,174c.08,4.66-.09,9.32-.17,14a12.13,12.13,0,0,1-.33,3,6.37,6.37,0,0,1-5.5,4.92A6.74,6.74,0,0,1,222.9,192c-.33-.66-.49-1.33-.83-2.16-.08-1.42-.33-3-.33-4.58.08-8.74.17-17.49.33-26.23a20.1,20.1,0,0,1,.34-3.91,8.61,8.61,0,0,1,1.33-3.17,5.6,5.6,0,0,1,9.49.09,8.46,8.46,0,0,1,1.42,4.49c.25,5.83.66,11.66.83,17.49Z" />
                    <path d="M237.81,184.49a50,50,0,0,1-1.33-12.57,203.26,203.26,0,0,1,1.5-26.57,21.6,21.6,0,0,1,.91-4.66,6,6,0,0,1,5.42-4.33,18.9,18.9,0,0,1,15.4,4.5,29.41,29.41,0,0,1,10,17.48,39.08,39.08,0,0,1-5.33,28.06,17.56,17.56,0,0,1-11.57,8.41C248,196,241.39,194.4,238.56,187A24.36,24.36,0,0,1,237.81,184.49Zm16.9-3.33a31.51,31.51,0,0,0,2.83-20.82c-.83-1.83-1.58-3.75-2.49-5.58a9.65,9.65,0,0,0-3.58-3.74c-.92-.59-1.75-.25-1.84.83-.41,8.16-.74,16.32-1.08,24.48a31.58,31.58,0,0,0,.25,4.75,9.6,9.6,0,0,0,.67,2.49,1.7,1.7,0,0,0,2.25.75,5.9,5.9,0,0,0,2.83-3.16Z" />
                    <path d="M6.11,121.14a30.8,30.8,0,0,1-5.42-25,51.46,51.46,0,0,1,4-11.59,23.6,23.6,0,0,1,4.67-6.41c5.83-5.67,17.92-5.92,22.84,3.94a32.34,32.34,0,0,1,3.08,10.36A12.13,12.13,0,0,1,35,96.72a6.07,6.07,0,0,1-5.33,4.77,6.13,6.13,0,0,1-6.5-3,16.6,16.6,0,0,1-1.42-4.12c-.5-1.72-.92-3.45-1.5-5.17-.34-1-1.34-1.07-2-.25a3,3,0,0,0-.25.25c-2.84,4.93-4.67,10.11-4.09,15.86a16.83,16.83,0,0,0,3.42,8.22,7.75,7.75,0,0,0,2.17,1.89,3.45,3.45,0,0,0,4.33-.49A7.66,7.66,0,0,0,26,111a14.07,14.07,0,0,1,1.08-3.12A3.4,3.4,0,0,1,31,106a3.45,3.45,0,0,1,2.67,3,2.3,2.3,0,0,1,.08,1.07c-.58,3.45-.75,7-2.33,10.19a16.32,16.32,0,0,1-4.75,5.84,12.18,12.18,0,0,1-14.93.66A22.83,22.83,0,0,1,6,121.22Z" />
                    <path d="M39,125a32.51,32.51,0,0,1-3-10.93c-.92-9.21-.34-16.78,1.75-24.18a31.77,31.77,0,0,1,3.5-8.3,10.88,10.88,0,0,1,5.42-4.77,21.19,21.19,0,0,0,2.66-1.39,9.06,9.06,0,0,1,8.34-.09,15.73,15.73,0,0,1,7.84,7.81,34.61,34.61,0,0,1,3.33,14.64c-.08,4.68-.42,9.45-.92,14.14a33.9,33.9,0,0,1-4.58,13.81,16.8,16.8,0,0,1-7.75,7.4c-4.34,1.89-8.26,1.15-11.92-1.56A16.66,16.66,0,0,1,38.7,125ZM52,91.62a64.9,64.9,0,0,0-1,18.09c.25,1.23.5,3,.84,4.6a1.29,1.29,0,0,0,.91.82,1.32,1.32,0,0,0,.92-.74,16.28,16.28,0,0,0,1.17-3.2A62.66,62.66,0,0,0,57,92a38.35,38.35,0,0,0-1.34-6.66c0-.16-.33-.41-.5-.41a.82.82,0,0,0-.66.49,45.91,45.91,0,0,0-2.34,6.5Z" />
                    <path d="M69.62,120q-.12-6.3-.25-12.58c-.08-3.62-.17-7.24-.25-10.86-.08-3.12-.08-6.49,1.67-9.12a8.32,8.32,0,0,1,9.33-3c3.17,1.15,5.26,4.11,7.09,6.9L91.71,98c1.42-2.3,1.75-5.1,2-7.81s.5-5.51,1.84-7.81c3.33-5.84,9.67-3.37,11.33,2.22a23.64,23.64,0,0,1,.5,7.32l-.75,29.1c-.08,3.21-.25,6.66-2.08,9.29a8.79,8.79,0,0,1-10.34,2.8c-5.58-2.55-6.75-10-11.17-14.06-.16,2.71-.33,5.51-1.58,7.89s-3.92,4.28-6.59,3.78a6.86,6.86,0,0,1-4.67-4.35,19.22,19.22,0,0,1-1-6.5Z" />
                    <path d="M112.05,124c-1.92-2.3-3.34-5.42-4.25-9.78a33.39,33.39,0,0,1,1.58-17.1A24.43,24.43,0,0,1,114.3,89a15.31,15.31,0,0,1,5.25-3.78c5.25-2.22,11.17-.17,13.09,4.6a14.14,14.14,0,0,1,.25,9.46,4,4,0,0,1-.92,1.48c-1.58,1.89-4.67,1.64-5.58-.41a9.55,9.55,0,0,1-.59-2.22,19.68,19.68,0,0,0-.5-2.22c-.08-.25-.5-.41-.83-.5-.17-.08-.5.09-.67.25a16.05,16.05,0,0,0-1,1.48,24.17,24.17,0,0,0-.33,19.24c.08.24.5.66.75.66a2.34,2.34,0,0,0,1.25-.66,7.73,7.73,0,0,0,1.83-5,12.34,12.34,0,0,1,0-1.72,2.89,2.89,0,0,1,2.34-2.55,2.82,2.82,0,0,1,3.25,1.07,6.34,6.34,0,0,1,1.33,3.78c-.08,2-.08,3.94-.25,5.92a11.71,11.71,0,0,1-2.08,5.83,11.52,11.52,0,0,1-13.26,4,12.57,12.57,0,0,1-5.75-4Z" />
                    <path d="M153,75.92a22.25,22.25,0,0,1,3.41.25,15.93,15.93,0,0,1,4.42,1.39,6.81,6.81,0,0,1,3.5,7.57,7,7,0,0,1-6.25,5.59,37.66,37.66,0,0,1-4.25,0c-1.25,0-2.5-.08-3.75-.08a2,2,0,0,0-2.25,1.72,34.16,34.16,0,0,0-.59,5.1c-.08.9.5,1.4,1.42,1.48a45.79,45.79,0,0,1,4.92.49,3.56,3.56,0,0,1,2.67,4.19,4.05,4.05,0,0,1-3.5,3.29c-1.17.17-2.34.09-3.5.17-.75.08-1.34.49-1.17,1.31a57.44,57.44,0,0,0,1.25,6.58,2.7,2.7,0,0,0,2.83,2.14c1.5-.08,3-.33,4.5-.33a11.74,11.74,0,0,1,3.42.25,5.47,5.47,0,0,1,2.17,9.12,11.57,11.57,0,0,1-2.75,2.06,14.67,14.67,0,0,1-18.34-2.88,19.73,19.73,0,0,1-4.5-8.55,107.4,107.4,0,0,1-2.84-17,32.5,32.5,0,0,1,.67-11.67c.17-.74.42-1.48.67-2.22,2.08-6.33,6.42-9.62,12.92-10a43.81,43.81,0,0,1,4.58.16Z" />
                    <path d="M164.89,108.75c-.41-3.53-.66-7.07-.75-10.6a89,89,0,0,1,.25-9.38c.5-6.65,4.92-12.57,11.67-7.48A81.22,81.22,0,0,1,185,89.1c.92-3.2.75-6.66,1.84-9.78S191,73.24,194.23,74c2.34.49,3.92,2.71,4.42,5a21.86,21.86,0,0,1-.08,7,95.71,95.71,0,0,0-.08,17.59,19.61,19.61,0,0,1-.17,6.58,5.67,5.67,0,0,1-4.5,4.36,5.86,5.86,0,0,1-4.75-2.14,28.43,28.43,0,0,1-2.92-4.52,34.71,34.71,0,0,0-7.59-8.72c-.91,2.72-.58,5.59-.66,8.39s-.75,5.84-3,7.56a6.17,6.17,0,0,1-8.26-1c-1.25-1.57-1.58-3.7-1.83-5.68Z" />
                    <path d="M219.07,84.39c-.41.16-.83.24-1.33.41-2.5.33-3.17,1.89-3,4.27.83,12.09-1,24.09-1.25,36.18a22.78,22.78,0,0,1-.75,5.26,4.18,4.18,0,0,1-3.92,3.21,4.31,4.31,0,0,1-4.33-2.64,10,10,0,0,1-1-3.86c-.34-4.44-.67-8.88-.75-13.32-.17-7.81-.17-15.62-.25-23.43A7.78,7.78,0,0,0,200.65,85c-2.92-3.29-1.58-8.14,3.67-9.95A44,44,0,0,1,217,73c3.5-.17,5.92,1.73,6.5,4.68.59,2.8-1,5.35-4.25,6.83Z" />
                    <path d="M241.16,78.22c4.67,2.14,6.92,6.82,6.17,11.92a12.05,12.05,0,0,1-2.5,6.33,3.08,3.08,0,0,0-.25,3.78c1,1.89,1.92,3.87,2.84,5.84.75,1.73,1.58,3.45,2.16,5.26a23.47,23.47,0,0,1,1.17,5.35,5.6,5.6,0,0,1-3.58,5.51,5,5,0,0,1-5.92-1.65,18.12,18.12,0,0,1-2.25-4.19c-.67-1.56-1-3.29-1.59-4.93-.75-2.14-1.5-4.28-2.25-6.34a2.34,2.34,0,0,0-.83-1,1.15,1.15,0,0,0-1.75.57,10.33,10.33,0,0,0-.08,1.57c0,2,.08,3.94,0,5.83a12.73,12.73,0,0,1-.51,3.54,5,5,0,0,1-9.08,1.4,9.46,9.46,0,0,1-1.42-4.2,93.77,93.77,0,0,1-.5-9.62c.08-4.19.5-8.38.84-12.57a27.21,27.21,0,0,1,.66-4.44c1.25-5.68,4.75-8.88,10.42-9.54a15,15,0,0,1,8.25,1.48Zm-7.83,8.06c-.17.24-.08.66-.25,1.64,0,.82,0,2.3.08,3.78a.65.65,0,0,0,.84.58,6,6,0,0,0,3.08-5.18,1.53,1.53,0,0,0-1-1.56,4.64,4.64,0,0,0-1.83-.25c-.42.08-.84.58-1.17,1Z" />
                    <path d="M266.84,121.71c-.17-.66-.33-1.4-.42-1.72-.08-4.2-1.41-3.62-4.17-3.13a2.38,2.38,0,0,0-2.08,1.81c-1,3-2,6-3.08,9a28.32,28.32,0,0,1-1.42,3.62,4.73,4.73,0,0,1-4.75,2.38,4.42,4.42,0,0,1-3.5-3.94,8.75,8.75,0,0,1-.09-2.55,36.13,36.13,0,0,1,.84-4.19c1.66-8,3.41-16,5.08-24a40.38,40.38,0,0,1,5.67-14.31,15.48,15.48,0,0,1,5.5-5.34,11.08,11.08,0,0,1,12.34.82,13.11,13.11,0,0,1,4.67,6.09A23.58,23.58,0,0,1,282.93,98c-.42,3.13-1,6.25-1.67,9.29a45.4,45.4,0,0,0-1.42,11.1c0,.82,0,1.73-.08,2.55a6.55,6.55,0,0,1-6,5.92c-3.09.25-5.75-1.73-6.75-5Zm-.75-15.29c.42-2.38.75-4.77,1.08-7-.33-.33-.5-.49-.58-.49-.25.08-.58.16-.67.33-.66,2.3-1.33,4.6-1.92,6.9-.08.25.42.66.75.83.17.08.59-.09.84-.25s.58-.41.58-.66Z" />
                    <path d="M303.18,84.39c-.42.16-.83.24-1.33.41-2.5.33-3.17,1.89-3,4.27.83,12.09-1,24.09-1.25,36.18a23.34,23.34,0,0,1-.75,5.26,4.18,4.18,0,0,1-3.92,3.21,4.33,4.33,0,0,1-4.34-2.64,10.35,10.35,0,0,1-1-3.86c-.33-4.44-.66-8.88-.75-13.32-.16-7.81-.16-15.62-.25-23.43A7.77,7.77,0,0,0,284.76,85c-2.92-3.29-1.58-8.14,3.67-9.95A44,44,0,0,1,301.1,73c3.5-.17,5.92,1.73,6.5,4.68.58,2.8-1,5.35-4.25,6.83Z" />
                    <path d="M324.52,75.92a22.38,22.38,0,0,1,3.42.25,16,16,0,0,1,4.42,1.39,6.83,6.83,0,0,1,3.5,7.57,7.07,7.07,0,0,1-6.26,5.59,37.66,37.66,0,0,1-4.25,0c-1.25,0-2.5-.08-3.75-.08a2,2,0,0,0-2.25,1.72,34.16,34.16,0,0,0-.58,5.1c-.09.9.5,1.4,1.41,1.48a45.79,45.79,0,0,1,4.92.49,3.56,3.56,0,0,1,2.67,4.19,4,4,0,0,1-3.5,3.29c-1.17.17-2.33.09-3.5.17-.75.08-1.34.49-1.17,1.31a57.44,57.44,0,0,0,1.25,6.58,2.72,2.72,0,0,0,2.84,2.14c1.5-.08,3-.33,4.5-.33a11.79,11.79,0,0,1,3.42.25,5.47,5.47,0,0,1,2.16,9.12,11.57,11.57,0,0,1-2.75,2.06,14.67,14.67,0,0,1-18.34-2.88,19.73,19.73,0,0,1-4.5-8.55,106.26,106.26,0,0,1-2.83-17A32.27,32.27,0,0,1,306,88.09c.17-.74.42-1.48.67-2.22,2.08-6.33,6.42-9.62,12.92-10a44,44,0,0,1,4.59.16Z" />
                    <path d="M53.31,6.18C61.17,10.89,65,15.87,68,27c2.36,8.56.44,18.95-5.15,28.73a17.6,17.6,0,0,1-7.34,7.16,13.77,13.77,0,0,1-10.65.88c-7-2.28-11.35-7-12.4-14.33a81.49,81.49,0,0,1-.7-11.35c0-7.33.26-14.67.44-22a26.64,26.64,0,0,1,.34-3.67,9.51,9.51,0,0,1,7.17-8.12C44.4,3,48.94,3.12,53.31,5.74ZM53.57,47A26.81,26.81,0,0,0,54,31.24c-1-3.06-2.1-6.11-4.63-8.21-2.8-2.36-5.76-4.45-8.65-6.64a.59.59,0,0,0-1,.44,14.14,14.14,0,0,0-.17,1.84c.26,8.38.52,16.76,1,25.15a22,22,0,0,0,1.22,5.76c.79,2.44,2.8,3.49,5.24,3.84,2.27.35,3.76-1,4.89-2.71a14.29,14.29,0,0,0,1.84-3.66Z" />
                    <path d="M86.32,44.69c.09,4.89-.09,9.78-.18,14.67a12.45,12.45,0,0,1-.35,3.14A6.67,6.67,0,0,1,80,67.66,7.06,7.06,0,0,1,73,63.55c-.35-.7-.53-1.4-.88-2.27-.08-1.48-.35-3.14-.35-4.8.09-9.17.18-18.34.35-27.51a21.21,21.21,0,0,1,.35-4.1,9.37,9.37,0,0,1,1.4-3.32,5.89,5.89,0,0,1,10,.08,8.9,8.9,0,0,1,1.48,4.72c.26,6.11.7,12.23.87,18.34Z" />
                    <path d="M117.23,40.41a32.31,32.31,0,0,1,1.31,12.66,22.29,22.29,0,0,1-1.75,7.77C114.7,66,110.68,69,104.92,69.58a14.18,14.18,0,0,1-13.45-5.5,15.83,15.83,0,0,1-3.23-13.54,10.25,10.25,0,0,1,1-2.79A3.45,3.45,0,0,1,93,46.35,3.14,3.14,0,0,1,95.57,49c.09.78-.08,1.66,0,2.53a10.61,10.61,0,0,0,.62,3.32c.78,1.92,2.44,3.4,3.23,3,1.39-.61,1.57-2.09,1.83-3.23a17.84,17.84,0,0,0-3.58-15.89A28.71,28.71,0,0,0,94.88,36c-1-.87-2-1.66-2.89-2.62a15.53,15.53,0,0,1-4.45-14.84A21.73,21.73,0,0,1,93.22,7.84,12.9,12.9,0,0,1,105,3.65a9.92,9.92,0,0,1,8.73,8.29,6.56,6.56,0,0,1-.53,4.11c-1.13,2.44-2.79,3.31-5.58,2.88l-.79-.26c-.79-.27-1.66-.79-2.36.08a3.28,3.28,0,0,0,0,3.93c.88,1,1.92,1.84,2.88,2.71a31.92,31.92,0,0,1,9.7,15.37Z" />
                    <path d="M120,58.14c-.35-2.45-.53-4.89-.79-7.34-1-6.55-.52-13.1-.17-19.65.34-6.72.87-13.45,1.39-20.08a19.22,19.22,0,0,1,1.14-4.89,6.65,6.65,0,0,1,7.33-4.28,6.75,6.75,0,0,1,5.68,5.76A33.34,33.34,0,0,1,135,13.6c0,3.84-.09,7.69-.09,11.53,0,1.22.09,2.44.18,3.67s.61,1.65,1.66,1.65c1.48,0,2.27-.43,2.35-1.57.26-3.32.44-6.63.7-9.95.18-3.06.35-6.12.7-9.17a10.08,10.08,0,0,1,1.31-3.84A5.37,5.37,0,0,1,147.1,3,5.3,5.3,0,0,1,152,6.53a15.68,15.68,0,0,1,.87,4.45c.52,12.93.52,25.85.26,38.77q-.12,6.16-.52,12.32a9.16,9.16,0,0,1-.7,3.05,4.41,4.41,0,0,1-4.37,2.71A4.78,4.78,0,0,1,143.17,65a3.19,3.19,0,0,1-.35-.79c-1.49-6.64-3.58-13.19-3.58-20.17a25.39,25.39,0,0,0-.09-2.62c-.09-1.31-.61-1.84-1.57-1.84-1.31,0-2.36.61-2.53,1.49a9.23,9.23,0,0,0-.27,2.27c0,3.23.09,6.46.09,9.69a39,39,0,0,1-.35,5.5,7.6,7.6,0,0,1-1,3.06,6.78,6.78,0,0,1-11.18,1.48,8.94,8.94,0,0,1-2.44-4.89Z" />
                    <path d="M193.12,67.92a8.48,8.48,0,0,1-6.9,2.7A7.75,7.75,0,0,1,180.46,68c-.7-.69-1.14-1.57-1.84-2.27-1-1-.7-3.05-2.53-3.49-.87,1.14-1.66,2.27-2.53,3.32-4.2,5.24-10.92,5.06-14.85-.44a17,17,0,0,1-2.88-7A94.94,94.94,0,0,1,154,45.91c-.53-8.82-.7-17.64-1-26.46a22.61,22.61,0,0,1,.17-4.19,8.42,8.42,0,0,1,.7-2.53,5.43,5.43,0,0,1,5-3.32A5.13,5.13,0,0,1,164,12.55a12.62,12.62,0,0,1,1.05,4c1.05,7.6,2,15.19,3.06,22.79.17,1.22.43,2.45.69,3.67.09.26.35.7.44.7a1.68,1.68,0,0,0,1-.53c.7-1,1.22-2.18,1.92-3.23a24.3,24.3,0,0,1,2.54-3.32c2.7-2.62,5.93-2.35,8.38.44.26.35.43.7.7,1s.52,1.13,1.13.79c.35-.18.53-.88.61-1.4.18-1.57.26-3.15.35-4.72a125.36,125.36,0,0,1,4.46-23.4,25.88,25.88,0,0,1,2.09-5.07A8.14,8.14,0,0,1,198.62.06a7.39,7.39,0,0,1,8.21,6.12,9,9,0,0,1-.53,5.06c-2.88,7.6-4.1,15.63-5,23.67-.79,6.81-1.58,13.62-2.45,20.34a23.8,23.8,0,0,1-2.53,8.48,22.77,22.77,0,0,1-3.14,4.45Z" />
                    <path d="M232.5,9.06a22.72,22.72,0,0,1,4.81,9.17,63.85,63.85,0,0,1,1.74,14.06c-.17,9.78-.44,19.56-.7,29.34a11.84,11.84,0,0,1-.43,3.06,6.8,6.8,0,0,1-6.9,5.06,7.16,7.16,0,0,1-6.64-6.2,41.65,41.65,0,0,1-.09-4.63c0-1.39.09-2.79.09-4.19-.09-2.79-.79-3.32-3.49-2.71a2.15,2.15,0,0,0-1.84,2.1c-.26,2.71-.43,5.41-.69,8.12a24.16,24.16,0,0,1-.44,3.58c-.61,2.8-2.36,4.28-4.89,4.37A5.27,5.27,0,0,1,208,65.82a20.21,20.21,0,0,1-.43-4.63c-.09-8-.27-16.06-.18-24.1a125.69,125.69,0,0,1,.79-12.84,28.45,28.45,0,0,1,3.58-11.17,17.29,17.29,0,0,1,5.5-6,11.17,11.17,0,0,1,14.93,1.66Zm-7.42,29.78A73,73,0,0,0,224.29,23a7,7,0,0,0-.43-1.05c-.09-.17-.27-.43-.35-.43-.35.08-.79.17-1,.43a6.84,6.84,0,0,0-.88,1.75,68.32,68.32,0,0,0-1.74,16.33c0,1,.78,1.4,1.74,1.4,2.62.08,3.23-.35,3.41-2.71Z" />
                    <path d="M268.92,40.41a32.68,32.68,0,0,1,1.31,12.66,22.29,22.29,0,0,1-1.75,7.77c-2.1,5.16-6.11,8.13-11.88,8.74a14.15,14.15,0,0,1-13.44-5.5,15.84,15.84,0,0,1-3.24-13.54,10.62,10.62,0,0,1,1-2.79,3.45,3.45,0,0,1,3.84-1.4A3.14,3.14,0,0,1,247.26,49c.09.78-.09,1.66,0,2.53a10.6,10.6,0,0,0,.61,3.32c.79,1.92,2.45,3.4,3.23,3,1.4-.61,1.57-2.09,1.84-3.23a17.9,17.9,0,0,0-3.58-15.89,28.83,28.83,0,0,0-2.8-2.71c-1-.87-2-1.66-2.88-2.62a15.48,15.48,0,0,1-4.45-14.84A21.72,21.72,0,0,1,244.9,7.84a12.92,12.92,0,0,1,11.79-4.19,9.9,9.9,0,0,1,8.73,8.29,6.55,6.55,0,0,1-.52,4.11c-1.14,2.44-2.79,3.31-5.59,2.88l-.79-.26c-.78-.27-1.65-.79-2.35.08a3.25,3.25,0,0,0,0,3.93c.87,1,1.92,1.84,2.88,2.71a32,32,0,0,1,9.69,15.37Z" />
                    <path d="M271.71,58.14c-.35-2.45-.52-4.89-.78-7.34-1-6.55-.53-13.1-.18-19.65.35-6.72.87-13.45,1.4-20.08a18.79,18.79,0,0,1,1.13-4.89,6.67,6.67,0,0,1,7.34-4.28,6.78,6.78,0,0,1,5.68,5.76,34.3,34.3,0,0,1,.34,5.94c0,3.84-.08,7.69-.08,11.53,0,1.22.08,2.44.17,3.67s.61,1.65,1.66,1.65c1.49,0,2.27-.43,2.36-1.57.26-3.32.44-6.63.7-9.95.17-3.06.35-6.12.7-9.17a9.91,9.91,0,0,1,1.31-3.84A5.36,5.36,0,0,1,298.78,3a5.29,5.29,0,0,1,4.89,3.5,15.36,15.36,0,0,1,.88,4.45c.52,12.93.52,25.85.26,38.77q-.13,6.16-.53,12.32a9.15,9.15,0,0,1-.69,3.05,4.41,4.41,0,0,1-4.37,2.71A4.79,4.79,0,0,1,294.85,65a3.61,3.61,0,0,1-.35-.79c-1.48-6.64-3.58-13.19-3.58-20.17,0-.88,0-1.75-.08-2.62-.09-1.31-.61-1.84-1.58-1.84-1.31,0-2.35.61-2.53,1.49a9.74,9.74,0,0,0-.26,2.27c0,3.23.09,6.46.09,9.69a39,39,0,0,1-.35,5.5,7.92,7.92,0,0,1-1,3.06,6.78,6.78,0,0,1-11.18,1.48,9,9,0,0,1-2.45-4.89Z" />
                </g>
            </g>
        </svg>
    )
}
